<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Клиническое наблюдение. <br>Женщина, 53 года</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-33">
          <img src="@/assets/img/frame-32.png" alt="">
          <div class="frame-content">
            <div class="frame-content-title">Ваш выбор терапии первой линии для данного пациента:</div>
            <div class="frame-results">
              <div class="frame-results-item">
                <div class="frame-results-item-title">R-CHOP</div>
                <div class="frame-results-item-progress">
                  <span></span>
                </div>
                <div class="frame-results-item-number">78%</div>
              </div>
              <div class="frame-results-item">
                <div class="frame-results-item-title">GB</div>
                <div class="frame-results-item-progress">
                  <span></span>
                </div>
                <div class="frame-results-item-number">78%</div>
              </div>
              <div class="frame-results-item">
                <div class="frame-results-item-title">BR</div>
                <div class="frame-results-item-progress">
                  <span></span>
                </div>
                <div class="frame-results-item-number">78%</div>
              </div>
              <div class="frame-results-item">
                <div class="frame-results-item-title">G-CHOP</div>
                <div class="frame-results-item-progress">
                  <span></span>
                </div>
                <div class="frame-results-item-number">78%</div>
              </div>
              <div class="frame-results-item">
                <div class="frame-results-item-title">другой вариант</div>
                <div class="frame-results-item-progress">
                  <span></span>
                </div>
                <div class="frame-results-item-number">78%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="frame-note">Собственные данные автора <br>CHOP, циклофосфамид (С), доксорубицин (H), винкристин (O), преднизолон (P); R, ритуксимаб; B,  бендамустин; G, обинутузумаб </div>
      </div>
    </div>
    <Pagination :total="9" :page="3" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "MosunetuzumabPage_2_results",
    components: {
      Pagination,
    }
  }
</script>