import { ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export const useLogin = () => {
  const route = useRoute();
  const store = useStore();
  const isUnknownUser = ref(false);

  const getUnknownUser = async () => {
    await store.dispatch("getUnknownUser");
    isUnknownUser.value = true;
  };

  const getUserData = async () => {
    let userName = "";

    if (route.query.invite) {
      store.commit("SET_TOKEN", route.query.invite);

      try {
        const user = await store.dispatch("getUser");
        userName = user.fullname;
      } catch (e) {
        await getUnknownUser();
      }
    } else {
      await getUnknownUser();
    }

    return userName;
  };

  return {
    isUnknownUser,
    getUnknownUser,
    getUserData,
  };
};
