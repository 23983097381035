<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Подходы к снижению риска развития СВЦ <br>при терапии мосунетузумабом</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-52 ml0">
          <img src="@/assets/img/frame-52.png" alt="">
        </div>
        <div class="frame-note large">1. Hutchings M, et al. ASCO 2020; #8009; 2. Yakoub-Agha I, et al. Haematologica 2020;105:297–316; 3. ОХЛП мосунетузумаб С ЛП-№(003673)-(РГ-RU)</div>
      </div>
    </div>
    <Pagination :total="17" :page="10" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_13",
    components: {
      Pagination,
    }
  }
</script>