<template>
  <div class="final-screen">
    <h1 class="final-screen__title">
      Мосунетузумаб <br />
      в мире
    </h1>
    <FinalScreenLink />

    <img
      class="final-screen__elem1"
      src="@/assets/img/final-screen/final-screen-elem1.png"
    />
    <img
      class="final-screen__elem2"
      src="@/assets/img/final-screen/final-screen-elem2.png"
    />

    <div class="final-screen__container">
      <FirstCircle
        v-if="currentStep === 0"
        class="final-screen__circle-first"
        @next="nextStep"
      />

      <SecondCircle
        v-if="currentStep < 3"
        class="final-screen__circle-second"
        :class="{ 'final-screen__blur': currentStep < 2 }"
        :blured="currentStep < 2"
        @next="nextStep"
      />

      <ThirdCircle
        v-if="currentStep < 5"
        class="final-screen__circle-third"
        :class="{ 'final-screen__blur': currentStep < 4 }"
        :blured="currentStep < 4"
        @next="nextStep"
      />

      <transition name="first-card">
        <first-card
          class="final-screen__card-first"
          :class="{ 'final-screen__card-first--pinned': currentStep > 1 }"
          :pinned="currentStep > 1"
          v-if="currentStep > 0"
          @next="nextStep"
        >
        </first-card>
      </transition>

      <transition name="second-card-transition">
        <SecondCard
          class="final-screen__card-second"
          :class="{ 'final-screen__card-second--pinned': currentStep > 3 }"
          :pinned="currentStep > 3"
          v-if="currentStep > 2"
          @next="nextStep"
        >
        </SecondCard>
      </transition>

      <Transition name="third-card">
        <ThirdCard
          class="final-screen__card-third"
          :class="{ 'final-screen__card-third--pinned': currentStep > 5 }"
          :pinned="currentStep > 5"
          v-if="currentStep > 4"
          @next="nextStep"
        >
        </ThirdCard>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import FirstCard from "./FirstCard.vue";
import { ref } from "vue";
import SecondCircle from "./SecondCircle.vue";
import ThirdCircle from "./ThirdCircle.vue";
import FirstCircle from "./FirstCircle.vue";
import SecondCard from "./SecondCard.vue";
import ThirdCard from "./ThirdCard.vue";
import FinalScreenLink from "./FinalScreenLink.vue";

let currentStep = ref(0);

const nextStep = () => {
  currentStep.value += 1;
};
</script>

<style scoped lang="sass">
@import "@/sass/functions"

@function scaleEl($target)
  @return (calc($target * 1.2))

.final-screen
    position: relative
    background-color: rgba(236, 233, 236, 1)
    width: 100vw
    height: 100vh
    overflow: hidden
    &__container
      scale: 1.2
      $top-gap:  calc(vw(60px) * -1)
      transform: translate(vw(150px), $top-gap)
    &__circle

      &-first
        top: vw(185px)
        left: vw(521px)
        z-index: 2
      &-second
        top: vw(441px)
        left: vw(892px)
        z-index: 1
      &-third
        top: vw(376px)
        left: vw(327px)
        z-index: 1

    &__blur
      filter: blur(10px)

    &__title
        position: absolute
        top: vw(56px)
        left: vw(64px)
        font-size: vw(56px)
        font-weight: 700
        line-height: vw(56px)
        letter-spacing: 0.02em
        text-align: left
        color: rgba(49, 8, 68, 1)

    &__elem1
        position: absolute
        top: vw(167px)
        left: 0
        height: vw(545px)

    &__elem2
        position: absolute
        bottom: 0
        left: vw(662px)

    &__card
      &-first
        position: absolute
        top: vw(156px)
        height: vw(588px)
        left: vw(731px)
        transform: translate(-50%, 0)
        overflow: hidden
        transition: all 0.4s
        z-index: 3

        &--pinned
          transform: none
          background: rgba(49, 8, 68, 0.8)
          top: vw(264px)
          width: vw(572px)
          height: vw(373px)
          left: vw(486px)
          z-index: 0

      &-second
        position: absolute
        top: vw(159px)
        height: vw(638px)
        width: vw(398px)

        left: vw(584px)
        overflow: hidden
        transition: all 0.4s
        z-index: 3

        &--pinned
          background: rgba(49, 8, 68, 0.8)
          top: vw(264px)
          height: vw(373px)
          width: vw(232px)
          left: vw(1080px)
          z-index: 0

      &-third
        position: absolute
        top: vw(131px)
        height: vw(638px)
        left: vw(584px)
        width: vw(398px)
        overflow: hidden
        transition: all 0.4s
        z-index: 3

        &--pinned
          background: rgba(49, 8, 68, 0.8)
          top: vw(264px)
          height: vw(373px)
          width: vw(232px)
          left: vw(233px)
          z-index: 0
.first-card-enter-from
  width: 0
  opacity: 0

.first-card-enter-active
  transition: all 0.4s ease-in-out

.first-card-enter-to
  width:  vw(902px)
  opacity: 1



.second-card-transition-enter-from
  top: vw(441px)
  left: vw(892px)
  width: vw(232px)
  height: vw(376px)
  opacity: 0

.second-card-transition-enter-active
  transition: all 0.4s ease-in-out

.second-card-transition-enter-to
  top: vw(159px)
  left: vw(584px)
  width: vw(398px)
  height: vw(638px)
  opacity: 1



.third-card-enter-from
  top: vw(376px)
  left: vw(327px)
  width: vw(326px)
  height: vw(326px)
  opacity: 0

.third-card-enter-active
  transition: all 0.4s ease-in-out

.third-card-enter-to
  height: vw(638px)
  top: vw(131px)
  left: vw(584px)
  width: vw(398px)
  opacity: 1

</style>