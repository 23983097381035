export const chartJsScalesConfig = {
  y: {
    max: 1.05,
    min: -0.001,
    grid: {
      drawOnChartArea: false,
      tickColor: "rgb(49,8,68)",
      tickLength: 4,
      tickWidth: 2,
    },
    title: {
      display: true,
      text: "Вероятность",
      color: "rgb(42,24,84)",
      font: {
        size: 15,
      },
    },
    border: {
      width: 2,
      color: "#310844",
    },
    ticks: {
      stepSize: 0.25,
      autoSkip: false,
      includeBounds: false,
      color: "rgb(49,8,68)",
      callback: (value: number) => {
        return value.toFixed(2).split(".").join(",");
      },
      font: {
        size: 22,
        weight: 400,
        lineHeight: '30px',
      },
    },
  },
  x: {
    type: "linear",
    max: 125,
    min: -3,
    suggestedMax: 125,
    suggestedMin: -3,
    grid: {
      drawOnChartArea: false,
      tickColor: "rgb(49,8,68)",
      tickLength: 4,
      tickWidth: 2,
    },
    title: {
      display: true,
      text: "Время (месяцы)",
      color: "rgb(42,24,84)",
      font: {
        size: 15,
      },
    },
    border: {
      width: 2,
      color: "#310844",
    },
    ticks: {
      stepSize: 12,
      color: "rgb(49,8,68)",
      autoSkip: false,
      includeBounds: false,
      font: {
        size: 22,
        weight: 400,
        lineHeight: '30px',
      },
    },
  },
};

const copy = { ...chartJsScalesConfig };

export const hiddenScale = {
  x: {
    ...copy.x,
    title: { ...copy.x.title, color: "rgba(0,0,0,0)" },
    ticks: {
      ...copy.x.ticks,
      color: "rgba(255,255,25, 0)",
    },
    grid: {
      ...copy.x.grid,
      tickColor: "rgba(255,255,25, 0)",
    },
    border: {
      width: 2,
      color: "rgba(255,255,25, 0)",
    },
  },
  y: {
    ...copy.y,
    title: { ...copy.y.title, color: "rgba(0,0,0,0)" },
    ticks: {
      ...copy.y.ticks,
      color: "rgba(255,255,25, 0)",
    },
    border: {
      width: 2,
      color: "rgba(255,255,25, 0)",
    },
    grid: {
      ...copy.y.grid,
      tickColor: "rgba(255,255,25, 0)",
    },
  },
};
