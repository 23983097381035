<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_iii_2083_13930)">
      <circle
        cx="19.7965"
        cy="19.7965"
        r="19.7965"
        fill="url(#paint0_radial_2083_13930)"
      />
      <circle
        cx="19.7965"
        cy="19.7965"
        r="19.7965"
        fill="url(#paint1_radial_2083_13930)"
        fill-opacity="0.33"
      />
    </g>
    <path d="M7.92188 19.7988H31.6777" stroke="white" stroke-width="1.97965" />
    <defs>
      <filter
        id="filter0_iii_2083_13930"
        x="0"
        y="-5.40543"
        width="42.6826"
        height="53.4934"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.08882" dy="8.49426" />
        <feGaussianBlur stdDeviation="4.24713" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.175067 0 0 0 0 0.0240227 0 0 0 0 0.217024 0 0 0 0.54 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2083_13930"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.94913" />
        <feGaussianBlur stdDeviation="11.1355" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.819118 0 0 0 0 0.819118 0 0 0 0 0.819118 0 0 0 0.42 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_2083_13930"
          result="effect2_innerShadow_2083_13930"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-5.40543" />
        <feGaussianBlur stdDeviation="5.01933" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.466667 0 0 0 0 0.129412 0 0 0 0 0.380392 0 0 0 0.18 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_2083_13930"
          result="effect3_innerShadow_2083_13930"
        />
      </filter>
      <radialGradient
        id="paint0_radial_2083_13930"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.7965 11.7937) rotate(90) scale(20.4985 37.487)"
      >
        <stop stop-color="#860A73" />
        <stop offset="1" stop-color="#24146F" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_2083_13930"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(21.9025 9.82806) rotate(-163.54) scale(12.8833 8.43547)"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
</template>

<script lang="ts" setup></script>
