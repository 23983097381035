<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Большинство пациентов, достигших полного ответа <br>на монотерапию мосунетузумабом, сохраняют его <br>в течение длительного времени</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-23">
          <img src="@/assets/img/frame-23.png" alt="">
        </div>
        <div class="frame-note large">Schuster SJ et al. ASH 2023. Abstract 603 & presentation <br>ДО - длительность ответа; ДПО - длительность полного ответа; ДЧО - длительность частичного ответа; НД - не достигнуто</div>
      </div>
    </div>
    <Pagination :total="15" :page="10" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapseTherapyPage_11",
    components: {
      Pagination,
    }
  }
</script>