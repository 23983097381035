<template>
    <div class="interactive background-2">
      <div class="interactive-head chapter-1">
        <slot name="title"></slot>
  
        <div class="speaker-card">
          <div class="speaker-card-avatar">
            <slot name="avatar"></slot>
          </div>
          <div class="speaker-card-text">
            <h2><slot name="name"></slot></h2>
            <p><slot name="regalia"></slot></p>
          </div>
        </div>
        <button class="interactive-button play" @click="$store.dispatch('setPageByUser', 1)">Начать</button>
      </div>
  
      <InteractiveNote><slot name="interactive-note"></slot></InteractiveNote>
    </div>
  </template>
  
  <script>
  import InteractiveNote from '@/components/InteractiveNote';
  
  // TODO: внедрить
  
  export default {
    name: 'ChapterView',
    components: {
      InteractiveNote,
    },
  }
  </script>
  