<template>
  <ChapterView>
    <template v-slot:title><Chapter_2_Title /></template>
    <template v-slot:avatar><img src="@/assets/img/babicheva.png" alt=""></template>
    <template v-slot:name>Бабичева <br>Лали Галимовна</template>
    <template v-slot:regalia>к.м.н., РМАНПО МЗ РФ</template>
  </ChapterView>
</template>

<script>
import ChapterView from '@/components/ChapterView'
import Chapter_2_Title from '@/components/Chapter_2_Title.vue'

export default {
  name: 'Chapter_2',
  components: {
    ChapterView,
    Chapter_2_Title,
  },
}
</script>
