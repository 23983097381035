<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title" v-html="data.title"></h1>
      </div>

      <div class="frame-wrapper">
        <div class="content-frame content-frame-18 container">
          <div v-for="(item, idx) in answerItems" :key="idx" class="item">
            <p class="percent">{{ item.value }}%</p>
            <div class="loader">
              <span :style="{ height: item.value + '%' }"> </span>
            </div>
            <p class="local-title">
              {{ item.title }}
            </p>
          </div>
          <div class="footer">
            <span class="hint"> Совсем не важно </span>
            <span class="hint"> Крайне важно </span>
          </div>
        </div>
      </div>
    </div>
    <Pagination :total="19" :page="9" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
export default {
  name: "RelapseTherapyPagee_6",
  data() {
    return {
      answer: null,
      submited: false,
    };
  },
  components: {
    Pagination,
  },
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    answerItems() {
      const { answers, options } = this.data;
      let totalAnswes = 0;

      Object.values(answers).forEach((el) => {
        totalAnswes += el;
      });

      return options.map((el) => {
        const val = answers[el.ident];

        return {
          title: el.title,
          value: val ? Math.round((val / totalAnswes) * 100) : 0,
        };
      });
    },
  },
};
</script>

<style lang="sass" setup>
@import "@/sass/functions"
.container
  display: flex
  flex-direction: row
  gap: vw(18px)
  justify-content: space-between
  padding: vw(48px) vw(32px) vw(32px)

.item
  display: flex
  flex-direction: column
  align-items: center
  gap: vw(9px)
  height: 100%
  padding: 0 vw(32px) vw(40px)
  width: vw(96px)

.footer
  display: flex
  justify-content: space-between
  position: absolute
  bottom: vw(33px)
  left: vw(42px)
  right: vw(42px)

.loader
  flex-grow: 1
  height: 100%
  background-color: rgba(139,21,194,.3)
  width: vw(16px)
  border-radius: vw(8px)
  position: relative
  

  span
    height: 0
    width: 100%
    background-color: #8b15c2
    position: absolute
    left: 0
    bottom: 0
    border-radius: vw(8px)
.percent
  font-size: vw(22px)
  font-weight: 400
  line-height: vw(30px)
  letter-spacing: 0.01em
  text-align: left

.local-title
  font-size: vw(28px)
  font-weight: 500
  line-height: vw(36px)
  letter-spacing: 0.02em
  text-align: right

.hint
  font-size: vw(22px)
  font-weight: 400
  line-height: vw(30px)
  letter-spacing: 0.01em
  text-align: center


</style>
