import { Point  } from "chart.js";

export function isOnLineWithEndCaps(
  target: Point,
  first: Point,
  second: Point,
  maxDistance = 4
) {
  const dxL = second.x - first.x,
    dyL = second.y - first.y; // line: vector from (first.x,first.y) to (second.x,second.y)
  const dxP = target.x - first.x,
    dyP = target.y - first.y; // point: vector from (first.x,first.y) to (target.x,target.y)
  const dxQ = target.x - second.x,
    dyQ = target.y - second.y; // extra: vector from (second.x,second.y) to (target.x,target.y)

  const squareLen = dxL * dxL + dyL * dyL; // squared length of line
  const dotProd = dxP * dxL + dyP * dyL; // squared distance of point from (first.x,first.y) along line
  const crossProd = dyP * dxL - dxP * dyL; // area of parallelogram defined by line and point

  // perpendicular distance of point from line
  const distance = Math.abs(crossProd) / Math.sqrt(squareLen);

  // distance of (target.x,target.y) from (first.x,first.y) and (second.x,second.y)
  const distFromEnd1 = Math.sqrt(dxP * dxP + dyP * dyP);
  const distFromEnd2 = Math.sqrt(dxQ * dxQ + dyQ * dyQ);

  // if the point lies beyond the ends of the line, check if
  // it's within maxDistance of the closest end point
  if (dotProd < 0) return distFromEnd1 <= maxDistance;
  if (dotProd > squareLen) return distFromEnd2 <= maxDistance;

  // else check if it's within maxDistance of the line
  return distance <= maxDistance;
}

const tollerance = 0.00001;

export function findLineIntersection(
  p1: Point,
  p2: Point,
  p3: Point,
  p4: Point
): Point | void {
  const a1 = (p2.y - p1.y) / (p2.x - p1.x);
  const a2 = (p4.y - p3.y) / (p4.x - p3.x);
  const b1 = -a1 * p1.x + p1.y;
  const b2 = -a2 * p3.x + p3.y;

  if (a1 === a2 && b1 === b2) {
    return p1;
  }

  const x = (b2 - b1) / (a1 - a2);
  const y = a1 * x + b1;

  if (
    isBetween(p4.x, x, p3.x) ||
    areFloatEquals(p4.x, x, tollerance) ||
    areFloatEquals(x, p3.x, tollerance)
  ) {
    return { x, y };
  }
}

function areFloatEquals(a: number, b: number, tollerance: number): boolean {
  return Math.abs(a - b) <= tollerance;
}
function isBetween(x1: number, x2: number, x3: number): boolean {
  return x1 <= x2 && x2 <= x3;
}


