<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">…а также указывают на отсутствие стандарта лечения <br> рецидивов ФЛ, особенно для 3+ линии терапии</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-9">
          <img src="@/assets/img/frame-9.png" alt="">
        </div>
        <div class="frame-note">ХТ - химиотерапия</div>
      </div>
    </div>
    <Pagination :total="6" :page="3" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapsePage_1",
    components: {
      Pagination,
    }
  }
</script>

<style scoped>
.frame-note {
  margin-left: 3vw;
  margin-top: 2vw;
}
</style>