import { chartJsScalesConfig, hiddenScale } from "@/constants/chart-js-config";
import {
  ActiveElement,
  Chart,
  ChartEvent,
  ChartTypeRegistry,
  Point,
} from "chart.js";

type ChartFunction = (
  event: ChartEvent,
  elements: ActiveElement[],
  chart: Chart
) => void;

export const createBasicChart = (
  type: keyof ChartTypeRegistry,
  data: Point[]
) => {
  const chartRoot = document.getElementById("chart") as HTMLCanvasElement;

  return new Chart(chartRoot, {
    type: type,
    options: {
      maintainAspectRatio: false,
      plugins: {
        tooltips: {
          enabled: false,
        },
        legend: {
          display: false,
        },
        background: {
          color: "rgba(130,86,153,255)",
        },
      } as any,
      scales: chartJsScalesConfig as any,
      animation: {
        numbers: { duration: 0 },
      } as any,
      elements: {
        point: {
          pointStyle: 'rect',
        }
      }
    },
    data: {
      datasets: [
        {
          id: "baseChart",
          data: [{id: 'custom', x:-5, y: 12}, ...data],
          backgroundColor: "#FFD15B",
          pointRadius: 5,
        } as any,
      ],
    },
  });
};

export const createStaticChart = () => {
  const staticRoot = document.getElementById("static") as HTMLCanvasElement;

  return new Chart(staticRoot, {
    type: "line",
    options: {
      maintainAspectRatio: false,
      plugins: {
        pluginText: {
          color: "asdasdfa",
        },
        legend: {
          display: false,
        },
        renderText: {},
      } as any,
      scales: hiddenScale as any,
    },
    data: {
      datasets: [
        {
          data: [
            { x: -5, y: 0.5 },
            { x: 130, y: 0.5 },
          ],
          borderColor: "rgba(255, 255, 255, 0.1)",
          borderWidth: 2,
          pointRadius: 0,
          animation: {
            numbers: { duration: 0 },
            color: { duration: 0 },
          } as any,
        },
      ],
    },
  });
};

export const createCorsshairChart = (
  onClick: ChartFunction,
  onHover: ChartFunction
) => {
  const crosshairRoot = document.getElementById(
    "crosshair"
  ) as HTMLCanvasElement;

  return new Chart(crosshairRoot, {
    type: "line",
    options: {
      maintainAspectRatio: false,
      animation: {
        numbers: { duration: 0 },
        color: { duration: 0 },
      } as any,
      scales: hiddenScale as any,
      onClick,
      onHover,
      plugins: {
        legend: {
          display: false,
        },
      },
    },
    data: {
      datasets: [],
    },
  });
};
