import { Chart, ChartDataset, Point } from "chart.js";

export const smoothleRenderLine = (
  chart: Chart,
  dataset: ChartDataset,
  data: Point[],
  interval: number
): ReturnType<typeof setInterval> => {
  let idx = 0;

  const intervalId = setInterval(() => {
    if (idx >= data.length) {
      clearInterval(intervalId);
      return;
    }
    const point = data[idx];

    dataset.data.push(point);

    chart?.update();
    idx++;
  }, interval);

  return intervalId;
};
