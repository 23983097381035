<template>
  <button
    class="graph-toggle"
    :class="{ 'graph-toggle--pressed': modelValue }"
    @click="emit('update:modelValue', !modelValue)"
  >
    <span
      class="graph-toggle__icon"
      :style="{ 'background-color': color }"
    ></span>

    <div class="graph-toggle__text">
      <span v-html="text"> </span>
      <span v-if="note" class="graph-toggle__text-note">
        {{ note }}
      </span>
    </div>
  </button>
</template>

<script lang="ts" setup>
defineProps<{
  color: string;
  text: string;
  note?: string;
  modelValue: boolean;
}>();
const emit = defineEmits(["update:modelValue"]);
</script>

<style lang="sass" scoped>
@import "@/sass/functions"
.graph-toggle
    all: unset
    display: flex
    align-items: center
    padding: vw(8px) vw(16px)
    gap: 12px
    font-weight: 400
    font-size: vw(18px)
    line-height: vw(24px)
    letter-spacing: 0.02em
    border-radius: vw(8px)
    width: fit-content
    background: rgba(126, 80, 147, 0.3)
    cursor: pointer
    &__text
        display: flex
        flex-direction: column
        &-note
            font-size: vw(14px)
    &__icon
        width: vw(12px)
        height: vw(12px)
        border-radius: 50%
        flex-shrink: 0

    &--pressed
        background: rgba(126, 80, 147, 1)
        color: #fff
</style>
