<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Качество жизни (QoL) пациентов с ФЛ на 3 линии <br>терапии в реальной практике значительно уступает <br>QoL на 1 линии терапии</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-10">
          <img src="@/assets/img/frame-10.png" alt="">
        </div>
      </div>
    </div>
    <Pagination :total="6" :page="4" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapsePage_1",
    components: {
      Pagination,
    }
  }
</script>