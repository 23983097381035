<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">СВЦ является специфичным нежелательным <br>явлением для препаратов, воздействующих <br>на опухоль через активацию Т-лимфоцитов</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-47">
          <img src="@/assets/img/frame-47.png" alt="">
        </div>
        <div class="frame-note large">Информация,размещенная в настоящем материале, содержит сведения о незарегистрированных вРФ лекарственных средствах/показаниях, носит исключительно научный характер ине является рекламой.</div>
      </div>
    </div>
    <Pagination :total="17" :page="7" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_8",
    components: {
      Pagination,
    }
  }
</script>