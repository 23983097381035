<template>
  <div class="interactive">
    <div class="interactive-head">
      <WaitingTitle />
    </div>
    <div class="information">
      <div class="info-button light" @click="openInfo"></div>
      <transition name="slide-fade-info">
        <div class="info-content" v-if="infoBlock">
          <div class="info-content-close" @click="closeInfo"></div>
          <div class="info-content-row">
            <ul>
              <li>https://www.roche.ru/media/press-relizy/2023/med-cor-2023-12-07a дата доступа 30.01.2024</li>
              <li>https://www.gene.com/media/press-releases/14978/2022-12-22/fda-approves-genentechs-lunsumio-a-first дата доступа 30.01.2024</li>
            </ul>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import WaitingTitle from '@/components/WaitingTitle.vue'
import { ymEvent } from "@/utils/ymEvent"
export default {
  name: 'WaitingPage',
  components: { WaitingTitle },

  data() {
    return {
      infoBlock: false,
    }
  },

  methods: {
    openInfo() {
      this.infoBlock = true;
      this.$emit("openInfo");
      ymEvent('infoandsourcesbutton')
    },
    closeInfo() {
      this.infoBlock = false;
      this.$emit("closeInfo");
    },
  },
}
</script>

<style>
.slide-fade-info-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-info-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-info-enter-from,
.slide-fade-info-leave-to {
  transform: translateY(20px) translateX(-20px);
  opacity: 0;
}
</style>
