<script setup lang="ts">
</script>

<template>
  <transition name="video-fade">
    <template v-if="$slots.default">
      <slot />
    </template>
  </transition>
</template>

<style scoped lang="sass">
.video-fade-enter-active,
.video-fade-leave-active
  transition: opacity 0.5s ease


.video-fade-enter-from,
.video-fade-leave-to
  opacity: 0
</style>
