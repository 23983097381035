<script setup lang="ts">
import {
  ActiveControlIconNames,
  ControlIconNames,
  VideoActionProps,
} from "@/components/video-conference/types/video-action-props";
import { computed } from "vue";
import VideoAction from "@/components/video-conference/VideoAction.vue";
import { Room } from "livekit-client";
import { VideoControlState } from "@/components/video-conference/types/video-controls-state";
import useDataStorage from "@/components/video-conference/composables/use-data-storage";
import { useStore } from "vuex";

const props = defineProps<{
  isSmall?: boolean;
  currentRoom?: Room;
  modelValue: VideoControlState;
}>();

const store = useStore();
const currentRoom: Room | null = store.getters.room;

const emit = defineEmits(["update:modelValue"]);

const { sendMessage } = useDataStorage();
const toggleMice = async () => {
  if (!currentRoom) return;

  const enabled = currentRoom.localParticipant.isMicrophoneEnabled;

  if (enabled) {
    console.log("disabling mic");
  } else {
    console.log("enabling mic", currentRoom.localParticipant);
  }

  let error = false;
  try {
    await currentRoom.localParticipant.setMicrophoneEnabled(!enabled, {}, {stopMicTrackOnMute: true});
  } catch (e) {
    error = true;
  }

  emit("update:modelValue", {
    ...props.modelValue,
    isMicEnabled: !enabled && !error,
  });
};
const toggleVideo = async () => {
  if (!currentRoom) return;

  const enabled = currentRoom.localParticipant.isCameraEnabled;

  if (enabled) {
    console.log("disabling video");
  } else {
    console.log("enabling video", currentRoom.localParticipant);
  }

  let error = false;

  try {
    await currentRoom.localParticipant.setCameraEnabled(!enabled, {}, {stopMicTrackOnMute: true});
  } catch (e) {
    error = true;
  }

  emit("update:modelValue", {
    ...props.modelValue,
    isVideoEnabled: !enabled && !error,
  });
};

const handUp = async () => {
  const msg = props.modelValue.isHandsUp ? "handsDown" : "handsUp";
  sendMessage(" ", msg);

  emit("update:modelValue", {
    ...props.modelValue,
    isHandsUp: !props.modelValue.isHandsUp,
  });
};

const data = computed<VideoActionProps[]>(() => {
  return [
    {
      icon: ControlIconNames.CameraIcon,
      activeIcon: ActiveControlIconNames.CameraIconActive,
      text: "Вкл. камеру",
      activeText: "Выкл. камеру",
      isSmall: props.isSmall,
      cb: toggleVideo,
      isActive: props.modelValue.isVideoEnabled,
    },
    {
      icon: ControlIconNames.MicrophoneIcon,
      activeIcon: ActiveControlIconNames.MicrophoneIconActive,
      text: "Вкл. микрофон",
      activeText: "Выкл. микрофон",
      isSmall: props.isSmall,
      cb: toggleMice,
      isActive: props.modelValue.isMicEnabled,
    },
    {
      icon: ControlIconNames.HandIcon,
      activeIcon: ActiveControlIconNames.HandIconActive,
      text: "Поднять руку",
      activeText: "Опустить руку",
      isSmall: props.isSmall,
      cb: handUp,
      isActive: props.modelValue.isHandsUp,
    },
  ];
});
</script>

<template>
  <div class="-controls">
    <video-action
      class="-controls__item"
      v-for="(action, idx) in data"
      :key="idx"
      v-bind="action"
    />
  </div>
</template>

<style scoped lang="sass">
.-controls
  display: flex
  flex-direction: row

  &__item
    margin-right: 8px

    &:last-child
      margin-right: 0
</style>
