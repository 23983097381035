<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">В патогенезе ФЛ задействовано большое <br>количество различных сигнальных каскадов</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-5">
          <img src="@/assets/img/frame-5.png" alt="">
        </div>
        <div class="frame-note">Chen C-JJ, Choi MY, Heyman BM. Cancers. 2023; 15(18):4483</div>
      </div>
    </div>
    <Pagination :total="3" :page="3" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapsePage_4",
    components: {
      Pagination,
    }
  }
</script>