<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">СВЦ может быть трудно отличим <br>от других состояний</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-43">
          <img src="@/assets/img/frame-43.png" alt="">
        </div>
        <div class="frame-note large">1. Shimabukuro-Vornhagen A,et al. J Immunother Cancer 2018;6:56; <br>2. Chavez JC, et al. Hematol Oncol Stem Cell Ther 2020;13:1–6 ;  <br>3. Brudno N,and Kochenderfer JN.Blood 2016;127:3321–30; <br>4. Doessegger L & Banholzer ML. Clin Transl Immunol 2015;4:e39</div>
      </div>
    </div>
    <Pagination :total="17" :page="4" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_4",
    components: {
      Pagination,
    }
  }
</script>