<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Синдром выброса цитокинов (СВЦ)</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-41">
          <img src="@/assets/img/frame-41.png" alt="">
        </div>
        <div class="frame-note large">1.Cosenza M et al (2021). International Journal of Molecular Sciences 22(14): 7652; 2. Shimabukuro-Vornhagen A, et al. J Immunother Cancer 2018;6:56; 3. Cobb DA,and Lee DW. Cancer J 2021:27:119–125 <br>БисАТ, биспецифические антитела</div>
      </div>
    </div>
    <Pagination :total="17" :page="2" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_2",
    components: {
      Pagination,
    }
  }
</script>