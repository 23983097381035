<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Выберите дальнейшую тактику лечения</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-56">
          <img src="@/assets/img/frame-56.png" alt="">
        </div>
        <div class="frame-note large">Данный клинический случай разработан с целью обучения и не включает информацию о НЯ/ОС у реального пациента. Предоставлен проф. Кэтрин Тиблмонт, Больница Сен-Луи, Париж, Франция.</div>
      </div>
    </div>
    <Pagination :total="17" :page="17" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_17",
    components: {
      Pagination,
    }
  }
</script>