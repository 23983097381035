<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">СВЦ является специфичным нежелательным <br>явлением для препаратов, воздействующих <br>на опухоль через активацию Т-лимфоцитов</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-46">
          <img src="@/assets/img/frame-46.png" alt="">
        </div>
        <div class="frame-note large">1. Budder LE et al. (2022)/ he Lancet Oncology,23(8), 1055-1065; 2. Morschhauser et al. Blood138 (suppl 1):128; 3. LintonK. et al. ASH 2023. Abstract 1655. Blood 142(suppl 1): 1655; <br>4. Villasboas JC etal. ASH 2023. Blood 142 (suppl 1): 3041; 5. Jacobson CA et al. (2022) Lancet Oncol.23(1): 91-103; 6. Fowler, N.H.. et al. (2022) Nat Med28, 325–332; <br>7. Morschhauser et al.ICML 2023. AbstrLBA4. Hematological Oncology, 41:877-880</div>
      </div>
    </div>
    <Pagination :total="17" :page="6" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_7",
    components: {
      Pagination,
    }
  }
</script>