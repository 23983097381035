<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Насколько важно стремиться к достижению<br>полного ответа в 3+ линии терапии ФЛ?</h1>
        <div class="subtitle subtitle--cards">Отметьте от 1 до 10, где 1 – совсем не важно, 10 – это крайне важно</div>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-65">
          <div class="content-frame__wrapper">
            <div class="content-frame__grades">
              <div class="content-frame__grade">
                1
              </div>
              <div class="content-frame__grade">
                2
              </div>
              <div class="content-frame__grade">
                3
              </div>
              <div class="content-frame__grade content-frame__grade--2">
                4
              </div>
              <div class="content-frame__grade content-frame__grade--2">
                5
              </div>
              <div class="content-frame__grade content-frame__grade--2">
                6
              </div>
              <div class="content-frame__grade content-frame__grade--2">
                7
              </div>
              <div class="content-frame__grade content-frame__grade--3">
                8
              </div>
              <div class="content-frame__grade content-frame__grade--3">
                9
              </div>
              <div class="content-frame__grade content-frame__grade--3">
                10
              </div>
          </div>
          <div class="content-frame__text">
            <div class="subtitle__label">Совсем не важно</div>
            <div class="subtitle__label">Крайне важно</div>
          </div>
          <div class="content-frame__button">
            <button
              class="content-frame-button content-frame-button--2"
            >
              Ответить
            </button>
          </div>
          </div>
        </div>

      </div>
    </div>
    <Pagination :total="12" :page="10" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "MosunetuzumabPage_9",
    components: {
      Pagination,
    }
  }
</script>