<script setup lang="ts">

</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 12C9.92896 12 8.25003 10.3211 8.25003 8.25C8.25003 6.17893 9.92896 4.5 12 4.5C14.0711 4.5 15.75 6.17893 15.75 8.25C15.75 10.3211 14.0711 12 12 12ZM19.3821 16.5456C18.7536 14.3877 15.6876 12.75 12.0001 12.75C8.31255 12.75 5.24659 14.3877 4.61802 16.5456C4.47345 17.042 4.76656 17.53 5.23605 17.7463C6.35901 18.2638 8.72069 19.125 12.0001 19.125C15.2795 19.125 17.6412 18.2638 18.7641 17.7463C19.2336 17.53 19.5267 17.042 19.3821 16.5456Z" fill="white"/>
  </svg>

</template>

<style scoped lang="sass">

</style>
