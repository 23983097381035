<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">С каждой последующей линией терапии ФЛ <br>время до следующего лечения сокращается, <br>а расходы на лечение растут</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-2">
          <img src="@/assets/img/frame-2.gif" alt="">
        </div>
        <div class="frame-note">1.  Link et al (2019) Br J Haematol. 184: 660; 2. Shah BD et al. ASH 2023. Abstract 5137</div>
      </div>
    </div>
    <Pagination :total="3" :page="2" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapsePage_2",
    components: {
      Pagination,
    }
  }
</script>