<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Клиническое наблюдение. <br>Женщина, 53 года</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-36">
          <img src="@/assets/img/frame-36.png" alt="" />
          <div
            class="frame-content"
            v-if="active === '12_presentation_clinical_case_poll3'"
          >
            <div class="frame-content-title sm-title" v-html="data.title"></div>
            <div
              class="content-frame-field"
              v-for="item in data.options"
              :key="item.ident"
            >
              <div class="radio radio--circle">
                <label class="radio-container">
                  <p>{{ item.title }}</p>
                  <input
                    type="radio"
                    name="question"
                    :value="item.ident"
                    v-model="answer"
                  />
                  <span class="radio-checkmark"></span>
                </label>
              </div>
            </div>
            <div class="content-frame-row content-frame-row--button">
              <div class="content-frame-field">
                <button
                  class="content-frame-button content-frame-button--2"
                  @click="submit"
                  v-if="!submited"
                >
                  Ответить
                </button>
                <button
                  class="content-frame-button button--disabled content-frame-button--2"
                  disabled
                  v-else
                >
                  Отправлено
                </button>
              </div>
            </div>
          </div>
          <div
            v-else-if="active === '12_presentation_clinical_case_result3'"
            class="frame-content"
          >
            <div class="frame-content-title sm-title" v-html="data.title"></div>
            <div
              class="frame-results-item ml-item"
              v-for="(item, idx) in answerItems"
              :key="idx"
            >
              <div class="frame-results-item-title">{{ item.title }}</div>
              <div class="frame-results-item-progress">
                <span :style="{'width': item.value + '%'}"></span>
              </div>
              <div class="frame-results-item-number">{{ item.value }}%</div>
            </div>
          </div>
        </div>
        <div class="frame-note">Собственные данные автора <br>CHOP, циклофосфамид (С), доксорубицин (H), винкристин (O), преднизолон (P); R, ритуксимаб; B,  бендамустин; G, обинутузумаб; ВДХТ, высокодозная химиотерапия; <br>ТГСК, трансплантация гематопоэтических стволовых клеток </div>
      </div>
    </div>
    <Pagination :total="9" :page="6" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
export default {
  name: "MosunetuzumabPage_6",
  data() {
    return {
      answer: null,
      submited: false,
    };
  },
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    active() {
      return this.$store.getters["active"];
    },
    answerItems() {
      const { answers, options } = this.data;
      let totalAnswes = 0;

      Object.values(answers).forEach((el) => {
        totalAnswes += el;
      });

      return options.map((el) => {
        const val = answers[el.ident];

        return {
          title: el.title,
          value: val ? Math.round((val / totalAnswes) * 100) : 0,
        };
      });
    },
  },
  methods: {
    async submit() {
      console.log("test");
      let answers = [];
      answers.push({ ident: this.answer });
      let payload = {
        answers: answers,
        ident: "12_presentation_clinical_case_poll3",
      };
      await this.$store.dispatch("sendPoll", payload);
      this.submited = true;
    },
  },
  components: {
    Pagination,
  },
};
</script>
<style scoped lang="sass">
@import "@/sass/functions"

.ml-item
  margin-left: vw(93px)

.sm-title
  font-size: vw(20px)
  line-height: vw(20px)
  margin-bottom: vw(20px)
.content-frame-row 
  flex-wrap: wrap
  width: calc(100% + 3vw)

.radio
  display: flex

.content-frame-field
  margin-left: 0
  margin-bottom: vw(8px)

.radio-checkmark
  top: vw(2px)

.content-frame-button
  right: vw(32px)
  bottom: vw(32px)
.content-frame-36 .frame-content
  bottom: 0
  right: vw(0px)

.frame-results-item
  margin-left: 0
</style>
