<template>
  <div class="interactive-note interactive-note--2">
    <p>
      <slot>
        Данная презентация подготовлена при поддержке АО “Рош-Москва”. Информация предназначена только для медицинских работников. Мнение лектора может не совпадать с позицией АО “Рош-Москва”. 25 сентября 2024 года. M-RU-00015465 февраль 2024
      </slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'InteractiveNote',
}
</script>
