<template>
  <ChapterView>
    <template v-slot:title><Chapter_1_Title /></template>
    <template v-slot:avatar><img src="@/assets/img/avatar-7.png" alt=""></template>
    <template v-slot:name>Поспелова <br>Татьяна Ивановна</template>
    <template v-slot:regalia>д.м.н., профессор, ФГБОУ ВО НГМУ МР</template>
  </ChapterView>
</template>

<script>
import ChapterView from '@/components/ChapterView'
import Chapter_1_Title from '@/components/Chapter_1_Title.vue'

export default {
  name: 'Chapter_1',
  components: {
    Chapter_1_Title,
    ChapterView,
  },
}
</script>
