import { Chart } from "chart.js";

export const renderBackground = (chart: Chart, args: any, opts: any) => {
  if (!opts.color) {
    return;
  }
  const { ctx, chartArea } = chart;

  ctx.fillStyle = opts.color;
  ctx.fillRect(
    chartArea.left,
    chartArea.top,
    chartArea.width,
    chartArea.height
  );
  ctx.beginPath();
  ctx.moveTo(chartArea.right, chartArea.top);
  ctx.lineTo(chartArea.right - 16, chartArea.top);
  ctx.quadraticCurveTo(
    chartArea.right,
    chartArea.top,
    chartArea.right,
    chartArea.top + 16
  );
  ctx.lineTo(chartArea.right, chartArea.top);
  ctx.fillStyle = "#eecafb";
  ctx.strokeStyle = "#eecafb";

  ctx.fill();
  ctx.stroke();
  ctx.closePath();
};
