<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title half" v-html="data.title"></h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-31">
          <div class="form-title">Выберите 1 или несколько вариантов ответов </div>
          <div class="content-frame-field" v-for="item in data.options" :key="item.ident">
            <div class="radio radio--check"> <!-- Keep the same class for styling -->
              <label class="radio-container">
                <p>{{item.title}}</p>
                <input type="checkbox" :value="item.ident" v-model="answer">
                <span class="radio-checkmark"></span> <!-- Keep the same class for styling -->
              </label>
            </div>
          </div>
          <div class="content-frame-field">
            <button class="content-frame-button content-frame-button--2" @click="submit" v-if="!submited">Подтвердить</button>
            <button class="content-frame-button button--disabled content-frame-button--2" disabled v-else>Отправлено</button>
          </div>
        </div>
        <div class="frame-note large">Schuster SJ et al. ASH 2023. Abstract 603 & presentation <br>ПО - полный ответ;  ЧОО - частота объективного ответа; POD24 - прогрессирование заболевания в течение 24 месяцев с момента начала первой линии терапии; ВБП - выживаемость без прогрессирования; <br>ДПО - длительность полного ответа; НД - значение недостигнуто</div>
      </div>
    </div>
    <Pagination :total="15" :page="15" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue' 

export default {
  name: "RelapseTherapyPage_19",
  data() {
    return {
      answer: [], // Change to an array for multiple checkbox values
      submited: false,
    }
  },
  props: {
    data: {
      type: Object,
    }
  },
  components: {
    Pagination,
  },
  methods: {
    async submit() {
      console.log('test');
      let answers = this.answer.map(ident => ({ ident }));
      let payload = {
        answers: answers,
        ident: '11_presentation_therapy_relevant_poll3'
      }
      await this.$store.dispatch('sendPoll', payload)
      this.submited = true;
    }
  }
}
</script>
