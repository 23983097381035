<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Рецидивы фолликулярной лимфомы:<br>особенности 3+ линии терапии</h1>
      </div>
      <div class="frame-wrapper">
        <br>
        <br>
        <div class="content-frame content-frame-12">
          <img src="@/assets/img/frame-12.png" alt="">
        </div>
        <div class="frame-note">1. Kanters, S. et al. BMC Cancer 23, 74 (2023); 2. Casulo et al. Lancet Haematol. 2022 April ; 9(4): e289–e300; 3.Ghione et al. Haematologica. 2023 Mar 1;108(3):822-832; 4 Häfliger, Benjamin, et al. Value in Health 22 (2019): S413-S414; 5. <br>Zhang et a; (2022). Annals on oncology 33 (supplement 7): S833</div>
      </div>
    </div>
    <Pagination :total="6" :page="6" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapsePage_1",
    components: {
      Pagination,
    }
  }
</script>