<template>
  <div class="interactive">
    <div class="interactive-title interactive-title-4 dark">ПРАВДА ИЛИ ВЫМЫСЕЛ?</div>
    <div class="task small">
      <div class="task-icon"></div>
      <div class="task-text">
        <p>
          Кликните мышкой на карточки, утверждения на которых Вы считаете правдивыми. После выбора карточек нажмите кнопку "ответить".
        </p>
      </div>
    </div>
    <div class="text-cards">
      <div data-aos="zoom-in">
        <div class="text-card number-1 primary">
          <div class="text-card-row">
            <div class="text-card-number">{{data.options[0]?.ident}}</div>
            <div class="text-card-progress">
              <div class="text-card-progress-line">
                <span class="persent" :style="`width: ${answers[data.options[0]?.ident]}% !important`"></span>
              </div>
              <div class="text-card-progress-number" v-if="answers[data.options[0]?.ident]">{{answers[data.options[0]?.ident]}}%</div>
              <div class="text-card-progress-number" v-else>0%</div>
            </div>
          </div>
          <div class="text-card-text" v-html="data.options[0]?.title"></div>
        </div>
      </div>
      <div class="text-cards-row number-1">
        <div data-aos="zoom-in" data-aos-delay="300">
          <div class="text-card number-2 secondary">
            <div class="text-card-row">
              <div class="text-card-number">{{data.options[1]?.ident}}</div>
              <div class="text-card-progress">
                <div class="text-card-progress-line">
                  <span class="persent" :style="`width: ${answers[data.options[1]?.ident]}% !important`"></span>
                </div>
              <div class="text-card-progress-number" v-if="answers[data.options[1]?.ident]">{{answers[data.options[1]?.ident]}}%</div>
              <div class="text-card-progress-number" v-else>0%</div>
              </div>
            </div>
            <div class="text-card-text" v-html="data.options[1]?.title"></div>
          </div>
        </div>
        <div data-aos="zoom-in" data-aos-delay="600">
          <div class="text-card number-3 secondary">
            <div class="text-card-row">
              <div class="text-card-number">{{data.options[2]?.ident}}</div>
              <div class="text-card-progress">
                <div class="text-card-progress-line">
                  <span class="persent" :style="`width: ${answers[data.options[2]?.ident]}% !important`"></span>
                </div>
              <div class="text-card-progress-number" v-if="answers[data.options[2]?.ident]">{{answers[data.options[2]?.ident]}}%</div>
              <div class="text-card-progress-number" v-else>0%</div>
              </div>
            </div>
            <div class="text-card-text" v-html="data.options[2]?.title"></div>
          </div>
        </div>
      </div>
      <div class="text-cards-row text-cards-row--2">
        <div data-aos="zoom-in" data-aos-delay="900">
          <div class="text-card number-4 primary">
            <div class="text-card-row">
              <div class="text-card-number">{{data.options[3]?.ident}}</div>
              <div class="text-card-progress">
                <div class="text-card-progress-line">
                  <span class="persent" :style="`width: ${answers[data.options[3]?.ident]}% !important`"></span>
                </div>
              <div class="text-card-progress-number" v-if="answers[data.options[3]?.ident]">{{answers[data.options[3]?.ident]}}%</div>
              <div class="text-card-progress-number" v-else>0%</div>
              </div>
            </div>
            <div class="text-card-text" v-html="data.options[3]?.title"></div>
          </div>
        </div>
        <div data-aos="zoom-in" data-aos-delay="1200" data-aos-offset="-3000">
          <div class="text-card primary">
            <div class="text-card-row">
              <div class="text-card-number">{{data.options[4]?.ident}}</div>
              <div class="text-card-progress">
                <div class="text-card-progress-line">
                  <span class="persent" :style="`width: ${answers[data.options[4]?.ident]}% !important`"></span>
                </div>
              <div class="text-card-progress-number" v-if="answers[data.options[4]?.ident]">{{answers[data.options[4]?.ident]}}%</div>
              <div class="text-card-progress-number" v-else>0%</div>
              </div>
            </div>
            <div class="text-card-text" v-html="data.options[4]?.title"></div>
          </div>
        </div>
      </div>
      <div data-aos="zoom-in" data-aos-delay="1500"  data-aos-offset="-3000">
        <div class="text-card number-6 primary">
          <div class="text-card-row">
            <div class="text-card-number">{{data.options[5]?.ident}}</div>
            <div class="text-card-progress">
              <div class="text-card-progress-line">
                <span class="persent" :style="`width: ${answers[data.options[5]?.ident]}% !important`"></span>
              </div>
              <div class="text-card-progress-number" v-if="answers[data.options[5]?.ident]">{{answers[data.options[5]?.ident]}}%</div>
              <div class="text-card-progress-number" v-else>0%</div>
            </div>
          </div>
          <div class="text-card-text" v-html="data.options[5]?.title"></div>
        </div>
      </div>
    </div>

    <div class="interactive-element interactive-element-1"></div>
    <div class="interactive-element interactive-element-4"></div>
    <div class="interactive-element interactive-element-6" data-aos="zoom-in"></div>
    <div class="interactive-element interactive-element-7"></div>
    <div class="information">
      <div class="info-button" @click="openInfo"></div>
      <transition name="slide-fade">
        <div class="info-content" v-if="infoBlock">
          <div class="info-content-close" @click="closeInfo"></div>
          <div class="info-content-row">
            <p>1. Odutola, M.K. et al (2021). Environmental Research, 197, p.110887;
            <br>2. Linet MS et al (2014). J. Natl. Cancer Inst. 48: 26-40;
            <br>3. Mannetje A et al (2016). Environ. Health Perspect., 124: 396-405;
            <br>4. Casulo C et al (2015). J Clin Oncol. 33(23): 2516-22;
            <br>5. Dennie TW & Kolesar JM (2009). Clin Ther. 31 Pt 2: 2290-311;
            <br>6. Besien K & Schouten H (2007). Leukemia & Lymphoma 48(2): 232-243;
            <br>7. Crouchet al (2022). Blood Advances 6(21): 5716-5731;
            <br>8. Собственные данные</p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import AOS from 'aos';
import { ymEvent } from "@/utils/ymEvent";
export default {
  name: 'IceBreakerResults',
  data() {
    return {
      answers: [],
      infoBlock: false,
    }
  },
  props: {
    data: {
      type: Object,
    }
  },
  mounted() {
    AOS.init();
    const totalResponses = this.data.count;
    const percentages = {};

    this.data.options.forEach(o => {
      const answerCount = this.data.answers[o.ident] ? this.data.answers[o.ident] : 0
      percentages[o.ident] = Math.round((answerCount / totalResponses) * 100)
    })

    this.answers = percentages;
  },
  methods: {
    openInfo() {
      this.infoBlock = true;
      this.$emit("openInfo");
      ymEvent('infoandsourcesbutton')
    },
    closeInfo() {
      this.infoBlock = false;
      this.$emit("closeInfo");
    },
  }
}
</script>
