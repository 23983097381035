<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Шкала оценки ICE (энцефалопатии, ассоциированной <br>с иммунными эффекторными клетками)</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-63">
          <img src="@/assets/img/frame-63.png" alt="">
        </div>
        <div class="frame-note large">Lee DW, et al. Biol Blood Marrow Transplant 2019;25:625–38.</div>
      </div>
    </div>
    <Pagination :total="6" :page="6" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_24",
    components: {
      Pagination,
    }
  }
</script>