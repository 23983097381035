import { Chart, Point } from "chart.js";
import { findLineIntersection, isOnLineWithEndCaps } from "./find-intersection";
import { LINE_ID } from "@/types/line-id";

type SourceMap = Record<LINE_ID, Point[]>;

export const mapPixelForValue = (chart: Chart, p: Point) => {
  const { x: scaleX, y: scaleY } = chart.scales;
  const x = scaleX.getPixelForValue(p.x);
  const y = scaleY.getPixelForValue(p.y);
  return { x, y };
};

export const findHoveredDataset = (chart: Chart, sourceMap: SourceMap, p: Point) => {
  return chart.data.datasets.find((d: any) => {
    const source: Point[] = sourceMap[d.id as LINE_ID] || [];

    let result = false;

    for (let i = 1; i < source.length; i++) {
      const curPoint = source[i];
      const prevPoint = source[i - 1];
      const curVal = mapPixelForValue(chart, curPoint);
      const prevVal = mapPixelForValue(chart, prevPoint);

      result = isOnLineWithEndCaps(p, curVal, prevVal);

      if (result) {
        break;
      }
    }

    return result;
  });
};

export const findMediumIntersection = (
  chart: Chart,
  sourceMap: SourceMap,
  dataset: any
) => {
  const source: Point[] = sourceMap[dataset.id as LINE_ID] || [];

  const { x: scaleX, y: scaleY } = chart.scales;

  const p1x = scaleX.getPixelForValue(-5);
  const p2x = scaleX.getPixelForValue(130);
  const y = scaleY.getPixelForValue(0.5);
  let currentRes;

  for (let i = 1; i < source.length; i++) {
    const curPoint = source[i];
    const prevPoint = source[i - 1];
    const curVal = mapPixelForValue(chart, curPoint);
    const prevVal = mapPixelForValue(chart, prevPoint);

    currentRes = findLineIntersection(
      { x: p1x, y: y },
      { x: p2x, y: y },
      curVal,
      prevVal
    );

    if (currentRes) {
      break;
    }
  }

  return currentRes;
};

export const checkInterSections = (
  chart: Chart,
  sourceMap: SourceMap,
  p: Point
) => {
  const dataSet = findHoveredDataset(chart, sourceMap, p);

  if (!dataSet) {
    return;
  }

  return findMediumIntersection(chart, sourceMap, dataSet);
};
