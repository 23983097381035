import { Point } from "@/types/Point";

export const graphFinalCurve: Point[] = [
  { x: 0, y: 1 },
  { x: 1, y: 0.9378 },
  { x: 2, y: 0.875 },
  { x: 2.5, y: 0.815 },
  { x: 3.15, y: 0.75 },
  { x: 4.1, y: 0.6875 },
  { x: 4.9, y: 0.625 },
  { x: 6.9, y: 0.5625 },
  { x: 9.8, y: 0.5 },
  { x: 12.9, y: 0.4375 },
  { x: 17, y: 0.375 },
  { x: 22, y: 0.3125 },
  { x: 25, y: 0.28125 },
  { x: 27, y: 0.26 },
  { x: 30, y: 0.25125 },
  { x: 35, y: 0.21875 },
  { x: 40, y: 0.1912 },
  { x: 43.8, y: 0.1875 },
  { x: 51, y: 0.1743 },
  { x: 56, y: 0.1695 },
  { x: 60.6, y: 0.154 },
  { x: 60.6, y: 0.141 },
  { x: 67, y: 0.141 },
  { x: 68.9, y: 0.1375 },
  { x: 76.5, y: 0.1295 },
  { x: 76.5, y: 0.1275 },
  { x: 82.5, y: 0.1265 },
  { x: 82.5, y: 0.1259 },
  { x: 105.7, y: 0.1259 },
];
