<template>
  <FinalScreenCircle
    class="second-circle"
    @click="emit('next')"
    :class="{ 'second-circle--blured': blured }"
  >
    <div class="second-circle__body">
      <svg
        width="170"
        height="84"
        viewBox="0 0 170 84"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="second-circle__number"
      >
        <path
          d="M29.4798 1.68196H59.293C68.5964 1.68196 71.4509 6.86227 71.4509 13.3112C71.4509 21.6631 60.6674 29.0636 49.6724 19.2316L40.1576 10.7739C33.3915 4.74786 31.4885 7.60231 27.6826 14.4741L12.1417 42.7015C10.4501 45.8731 13.3046 48.6219 16.159 47.2475L47.558 31.7066C67.4335 21.8746 72.7195 37.2041 72.7195 54.1193C72.7195 74.5234 56.2271 83.5096 31.1714 83.5096C13.0932 83.5096 0.512413 75.8978 5.16412 63.9513C8.33573 55.8109 16.4762 56.3395 20.4936 61.6255L31.1714 75.5806C34.1315 79.4923 37.6203 79.2808 41.6377 75.792L65.1076 55.2823C68.0678 52.6392 67.2221 48.3047 62.0418 48.7276L18.3792 52.3221C7.48997 53.1678 5.26984 47.1418 5.587 36.3583L6.1156 15.1085C6.32704 5.69934 10.3444 1.68196 29.4798 1.68196ZM132.333 83.5096C107.806 83.5096 94.9076 69.2374 94.9076 41.5386C94.9076 13.8398 107.806 -0.00956685 132.333 -0.00956685C156.966 -0.00956685 169.863 13.8398 169.863 41.5386C169.863 69.2374 156.966 83.5096 132.333 83.5096ZM134.13 74.312L162.04 40.5871C165.635 36.2526 164.577 31.4951 159.503 29.4865L119.435 13.4169C110.977 10.0339 104.317 15.5313 107.383 25.4691L121.655 71.0346C123.346 76.5321 130.113 79.0694 134.13 74.312Z"
          fill="white"
        />
      </svg>

      <svg
        width="68"
        height="68"
        viewBox="0 0 68 68"
        fill="none"
        class="second-circle__plus"
        xmlns="http://www.w3.org/2000/svg"
        v-if="!blured"
      >
        <path
          d="M2 33.377L66.0046 34.6239"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
        />
        <path
          d="M33.3711 66.0039L34.618 1.99935"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
        />
      </svg>
    </div>
  </FinalScreenCircle>
</template>

<script setup lang="ts">
import FinalScreenCircle from "./FinalScreenCircle.vue";

const emit = defineEmits(["next"]);
defineProps<{ blured: boolean }>();
</script>

<style scoped lang="sass">
@import "@/sass/functions"

.second-circle
  width: vw(376px)
  height: vw(376px)
  &__body
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
  &__number
    margin-bottom: vw(10px)
    width: vw(170px)
    height: vw(84px)
  &--blured
    pointer-events: none
  &__plus
    width: vw(68px)
    height: vw(68px)
</style>
