<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Как Вы думаете, о каком нежелательном явлении <br>идет речь в данном кейсе?</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-58">
          <img src="@/assets/img/frame-58.png" alt="">
          <div class="frame-content">
            результаты
          </div>
        </div>
        <div class="frame-note large">Данныйклинический случай разработан с целью обучения и не включает информацию о НЯ/ОСу реального пациента. Предоставлен проф. ГеоргомХессом, Университет Иоганна Гутенберга, Майнце,Германия,,.</div>
      </div>
    </div>
    <Pagination :total="23" :page="20" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_19",
    components: {
      Pagination,
    },
  }
</script>