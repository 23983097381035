<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Менеджмент СВЦ</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-54">
          <img src="@/assets/img/frame-54-3.png" alt="">
        </div>
        <div class="frame-note large">ОХЛП мосунетузумаб С ЛП-№(003673)-(РГ-RU)</div>
      </div>
    </div>
    <Pagination :total="17" :page="15" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_15",
    components: {
      Pagination,
    }
  }
</script>