<script setup lang="ts"></script>

<template>
  <div class="logout-page">
    <h1>Соединение разорвано</h1>
  </div>
</template>

<style scoped lang="sass">
@import "@/sass/functions"

.logout-page
  position: fixed
  left: 0
  right: 0
  bottom: 0
  top: 0
  z-index: 9999
  background: #1D191E
  display: flex
  align-items: center
  justify-content: center
  color: #ffffff
  font-size: vw(50px)
</style>
