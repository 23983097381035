import { DataPacket_Kind, RemoteParticipant, Room } from "livekit-client";
import { VideoChatMessageProps } from "@/components/video-conference/types/video-chat-message-props";
import { computed, inject, ref } from "vue";
import { VideoParticipantExpose } from "@/components/video-conference/types/video-partisipant-expose";
import axios from "axios";
import { useStore } from "vuex";

const encoder = new TextEncoder();
const decoder = new TextDecoder();

type ChatTopic = string;
interface ChatServerResponse {
  timestamp: string;
  message: string;
  identity: string;
  image_url: string | null;
}

const messages = ref<VideoChatMessageProps[]>([]);
const chatHistory = ref<VideoChatMessageProps[]>([]);
type FindParticipant = (id: string) => VideoParticipantExpose;
export default function useDataStorage(cb?: FindParticipant) {
  const store = useStore();

  const currentRoom: Room | null = store.state.room;

  // Это нужно потому что я использую композобл в том же месте где делаю провайд
  // И оно ломается
  const findParticipantComponent = inject<FindParticipant | undefined>(
    "findParticipantComponent",
    cb
  );

  const loadChatHistory = async () => {
    const res: { data: ChatServerResponse[] } = await axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/chat/history`
    );

    const savedData = res.data
      .filter((el) => el.identity !== "server")
      .map((el) => {
        return {
          text: el.message,
          author: el.identity,
          image_url: el.image_url,
          isLocalParticipant:
            el.identity === currentRoom?.localParticipant.identity,
          time: +new Date(el.timestamp),
        };
      });
    savedData.sort((a, b) => a.time - b.time);
    chatHistory.value = savedData;
  };

  const sendMessage = (
    message: string | { msg: string; image_url: string },
    topic: ChatTopic,
    isLocal = false
  ) => {
    if (!currentRoom) return;

    if (!message) return;

    const textMessage = typeof message === "string" ? message : message.msg;
    const image_url = typeof message === "string" ? null : message.image_url;

    const msg =
      topic === "handsUp"
        ? encoder.encode("")
        : encoder.encode(
            JSON.stringify({
              message: textMessage,
              image_url,
              timestamp: Math.floor(Date.now() / 1000),
              identity: currentRoom?.localParticipant.identity,
            })
          );

    if (!isLocal) {
      currentRoom.localParticipant.publishData(msg, DataPacket_Kind.RELIABLE, {
        topic,
      });
    }

    const newMessage: VideoChatMessageProps = {
      text: textMessage,
      image_url,
      author: currentRoom.localParticipant.identity,
      isLocalParticipant: true,
      time: +Date.now(),
      isLocalMessage: isLocal,
    };

    if (topic !== "chat") {
      if (!findParticipantComponent) {
        return;
      }

      const lp = findParticipantComponent(
        currentRoom.localParticipant.identity
      );

      if (lp) {
        if (topic === "reaction") {
          lp.setReaction(textMessage);
        } else {
          lp.updateHand(topic as any);
        }
      }

      return;
    } else {
      messages.value.push(newMessage);
    }
  };

  function handleData(
    msg: Uint8Array,
    participant?: RemoteParticipant,
    kind?: DataPacket_Kind,
    topic?: ChatTopic
  ) {
    if (topic === "handsUp" || topic === "handsDown" || topic === "presenter") {
      return;
    }

    const data = decoder.decode(msg);
    let text: any = '';
    let hasError = false;

    try {
      text = JSON.parse(data);
    } catch (e) {
      hasError = true;
    }

    if (!text) {
      text = {};
    }

    if (hasError) {
      return;
    }

    if (participant) {
      if (!findParticipantComponent) {
        return;
      }

      if (topic === "chat") {
        const newMessage: VideoChatMessageProps = {
          author: text.identity,
          image_url: text.image_url,
          text: text.message,
          time: +new Date(text.timestamp) * 1000,
          isLocalParticipant: false,
        };
        messages.value.push(newMessage);
      } else if (topic === "reaction") {
        const currentParticipant = findParticipantComponent(
          participant.identity
        );

        if (currentParticipant) {
          currentParticipant.setReaction(text.message);
        }
      }
    }
  }

  const fullChatMessages = computed(() => {
    return [...chatHistory.value, ...messages.value];
  });

  return {
    messages,
    sendMessage,
    handleData,
    loadChatHistory,
    fullChatMessages,
    currentRoom,
  };
}
