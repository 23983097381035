<template>
  <FinalScreenCircle class="first-circle" @click="emit('next')">
    <svg
      width="210"
      height="215"
      viewBox="0 0 210 215"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.2708 0.990385H75.1716C86.9988 0.990385 90.6276 7.57599 90.6276 15.7744C90.6276 26.392 76.9188 35.8 62.9412 23.3008L50.8452 12.5488C42.2436 4.88799 39.8244 8.51679 34.986 17.2528L15.2292 53.1376C13.0788 57.1696 16.7076 60.664 20.3364 58.9168L60.2532 39.16C85.5204 26.6608 92.2404 46.1488 92.2404 67.6528C92.2404 93.592 71.274 105.016 39.4212 105.016C16.4388 105.016 0.445171 95.3392 6.35877 80.152C10.3908 69.8032 20.7396 70.4752 25.8468 77.1952L39.4212 94.936C43.1844 99.9088 47.6196 99.64 52.7268 95.2048L82.5636 69.1312C86.3268 65.7712 85.2516 60.2608 78.666 60.7984L23.1588 65.368C9.31557 66.4432 6.49317 58.7824 6.89637 45.0736L7.56837 18.0592C7.83717 6.09759 12.9444 0.990385 37.2708 0.990385ZM153.51 50.584L175.955 22.8976C181.6 16.0432 182.003 11.3392 179.046 8.91999C176.627 7.03839 172.326 6.09759 166.547 10.5328L148 24.6448C139.532 31.096 133.216 32.3056 129.721 32.3056C123.136 32.3056 115.34 30.2896 114.4 17.5216C113.459 5.55999 123.404 0.855986 136.576 0.855986H183.212C193.83 0.855986 209.017 3.00639 209.017 15.7744C209.017 23.9728 203.238 32.0368 190.336 38.0848L164.262 50.3152C157.408 53.5408 155.66 57.4384 158.08 64.4272L164.8 83.7808C169.772 98.1616 160.768 105.016 152.166 105.016C142.086 105.016 136.576 97.8928 135.635 88.0816C134.425 75.1792 144.908 61.2016 153.51 50.584Z"
        fill="white"
      />
      <path
        d="M70 180.377L134.005 181.624"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
      />
      <path
        d="M101.371 213.004L102.618 148.999"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
      />
    </svg>
  </FinalScreenCircle>
</template>

<script setup lang="ts">
import FinalScreenCircle from "./FinalScreenCircle.vue";

const emit = defineEmits(["next"]);
</script>

<style scoped lang="sass">
@import "@/sass/functions"
.first-circle
  width: vw(476px)
  height: vw(476px)

  svg 
    width: vw(210px)
    height: vw(215px)
</style>
