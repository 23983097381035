<template>
  <div class="interactive tag-cloud-page">
    <h1 class="tag-cloud-page__title">
      Опишите одним словом <br />
      свои впечатления о мероприятии
    </h1>
    <div class="tag-cloud">
      <div
        id="tag-cloud"
        class="tag-cloud-content tag-cloud-page__content"
      ></div>
      <div class="tag-cloud-field" v-if="token">
        <input
          v-model="textInput"
          type="text"
          maxlength="30"
          placeholder="Введите Вашу ассоциацию"
          class="tag-cloud-control"
          @keyup.enter="sendTag"
        />
        <button
          class="tag-cloud-button"
          :disabled="textInput.length === 0"
          @click="sendTag"
        >
          отправить
        </button>
      </div>

      <div class="tag-cloud-page__footer">
        <info-icon
          class="tag-cloud-page__footer-icon"
          @click="showFooterAction"
        />

        <span class="tag-cloud-page__footer-text">
          АО “Рош-Москва” 107031, г.Москва, Трубная площадь, дом 2 moscow.reception@roche.com M-RU-00015465 февраль 2024. Информация на данном веб-сайте предназначена исключительно для медицинских работников. Сайт предназначен только для посетителей из Российской Федерации
          <br>
          Вы можете помочь в осуществлении мониторинга безопасности препаратов компании Рош, передав сообщение о нежелательном явлении (побочном действии), ассоциированном с применением препарата, в компанию АО «Рош-Москва» по телефону 8(495) 229-29-99 (офис), электронной почте moscow.ds@roche.ru или через форму обратной связи на сайте www.roche.ru Просим обращаться в АО “Рош-Москва” в том случае, если у вас возникнут вопросы или потребуются дополнительные сведения по применению препаратов компании Рош, через форму обратной связи на сайте www.roche.ru, по телефону 8(495)229-29-99 (офис) или электронной почте moscow.medinfo@roche.ru Предоставляя Ваши персональные данные при направлении информации, Вы соглашаетесь с условиями и порядком обработки Ваших персональных данных АО "Рош-Москва", установленных Политикой оператора.
        </span>
        <transition name="tag-cloud-transition">
          <div class="tag-cloud-page__footer-info" v-if="showFooter">
            Медицинская информация по препарату обинутузумаб
            <div class="tag-cloud-page__footer-info-block">
              <img
                src="@/assets/img/qr-1.png"
                class="tag-cloud-page__footer-info-qr"
              />
              <div>
                Чтобы ознакомиться с общей характеристикой лекарственного
                препарата <br />
                (ОХЛП) обинутузумаб, отсканируйте QR-код или перейдите по
                ссылке:
                <br />
                <a
                  href="https://www.roche.ru/ru/produkty/katalog/gazyva.html"
                  target="_blank"
                  >https://www.roche.ru/ru/produkty/katalog/gazyva.html</a
                >
              </div>
            </div>
            Медицинская информация по препарату мосунетузумаб
            <div class="tag-cloud-page__footer-info-block">
              <img
                src="@/assets/img/qr-2.png"
                class="tag-cloud-page__footer-info-qr"
              />
              <div>
                Чтобы ознакомиться с общей характеристикой лекарственного
                препарата <br />(ОХЛП) мосунетузумаб, отсканируйте QR-код или
                перейдите по ссылке: <br />
                <a
                  href="https://www.roche.ru/resheniya/katalog/lunsumio"
                  target="_blank"
                  >https://www.roche.ru/resheniya/katalog/lunsumio</a
                >
              </div>
            </div>

            Если Вам требуется распечатанная актуальная информация, свяжитесь с
            нами по телефону <br />
            +7(495) 229 29 99, и мы вышлем ее по указанному Вами адресу.

            <br />
            <br />
            <div class="tag-cloud-page__footer-info-footer">
              АО"Рош-Москва"<br />
              107031,Россия, г. Москва,<br />
              Трубнаяплощадь, дом 2,<br />
              ПомещениеI, этаж 1, комната 42, <br />
              МФК"Галерея Неглинная".<br />
              Тел.:+7 (495) 229–29–99
            </div>
            <close-icon
              class="tag-cloud-page__footer-close"
              @click="showFooter = false"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import InfoIcon from "@/components/icons/InfoIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";
import axios from "axios";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { ymEvent } from "@/utils/ymEvent";
let chart: am4plugins_wordCloud.WordCloud | null = null;
let series: am4plugins_wordCloud.WordCloudSeries | null = null;
const textInput = ref("");
const store = useStore();

const showFooter = ref(false);

const words = computed(() => {
  const data = store.state.metadata?.presentation_data || [];

  return data.map((el: { name: string; interactions: number }) => {
    return {
      tag: `#${el.name.charAt(0).toUpperCase() + el.name.slice(1)}`,
      weight: el.interactions,
    };
  });
});

const token = computed(() => {
  return store.state.token;
});

const showFooterAction = async () => {
  showFooter.value = true;
  ymEvent('infoandsourcesbutton')
};
const sendTag = async () => {
  const name = textInput.value.toLowerCase();

  if (!name.length) return;

  textInput.value = "";

  await axios.post(
    `${process.env.VUE_APP_BASE_API_URL}/api/tags/suggest`,
    {
      name,
    },
    {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    }
  );
  ymEvent('sentwordincloud')
};

let interval: any = null;

watch(words, () => {
  if (!series) {
    return;
  }
  const chartDiv = document.getElementById("tag-cloud");

  chartDiv?.classList.add("fade-out");

  if (interval) {
    clearInterval(interval);
    interval = null;
  }

  interval = setTimeout(() => {
    series!.data = words.value;
  }, 700);
});

onMounted(() => {
  const chartDiv = document.getElementById("tag-cloud");
  if (!chartDiv) {
    return;
  }
  chart = am4core.create(chartDiv, am4plugins_wordCloud.WordCloud);
  series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());
  series.data = words.value;
  series.dataFields.word = "tag";
  series.dataFields.value = "weight";
  series.randomness = 0.1;
  series.accuracy = 5;

  series.colors = new am4core.ColorSet();
  series.colors.list = [
    "126, 80, 147, 1",
    "49, 8, 68, 1",
    "139, 21, 194, 1",
    "49, 8, 68, 1",
    "58, 46, 111, 1",
  ].map((el) => {
    const [r, g, b, a] = el.split(",").map((el) => parseFloat(el));
    return am4core.color({ r, g, b, a });
  });
  series.colors.reuse = true;
  series.maxFontSize = new am4core.Percent(60);
  series.minFontSize = new am4core.Percent(4);
  series.labelsContainer.rotation = 0;
  series.angles = [0];

  series.events.on("arrangeprogress", function () {
    chartDiv.classList.remove("fade-out");
    chartDiv.classList.add("fade-in");

    setTimeout(() => {
      chartDiv.classList.remove("fade-in");
    }, 700);
  });

  const check = document.querySelector<HTMLElement>("[aria-labelledby]");

  if (check) {
    check.style.opacity = "0";
  }
});

onBeforeUnmount(() => {
  if (chart) {
    chart.dispose();
  }
});
</script>

<style scoped lang="sass">
@import "@/sass/functions"

.tag-cloud-page
  &__title
    color: rgba(49, 8, 68, 1)
    font-size: vw(56px)
    font-weight: 700
    line-height: vw(56px)
    letter-spacing: 0.02em
    padding: vw(25px) vw(67px)
    width: vw(1100px)
    text-transform: uppercase

  &__footer
    position: absolute
    left: vw(29px)
    right: vw(29px)
    bottom: vw(15px)
    color: rgba(88, 80, 89, 1)
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: vw(20px)
    &-icon
      margin-right: vw(24px)
      height: vw(32px)
      width: vw(32px)
      cursor: pointer

    &-text
      margin-bottom: vw(4px)
      font-size: vw(12px)
      line-height: vw(17px)
      letter-spacing: 0.02em
    &-info
      box-sizing: border-box
      z-index: 1
      position: absolute
      box-shadow: 0px 44px 72px 0px rgba(0, 0, 0, 0.32)
      padding: vw(80px) vw(24px) vw(24px)
      font-size: vw(15px)
      line-height: vw(24px)
      letter-spacing: 0.02em
      color: #2D2830
      border-radius: vw(16px)
      background: #fff
      overflow: hidden
      bottom: 0
      width: vw(694px)
      height: vw(577px)
      &-footer
        line-height: vw(17px)

      &-qr
        width: vw(128px)
        height: vw(129px)
      &-block
        display: flex
        gap: 6px
        align-items: center

        a
          color: #2D2830
          text-decoration: underline
    &-close
      position: absolute
      top: vw(24px)
      right: vw(26px)
      width: vw(32px)
      height: vw(32px)
      cursor: pointer

  &__content
    width: vw(1000px)
    height: vw(733px)

@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1


@keyframes fadeOut
  0%
    opacity: 1
  100%
    opacity: 0

.fade-in
  animation: fadeIn 0.7s ease-in-out
.fade-out
  animation: fadeOut 0.7s ease-in-out

.tag-cloud-transition-enter-active,
.tag-cloud-transition-leave-active
  transition: all 0.3s ease

.tag-cloud-transition-enter-to,
.tag-cloud-transition-leave-from
  width: vw(694px)
  height: vw(577px)
  opacity: 1

.tag-cloud-transition-enter-from,
.tag-cloud-transition-leave-to
  width: 0
  height: 0
  padding: 0
  opacity: 0
</style>
