<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">До 2023 года для терапии рецидивов ФЛ в РФ были <br>одобрены 4 препарата, воздействующие на различные <br>молекулярные мишени, важные для патогенеза</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-6">
          <img src="@/assets/img/frame-6.png" alt="">
          <div class="content-frame-buttons">
            <button class="content-frame-button" @click="$store.dispatch('setPageByUser', 13)">ЛЕНАЛИДОМИД</button>
            <button class="content-frame-button" @click="$store.dispatch('setPageByUser', 17)">ДУВЕЛИСИБ</button>
            <button class="content-frame-button" @click="$store.dispatch('setPageByUser', 4)">РИТУКСИМАБ</button>
            <button class="content-frame-button" @click="$store.dispatch('setPageByUser', 8)">ОБИНУТУЗУМАБ</button>
          </div>
        </div>
        <div class="frame-note">Chen C-JJ, Choi MY, Heyman BM. Cancers. 2023; 15(18):4483</div>
      </div>
    </div>
    <Pagination :total="3" :page="3" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapsePage_3",
    components: {
      Pagination,
    }
  }
</script>