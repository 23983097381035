<template>
  <div class="second-card" :class="{ 'second-card--pinned': pinned }">
    <img
      src="@/assets/img/final-screen/final-screen-globus.svg"
      class="second-card__globus"
    />
    <img
      src="@/assets/img/final-screen/final-screen-50.svg"
      class="second-card__50"
    />

    <p class="second-card__text">
      стран, в которых<br />
      зарегистрирован<br />
      мосунетузумаб
    </p>

    <p class="second-card__footer">Данные компании Roche</p>

    <FinalScreenClose
      class="second-card__close"
      @click="emit('next')"
      v-if="!pinned"
    />
  </div>
</template>

<script setup lang="ts">
import FinalScreenClose from "./icons/FinalScreenClose.vue";
const emit = defineEmits(["next"]);
defineProps<{ pinned: boolean }>();
</script>

<style lang="sass" setup>
@import "@/sass/functions"

.second-card
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    background: rgba(49, 8, 68, 1)
    padding: vw(64px) vw(42px) vw(24px)
    border-radius: vw(16px)
    overflow: hidden
    &__globus
        width: vw(240px)
        height: vw(240px)
        margin-bottom: vw(52px)
    &__50
        width: vw(118px)
        height: vw(55px)
        margin-bottom: vw(32px)
    &__text
        font-size: vw(28px)
        font-weight: 500
        line-height: vw(36px)
        letter-spacing: 0.02em
        text-align: center
        color: rgba(255, 255, 255, 1)
        margin-bottom: vw(52px)

    &__footer
        color: rgba(255, 255, 255, 0.5)
        font-size: vw(14px)
        font-weight: 500
        line-height: vw(22px)
        letter-spacing: 0.02em

    &__close
        position: absolute
        top: vw(24px)
        right: vw(24px)
        width: vw(42px)
        height: vw(42px)
        cursor: pointer

    &--pinned
        padding: vw(37px) vw(25px) vw(13px)

        .second-card
            &__globus
                width: vw(140px)
                height: vw(140px)
                margin-bottom: vw(30px)
            &__50
                width: vw(68px)
                height: vw(32px)
                margin-bottom: vw(18px)

            &__text
                font-size: vw(16px)
                line-height: vw(21px)
                font-weight: 500
                letter-spacing: 0.02em
                color: rgba(255, 255, 255, 1)
                margin-bottom: vw(29px)

            &__footer
                color: rgba(255, 255, 255, 0.5)
                font-size: vw(8px)
                font-weight: 500
                line-height: vw(12px)
                letter-spacing: 0.02em
</style>
