<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Клиническая гетерогенность ФЛ</h1>
        <p class="text">От спонтанных ремиссий (5-10%) до рефрактерных форм <br>и раннего прогрессирования заболевания, POD24 (20-30%)</p>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-1">
          <img src="@/assets/img/frame-1.png" alt="">
        </div>
        <div class="frame-note">1. Chan FC (2017) Diss. University of British Columbia (https://open.library.ubc.ca/soa/cIRcle/collections/ubctheses/24/items/1.0343370, дата доступа 14.02.2024); <br>2. Casulo et al. (2015). J Clin Oncol 33: 2516–22 <br>ИХТ, иммунохимиотерапия</div>
      </div>
    </div>
    <Pagination :total="3" :page="1" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapsePage_1",
    components: {
      Pagination,
    }
  }
</script>