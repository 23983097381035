<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Существуют ли предикторы тяжести СВЦ?</h1>
        <br>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-49">
          <img src="@/assets/img/frame-49.png" alt="">
        </div>
        <div class="frame-notes frame-notes--5">
          <div class="frame-note large">Matasar et al. (2023) Clinical Lymphoma Myeloma and Leukemia.In press</div>
        </div>
      </div>
    </div>
    <Pagination :total="17" :page="8" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_10",
    components: {
      Pagination,
    }
  }
</script>