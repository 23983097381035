<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Сколько линий терапии получает пациент с ФЛ?</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-3">
          <img src="@/assets/img/frame-3.gif" alt="">
        </div>
        <div class="frame-note">1. Linton et al. Proc ASH 2023. Abstr 1655; 2. Arnason et al. ASH 2023. Abstr 427; 3. Schuster et al. ASH 2023. Abstr 603; 4. Schuster et al. ASH 2023. Abstr 601; 5. Morschhauser et al. ASH 2023. Abstr 602; 6. Neelapu et al. ASH 2023. <br>Abstr 4868; 7. Salles G, et al (2022) Hemasphere. 6(7):e745. 8. Huntington SF et al. JHEOR. 2022; 9(2): 115-122; 9. Клиническая онкогематология. Фундаментальные исследования и клиническая практика (2023): <br>16 (2), 227-228,doi:10.21320/6nkr3a68 </div>
      </div>
    </div>
    <Pagination :total="4" :page="3" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapsePage_3",
    components: {
      Pagination,
    }
  }
</script>