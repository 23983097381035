<script setup lang="ts"></script>

<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.8151 7.85155L21.361 25.5777C20.8722 27.1662 20.6276 27.9609 20.206 28.2243C19.8403 28.4528 19.3876 28.491 18.9893 28.3257C18.5301 28.1352 18.1574 27.3911 17.4139 25.904L14.7258 20.5278L17.373 14.855L11.8178 17.4475L6.09598 14.5866C4.60891 13.843 3.8653 13.4709 3.67476 13.0117C3.5095 12.6135 3.54719 12.1603 3.77567 11.7946C4.03912 11.373 4.83372 11.128 6.4228 10.6391L24.1489 5.18489C25.3982 4.80049 26.0231 4.60845 26.4451 4.76336C26.8126 4.8983 27.1024 5.18778 27.2373 5.55533C27.3921 5.97709 27.2 6.60166 26.816 7.84965L26.8159 7.84971L26.8151 7.85155Z"
      fill="white"
    />
  </svg>
</template>

<style scoped lang="sass"></style>
