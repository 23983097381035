<template>
  <ChapterView>
    <template v-slot:title><Chapter_4_Title /></template>
    <template v-slot:avatar><img src="@/assets/img/avatar-11.png" alt=""></template>
    <template v-slot:name>Дубов <br>Виталий Сергеевич</template>
    <template v-slot:regalia>ГБУЗ ККБ №2</template>
  </ChapterView>
</template>

<script>
import ChapterView from '@/components/ChapterView'
import Chapter_4_Title from '@/components/Chapter_4_Title.vue'

export default {
  name: 'Chapter_4',
  components: {
    ChapterView,
    Chapter_4_Title,
  },
}
</script>
