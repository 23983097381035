<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Регистрационное исследование</h1>
        <p class="text large">GO29781</p>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-16">
          <img src="@/assets/img/frame-16.png" alt="">
        </div>
        <div class="frame-note">Budde LE, et al. (2022) The Lancet Oncology, 23(8): 1055-1065 <br>ПО - полный ответ; ЧО - частичный ответ; СЗ - стабилизация заболевания; СВЦ - синдром высвобождения цитокинов; ЧОО - частота объективного ответа; ВБП - выживаемость без прогрессирования; <br>ОВ - общая выживаемость; Ц - цикл; Д - день</div>
      </div>
    </div>
    <Pagination :total="15" :page="4" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapseTherapyPage_4",
    components: {
      Pagination,
    }
  }
</script>