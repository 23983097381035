<script setup lang="ts"></script>

<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.20711 3.29289C4.81658 2.90237 4.18342 2.90237 3.79289 3.29289C3.40237 3.68342 3.40237 4.31658 3.79289 4.70711L27.7929 28.7071C28.1834 29.0976 28.8166 29.0976 29.2071 28.7071C29.5976 28.3166 29.5976 27.6834 29.2071 27.2929L21.5 19.5858V10C21.5 8.89543 20.6046 8 19.5 8H9.91421L5.20711 3.29289ZM4.5 8H5.08579L20.6919 23.6062C20.3591 23.8536 19.9466 24 19.5 24H4.5C3.39543 24 2.5 23.1046 2.5 22V10C2.5 8.89543 3.39543 8 4.5 8ZM27.9188 9.12946L22.5 13V19L27.9188 22.8705C28.5806 23.3433 29.5 22.8702 29.5 22.0568V9.94319C29.5 9.12982 28.5806 8.65669 27.9188 9.12946Z"
      fill="white"
    />
  </svg>
</template>

<style scoped lang="sass"></style>
