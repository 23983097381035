interface Point {
  x: number;
  y: number;
}

export const findClosest = (arr: Point[], target: number): Point => {
  let diff = 1e10;
  let minPoint = arr[0];

  for (let i = 0; i < arr.length; i++) {
    const isLeft = arr[i].x - target > 0;

    const newDiff = Math.abs(arr[i].x - target);

    if (isLeft && newDiff < diff) {
      diff = newDiff;
      minPoint = arr[i];
      continue;
    }

    if (!isLeft && (Math.abs(newDiff - diff) < 1e6 || newDiff < diff)) {
      diff = newDiff;
      minPoint = arr[i];
    }
  }

  return minPoint;
};
