<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">ICANS может иметь различные <br>клинические проявления</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-60">
          <img src="@/assets/img/frame-60.png" alt="">
        </div>
        <div class="frame-note large">1. Borrega JG, et al. HemaSphere2019;3:e191 <br>2. Lee DW, et al. Biol Blood Marrow Transplant2019;25:625–38 <br>3. Santomasso B, et al. Am Soc Clin Oncol Educ Book 2019;39:433–4</div>
      </div>
    </div>
    <Pagination :total="6" :page="3" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_21",
    components: {
      Pagination,
    }
  }
</script>