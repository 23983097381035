<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Симптомы СВЦ крайне разнообразны</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-42">
          <img src="@/assets/img/frame-42.png" alt="">
        </div>
        <div class="frame-note large">García Roche Aet al(2019) Med Intensiva (Engl Ed)43(8): 480-488;AdkinsS. J Adv Pract Oncol2019;10:21–8; Shimabukuro-Vornhagen A et al. J ImmunotherCancer 2018;6:56</div>
      </div>
    </div>
    <Pagination :total="17" :page="3" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_3",
    components: {
      Pagination,
    }
  }
</script>