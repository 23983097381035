<template>
  <ChapterView>
    <template v-slot:title><Chapter_4_Title_Part_2 /></template>
    <template v-slot:avatar><img src="@/assets/img/avatar-12.png" alt=""></template>
    <template v-slot:name>Гаммершмидт <br>Юлия Сергеевна</template>
    <template v-slot:regalia>ОГАУЗ «ТОКБ»</template>
  </ChapterView>
</template>

<script>
import ChapterView from '@/components/ChapterView'
import Chapter_4_Title_Part_2 from '@/components/Chapter_4_Title_Part_2.vue'

export default {
  name: 'Chapter_4_part_2',
  components: {
    ChapterView,
    Chapter_4_Title_Part_2,
  },
}
</script>
