<template>
  <div class="third-card" :class="{ 'third-card--pinned': pinned }">
    <FinalScreenClose
      class="third-card__close"
      @click="emit('next')"
      v-if="!pinned"
    />

    <img
      src="@/assets/img/final-screen/final-screen-doctor.png"
      class="third-card__doctor"
    />
    <p class="third-card__title">
      Мосунетузумаб <br />
      изучается в
    </p>
    <img
      src="@/assets/img/final-screen/final-screen-33.svg"
      class="third-card__33"
    />
    <p class="third-card__subtitle">
      клинических <br />
      исследованиях
    </p>

    <p class="third-card__footer">
      <a
        href="https://clinicaltrials.gov/search?intr=Mosunetuzumab"
        target="_blank"
        >https://clinicaltrials.gov/search?intr=Mosunetuzumab</a
      >
      <br />дата доступа 12.02.2024
    </p>
  </div>
</template>

<script setup lang="ts">
import FinalScreenClose from "./icons/FinalScreenClose.vue";
defineProps<{ pinned: boolean }>();
const emit = defineEmits(["next"]);
</script>

<style scoped lang="sass">
@import "@/sass/functions"

.third-card
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    background: rgba(49, 8, 68, 1)
    padding: vw(100px) vw(43px) vw(24px)
    border-radius: vw(16px)

    &__doctor
        width: vw(215px)
        height: vw(207px)
        margin-bottom: vw(30px)

    &__33
        width: vw(92px)
        height: vw(43px)
        margin-bottom: vw(32px)

    &__title
        font-size: vw(24px)
        font-weight: 500
        line-height: vw(36px)
        height: vw(53px)
        letter-spacing: 0.01em
        text-align: center
        margin-bottom: vw(32px)
        color: rgba(255, 255, 255, 1)

    &__subtitle
        font-size: vw(24px)
        line-height: vw(36px)
        letter-spacing: 0.01em
        text-align: center
        height: vw(53px)
        font-weight: 500

        margin-bottom: vw(32px)
        color: rgba(255, 255, 255, 1)

    &__footer
        color: rgba(255, 255, 255, 0.5)
        font-size: vw(14px)
        font-weight: 500
        line-height: vw(22px)
        letter-spacing: 0.02em
        text-align: center
        a
          color: rgba(255, 255, 255, 0.5)
          text-decoration: underline

    &__close
        position: absolute
        top: vw(24px)
        right: vw(24px)
        width: vw(42px)
        height: vw(42px)
        cursor: pointer

    &--pinned
        padding: vw(75px) vw(25px) vw(13px)
        border-radius: vw(16px)
        .third-card
            &__doctor
                width: vw(104px)
                height: vw(100px)
                margin-bottom: vw(24px)

            &__33
                width: vw(54px)
                height: vw(25px)
                margin-bottom: vw(18px)

            &__title
                font-size: vw(14px)
                line-height: vw(12px)
                margin-bottom: vw(18px)
                height: vw(31px)
                font-weight: 500


            &__subtitle
                font-size: vw(14px)
                line-height: vw(21px)
                margin-bottom: vw(19px)
                height: vw(31px)
                font-weight: 500

            &__footer
                font-size: vw(8px)
                line-height: vw(14px)
                font-weight: 500
</style>
