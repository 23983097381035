<template>
  <div class="page background-3 graph-page">
    <div class="page-wrapper">
      <div class="graph-page__header">
        <h1 class="graph-page__title">
          Выживаемость без <br />
          прогрессирования в 3+ линии <br />
          терапии ФЛ: Ваши прогнозы?
        </h1>
        <div class="graph-page__task">
          <div class="graph-page__task-icon"></div>
          <div class="graph-page__task-text">
            Кликая мышкой, поставьте точки на графике ниже, нажмите кнопку
            «Построить кривую» — и Вы увидите, как построится график
            беспрогрессивной выживаемости для 3+ линии терапии по Вашему
            прогнозу
          </div>
        </div>
      </div>

      <div class="page-content graph-page__content">
        <div class="graph">
          <div class="graph-page__vbp">
            ВБП
          </div>
          <div class="graph-frame">
            <canvas
              id="static"
              width="60vw"
              height="40vw"
              style="position: absolute"
            ></canvas>
            <canvas
              id="crosshair"
              class="crosshair-chart"
              :class="{ 'crosshair-chart--final': isFinalScreen }"
              width="60vw"
              height="40vw"
              style="position: absolute"
            ></canvas>

            <canvas id="chart" width="60vw" height="40vw"></canvas>
          </div>

          <div
            class="interactive-elements"
            :class="{ 'interactive-elements--result': showResult }"
          >
            <template v-if="showResult">
              <graph-toggle
                v-if="renderedPoints.length && store.state.token"
                v-model="renderMyLine"
                text="Моя кривая"
                color="rgba(255, 197, 48, 1)"
              />

              <graph-toggle
                v-model="renderCollectiveLine"
                color="rgba(255, 255, 255, 0.6)"
                text="Коллективная кривая"
                note="(мнение всех участников)"
              />

              <graph-toggle
                v-model="renderResearchLine"
                color="rgba(255, 255, 255, 1)"
                text="Кривая исследования<sup>1</sup>"
              />
            </template>
            <template v-else>
              <button
                class="graph-page__button-revert"
                @click="removeLastDot"
                v-if="!dataSent"
              >
                <revert-icon
                  class="graph-page__button graph-page__button-icon"
                />
                Отменить <br />действие
              </button>
              <button
                type="button"
                v-if="store.state.token && !dataSent"
                class="graph-button button graph-page__button graph-page__button-curve"
                :disabled="dataSent"
                @click="sendData"
              >
                построить кривую
              </button>
            </template>
          </div>
        </div>
      </div>

      <div class="graph-page__footer" v-if="showResult">
        1. Kanters, S. et al. BMC Cancer 23, 74 (2023)
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ActiveElement, ChartEvent, ChartTypeRegistry, Plugin } from "chart.js";
import Chart from "chart.js/auto";
import "nouislider/dist/nouislider.css";
import { getRelativePosition } from "chart.js/helpers";
import { findClosest } from "@/utils/find-closes";
import axios from "axios";
import {
  graphToRender,
  interpolateGraphToRender,
} from "@/constants/graph-to-render";
import { Point } from "@/types/Point";
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { graphFinalCurve } from "@/constants/graph-final-curve";
import RevertIcon from "./RevertIcon.vue";

import { renderCrosshair } from "@/utils/render-crosshair";
import { smoothleRenderLine } from "@/utils/soothle-render-line";
import { LINE_ID } from "@/types/line-id";
import {
  createBasicChart,
  createCorsshairChart,
  createStaticChart,
} from "@/utils/create-charts";
import { renderBackground } from "@/utils/render-background";
import {
  findHoveredDataset,
  findMediumIntersection,
} from "@/utils/find-medium-intersection";
import {
  getCollectiveLindeDataSet,
  getMediumDataset,
  getMyLineDataSet,
  getResearchDataset,
} from "@/constants/final-line-datasets";
import GraphToggle from "./GraphToggle.vue";

let chart: Chart | null = null;
let staticChart: Chart | null = null;
let crosshairChart: Chart | null = null;

const props = defineProps<{
  currentState: "7_graph_start" | "7_graph_result";
}>();

const renderedPoints = ref<Point[]>([]);
const storageKey = "savedPoints";
const store = useStore();

const chartType = ref<keyof ChartTypeRegistry>("scatter");
const dataSent = ref(false);
const showResult = ref(false);

const renderCollectiveLine = ref(true);
const renderMyLine = ref(true);
const renderResearchLine = ref(true);
const isFinalScreen = ref(false);

const showGraphText = ref(false);
let leftGraphPosition = 0;
let leftGraphPoint = 0;
const showBottomGraphText = ref(false);

const fixedPoints = [0, 12, 24, 36, 48, 60, 72, 84, 96, 108, 120];

const getMyDataset = (): Record<LINE_ID, Point[]> => {
  const backendData = store.state.metadata?.presentation_data || [];

  return {
    [LINE_ID.MyLine]: renderedPoints.value,
    [LINE_ID.CollectiveLine]: fixedPoints.map((el, idx) => ({
      x: el,
      y: backendData[idx].avg,
    })),
    [LINE_ID.ResearchLine]: graphFinalCurve,
  };
};

const plugin: Plugin = {
  id: "background",
  beforeDraw: renderBackground,
};

const pluginText: Plugin = {
  id: "pluginText",
  beforeDraw: (chart) => {
    const { ctx, chartArea, canvas } = chart;
    if (canvas.id !== "static") {
      return;
    }

    if (showResult.value && showGraphText.value) {
      let top = showBottomGraphText.value
        ? Math.floor(chartArea.height * 0.45)
        : chartArea.height * 0.519;
      const left = leftGraphPosition + chartArea.width * 0.01;
      const textHeight = chartArea.width * 0.04;
      const secondTextHeight = chartArea.width * 0.027;

      ctx.font = `500 ${textHeight}px Roboto Condensed`;

      ctx.fillStyle = "white";

      ctx.fillText(`Медиана ВБП — ${leftGraphPoint.toFixed(2)} мес`, left, top);

      if (showBottomGraphText.value) {
        top = top + textHeight + chartArea.width * 0.01;

        ctx.font = `${secondTextHeight}px Roboto Condensed`;

        ctx.fillStyle = "white";

        ctx.fillText(`(95% ДИ 9,01-10,63)`, left, top);
      }
    }
  },
};

const onChartClick = (
  event: ChartEvent,
  elements: ActiveElement[],
  scopedChart: Chart,
  isSecond = false
) => {
  if (!store.state.token) return;

  if (!scopedChart) {
    return;
  }

  if (chartType.value !== "scatter") {
    return;
  }

  if (dataSent.value) {
    return;
  }

  const canvasPosition = getRelativePosition(event, scopedChart as any);
  const dataX = scopedChart.scales.x.getValueForPixel(canvasPosition.x)!;
  const dataY = scopedChart.scales.y.getValueForPixel(canvasPosition.y)!;

  const chartData = scopedChart.data.datasets[0].data;
  const lastPoint: Point = (chartData[chartData.length - 1] as Point) || {
    x: 0,
    y: 1,
  };

  const { y: lastY } = lastPoint;

  let newData: Point = {
    x: isSecond ? dataX + 13 : dataX,
    y: dataY > lastY ? lastY : dataY,
  };

  scopedChart.data.datasets[0].data.push(newData);
  scopedChart.update();

  renderedPoints.value.push(newData);
};

const removeLastDot = () => {
  const lastPoint =
    chart?.data.datasets[0].data[chart?.data.datasets[0].data.length - 1];
  if (lastPoint && lastPoint.id === "custom") {
    return;
  }

  chart?.data.datasets[0].data.pop();
  renderedPoints.value.pop();
  chart?.update();
};

const updateLegendOnHover = (p: Point) => {
  if (!chart) {
    return;
  }

  chart.data.datasets.forEach((el) => {
    if (el.id === LINE_ID.CollectiveLine) {
      el.borderWidth = 1;
    } else if (el.id === LINE_ID.ResearchLine) {
      el.borderWidth = 3;
    } else if (el.id === LINE_ID.MyLine) {
      el.borderWidth = 2;
    }
  });
  chart.update();

  if (staticChart?.data.datasets[0].id === "dashedLine") {
    staticChart?.data.datasets.shift();
    showGraphText.value = false;
    staticChart?.update();
  }
  const sourceMap = getMyDataset();

  const dataSet = findHoveredDataset(chart, sourceMap, p);

  if (!dataSet) {
    return;
  }

  if (dataSet.id === LINE_ID.CollectiveLine && !renderCollectiveLine.value) {
    return;
  } else if (dataSet.id === LINE_ID.ResearchLine && !renderResearchLine.value) {
    return;
  } else if (dataSet.id === LINE_ID.MyLine && !renderMyLine.value) {
    return;
  }

  const mediumPoint = findMediumIntersection(chart, sourceMap, dataSet);

  if (!mediumPoint) {
    return;
  }
  const { x: scaleX, y: scaleY } = chart.scales;

  dataSet.borderWidth = 4;
  chart.update();
  const newX = scaleX.getValueForPixel(mediumPoint.x);
  const newY = scaleY.getValueForPixel(mediumPoint.y);

  const staticDataSet = getMediumDataset({ x: newX!, y: newY! });
  showGraphText.value = true;
  leftGraphPosition = mediumPoint.x;
  leftGraphPoint = newX!;

  showBottomGraphText.value = dataSet.id === LINE_ID.ResearchLine;

  staticChart?.data.datasets.unshift(staticDataSet as any);
  staticChart?.update();
};

const createCharts = (type: keyof ChartTypeRegistry) => {
  const chartRoot = document.getElementById("chart") as HTMLCanvasElement;

  if (!chartRoot) {
    return;
  }

  chart = createBasicChart(type, [...renderedPoints.value]);

  const crosshairOnClick = (e: ChartEvent) => {
    return onChartClick(e, [], chart!);
  };
  const crosshairOnHover = (
    e: ChartEvent,
    elements: ActiveElement[],
    ch: Chart
  ) => {
    renderCrosshair(e, ch, isFinalScreen.value);
    updateLegendOnHover({ x: e.x!, y: e.y! });
  };
  crosshairChart = createCorsshairChart(crosshairOnClick, crosshairOnHover);
  staticChart = createStaticChart();
};

let researchDatasetInterval: ReturnType<typeof setInterval> | null = null;
let myLineDataSeInterval: ReturnType<typeof setInterval> | null = null;
let collectiveLindeDataSetInterval: ReturnType<typeof setInterval> | null =
  null;

const renderResultData = () => {
  if (!chart) {
    return;
  }

  showResult.value = true;
  isFinalScreen.value = true;
  const myData = interpolateGraphToRender(renderedPoints.value, 20);

  const backendData = store.state.metadata?.presentation_data || [];
  const collectiveData = interpolateGraphToRender(
    fixedPoints.map((el, idx) => ({ x: el, y: backendData[idx].avg })),
    15
  );

  const researchData = interpolateGraphToRender(graphFinalCurve, 10);

  const myLineDataSet = getMyLineDataSet();
  const collectiveLindeDataSet = getCollectiveLindeDataSet();
  const researchDataset = getResearchDataset();

  chart.data.datasets.push(myLineDataSet);
  chart.data.datasets.push(collectiveLindeDataSet);
  chart.data.datasets.push(researchDataset);

  chart.update();
  researchDatasetInterval = smoothleRenderLine(
    chart,
    researchDataset,
    researchData,
    1
  );
  myLineDataSeInterval = smoothleRenderLine(chart, myLineDataSet, myData, 5);
  collectiveLindeDataSetInterval = smoothleRenderLine(
    chart,
    collectiveLindeDataSet,
    collectiveData,
    5
  );
};

let renderLinesInterval: ReturnType<typeof setInterval> | null = null;
let hideLinesTimeout: ReturnType<typeof setTimeout> | null = null;
let goNextTimeout: ReturnType<typeof setTimeout> | null = null;

const renderLines = () => {
  dataSent.value = true;
  if (!chart) {
    return;
  }
  const baseChart = chart.data.datasets.find((el) => el.id === "baseChart");

  if (baseChart) {
    baseChart.hidden = true;
    chart.update();
  }

  chartType.value = "line";
  chart.config.type = "line";

  chart.data.datasets.pop();
  chart.update();

  graphToRender.forEach((line) => {
    const dataSet = {
      data: [],
      backgroundColor: line.color,
      borderColor: line.color,
      borderRadius: 0,
      pointRadius: 0,
    };
    chart?.data.datasets.push(dataSet);
    chart?.update();
  });

  let idx = 0;

  const preparedData = graphToRender.map((line) => {
    return {
      color: line.color,
      points: interpolateGraphToRender(line.points, 15),
    };
  });

  renderLinesInterval = setInterval(() => {
    if (idx === preparedData[0].points.length) {
      hideLinesTimeout = setTimeout(() => {
        chart?.data.datasets.forEach((d, index) => {
          chart?.hide(index);
          chart?.update("hide");
        });

        goNextTimeout = setTimeout(() => {
          chart!.data.datasets = [];
          renderResultData();
          return;
        }, 1000);
      }, 300);

      if (renderLinesInterval) {
        clearInterval(renderLinesInterval);
      }

      renderLinesInterval = null;
      return;
    }

    preparedData.forEach((line, i) => {
      chart?.data.datasets[i].data.push(line.points[idx]);
    });

    idx += 1;
    chart?.update();
  }, 3);
};

const connectDots = () => {
  if (!chart) {
    return;
  }

  chart.hide(0);
  chart.data.datasets[0].data = [];
  chart.data.datasets[0].type = "line";
  chart.options.elements!.point!.pointStyle = false;
  chart.data.datasets[0].borderColor = "#FFD15B";

  chart.update();
  const points = interpolateGraphToRender(renderedPoints.value, 15);
  smoothleRenderLine(chart, chart.data.datasets[0], points, 10);
};

const sendData = async () => {
  if (renderedPoints.value.length === 0) {
    return;
  }
  dataSent.value = true;
  renderedPoints.value.sort((a, b) => a.x - b.x);
  localStorage.setItem(storageKey, JSON.stringify(renderedPoints.value));

  const closestPoints = fixedPoints.map((el) => {
    const closestPoint = findClosest(renderedPoints.value, el);

    return closestPoint.y;
  });

  connectDots();

  await axios.post(
    `${process.env.VUE_APP_BASE_API_URL}/api/graph`,
    {
      values: closestPoints,
    },
    {
      headers: {
        Authorization: `Bearer ${store.state.token}`,
      },
    }
  );
};

watch(
  () => props.currentState,
  () => {
    if (props.currentState === "7_graph_result") {
      renderLines();
    }
  }
);

const updateDatasetVisibility = (key: LINE_ID, value: boolean) => {
  if (!chart) {
    return;
  }
  const dataSet = chart?.data.datasets.findIndex((el) => el.id === key);

  if (dataSet !== -1) {
    if (value) {
      chart.show(dataSet);
    } else {
      chart.hide(dataSet);
    }
  }
};

watch(renderCollectiveLine, (value: boolean) =>
  updateDatasetVisibility(LINE_ID.CollectiveLine, value)
);
watch(renderMyLine, (value: boolean) =>
  updateDatasetVisibility(LINE_ID.MyLine, value)
);
watch(renderResearchLine, (value: boolean) => {
  updateDatasetVisibility(LINE_ID.ResearchLine, value);
});

onMounted(() => {
  const savedPoint = localStorage.getItem(storageKey);

  if (savedPoint) {
    renderedPoints.value = JSON.parse(savedPoint);
  }

  Chart.register(plugin);
  Chart.register(pluginText);

  createCharts("scatter");

  if (props.currentState === "7_graph_result") {
    renderLines();
  }
});

onBeforeUnmount(() => {
  Chart.unregister(plugin);
  Chart.unregister(pluginText);

  if (renderLinesInterval) {
    clearInterval(renderLinesInterval);
  }

  if (researchDatasetInterval) clearInterval(researchDatasetInterval);
  if (myLineDataSeInterval) clearInterval(myLineDataSeInterval);
  if (collectiveLindeDataSetInterval)
    clearInterval(collectiveLindeDataSetInterval);
  if (hideLinesTimeout) clearTimeout(hideLinesTimeout);
  if (goNextTimeout) clearTimeout(goNextTimeout);

  staticChart?.destroy();
  staticChart = null;

  chart?.destroy();
  chart = null;

  crosshairChart?.destroy();
  crosshairChart = null;
});
</script>

<style lang="sass" scoped>
@import "../sass/functions.sass"
@import "../sass/styles.sass"
.crosshair-chart
  cursor: url("@/assets/img/cursor.png") 5 5, auto
  &--final
    cursor: default

.graph-page
  padding: 0
  &__vbp
    position: absolute
    left: 106px
    top: vw(231px)
    font-size: 14px
    line-height: 22px
    letter-spacing: 0.02em

  &__footer
    position: absolute
    bottom: vw(13px)
    left: vw(1190px)
    font-size: vw(12px)
    line-height: vw(12px)
    letter-spacing: 0.02em
    text-align: center
    color: rgba(88, 80, 89, 1)

  &__button
    &-revert
      all: unset
      display: flex
      align-items: center
      font-size: vw(18px)
      line-height: vw(21px)
      margin-top: vw(7px)
      cursor: pointer

    &-icon, &-curve
      background: #41234F
      border: none
      outline: none
      color: #F8DDFF
      text-transform: uppercase
      font-weight: 500

      font-family: "Roboto Condensed"
      cursor: pointer
      position: relative
      outline: 1px solid #310844
      outline-offset: vw(7px)

    &-curve
      font-size: vw(18px)
      line-height: vw(20px)
      margin-bottom: vw(30px)
      padding: vw(16px) vw(20px)
      background: rgba(126, 80, 147, 1)


    &-icon
      border-radius: 9999px
      padding: vw(12px)
      flex-shrink: 0
      width: vw(49px)
      height: vw(49px)
      margin-right: vw(21px)

    &:hover
      &.graph-page__button
        &-icon, &-curve
          outline-color: #351943
          background: #351943
        &-curve
          background: rgba(126, 80, 147, 0.8)

    &:active
      &.graph-page__button
        &-revert
          color: #2a0e37
        &-icon, &-curve
          color: #a488ad
          &:deep(path)
            fill: #a488ad

  // &--result
  //   margin-top: vw(-30px)

  &__content
    padding-left: vw(64px)
  &__header
    display: flex
    align-items: center
    gap: vw(34px)
    padding: vw(56px) vw(27px) vw(50px) vw(64px)

  &__title
    font-size: vw(40px)
    line-height: vw(46px)
    font-weight: 700
    letter-spacing: 0.02em
    color: rgba(49, 8, 68, 1)


  &__task
    display: flex
    align-items: center
    border-radius: vw(16px)
    width: vw(722px)
    padding: vw(16px) vw(17px) vw(16px) vw(24px)
    background-color: #dab4e9
    gap: vw(20px)

    &-icon
      width: vw(42px)
      height: vw(42px)
      background: url("@/assets/img/task-icon.svg") no-repeat center center
      background-size: contain
      flex-shrink: 0
    &-text
      font-size: vw(22px)
      line-height: vw(30px)
      color: #2A1854
      letter-spacing: 0.02em
</style>
