<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Нежелательные явления, специфичные <br>для классов терапии, действующих за счет <br>активации Т-лимфоцитов</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-57">
          <img src="@/assets/img/frame-57.png" alt="">
        </div>
        <div class="frame-note large">1. Shimabukuro-Vornhagen A, et al. J Immunother Cancer 2018;6:56; <br>2. Garcia Borrega J, et al. Hemasphere 2019;3(2):e191.</div>
      </div>
    </div>
    <Pagination :total="18" :page="18" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_18",
    components: {
      Pagination,
    }
  }
</script>