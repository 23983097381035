<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-head mb-120 mt-20 ml-64">
        <h1 class="title full mr-15">Как выглядят пациенты с ФЛ, <br>которые нуждаются в 3 <br>линии терапии?</h1>
        <div class="task">
          <div class="task-icon"></div>
          <div class="task-text">
            <p>
              В параметрах ниже выберите значения, которые, на Ваш взгляд, наиболее точно характеризуют пациента на 3 линии ФЛ.
            </p>
          </div>
        </div>
      </div>
      <div class="page-content fix-width">
        <form class="form">
          <div class="form-wrapper">
            <div class="form-row">
              <div class="form-label"><span>Пол</span></div>
              <div class="form-content">
                <div class="radio radio--gender">
                  <label class="radio-container">
                    <div class="radio-icon man"></div>
                    <p>Пациент чаще <br>мужчина</p>
                    <input type="radio" name="gender" value="male" v-model="patient.gender" >
                    <span class="radio-checkmark"></span>
                  </label>
                </div>
                <div class="radio radio--gender">
                  <label class="radio-container">
                    <div class="radio-icon woman"></div>
                    <p>Пациент чаще <br>женщина</p>
                    <input type="radio" name="gender" value="female" v-model="patient.gender">
                    <span class="radio-checkmark"></span>
                  </label>
                </div>
                <div class="radio radio--gender">
                  <label class="radio-container">
                    <div class="radio-icon people"></div>
                    <p>Пол <br>не важен</p>
                    <input type="radio" name="gender" value="indifferent" v-model="patient.gender">
                    <span class="radio-checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label"><span>Возраст, годы</span></div>
              <div class="form-content">
                <div class="form-range">
                  <div id="slider1"></div>
                  <div class="form-range-buttons">
                    <div class="form-range-button form-range-button-lower" @click="minusAge"></div>
                    <input type="number" class="form-range-control" v-model="patient.age">
                    <div class="form-range-button form-range-button-upper" @click="plusAge"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label"><span>Время от постановки <br>диагноза до начала 3 <br>линии терапии, месяцы</span></div>
              <div class="form-content">
                <div class="form-range">
                  <div id="slider2"></div>
                  <div class="form-range-buttons">
                    <div class="form-range-button form-range-button-lower" @click="minusLine"></div>
                    <input type="number" class="form-range-control" v-model="patient.third_line">
                    <div class="form-range-button form-range-button-upper" @click="plusLine"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label"><span>Доля пациентов с POD24 <br>в анамнезе, %</span></div>
              <div class="form-content">
                <div class="form-range">
                  <div id="slider3"></div>
                  <div class="form-range-buttons">
                    <div class="form-range-button form-range-button-lower" @click="minusPod"></div>
                    <input type="number" class="form-range-control" v-model="patient.patient_with_pod24">
                    <div class="form-range-button form-range-button-upper" @click="plusPod"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label"><span>Доля пациентов c двойной <br>рефрактерностью, %</span></div>
              <div class="form-content">
                <div class="form-range">
                  <div id="slider4"></div>
                  <div class="form-range-buttons">
                    <div class="form-range-button form-range-button-lower" @click="minusRef"></div>
                    <input type="number" class="form-range-control" v-model="patient.patient_with_double_ref">
                    <div class="form-range-button form-range-button-upper" @click="plusRef"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="!disable_button">
            <button type="button" class="form-button button" @click="submit" v-if="!loading">Отправить</button>
            <button type="button" class="form-button button" v-else>Отправляем...</button>
          </template>
          <template v-else>
            <button type="button" class="form-button button button--disabled">Отправлено</button>
          </template>
          <div class="page-note">POD24 - прогрессирование заболевания в течение 24 месяцев с момента начала первой линии иммунохимиотерапии</div>
        </form>
      </div>
    </div>
    <!-- <div class="live-frame"></div> -->
  </div>
</template>

<script>
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
  export default {
    name: "PatientComponent",

    data() {
      return {
        slider1: null,
        slider2: null,
        slider3: null,
        slider4: null,
        loading: false,
        disable_button: false,
        patient: {
            "gender": "male",
            "age": 55,
            "third_line": 60,
            "patient_with_pod24": 50,
            "patient_with_double_ref": 50
        }
      }
    },

    mounted() {
      let that = this;
      this.slider1 = noUiSlider.create(document.getElementById('slider1'), {
        start: that.patient.age,
        connect: 'lower',
        range: {
          'min': 18,
          'max': 90,
        },
        pips: {
          mode: 'values',
          values: [18, 90],
        }
      })
      this.slider1.on('change', (value) => {
        this.patient.age = parseInt(value);
      })
      this.slider2 = noUiSlider.create(document.getElementById('slider2'), {
        start: that.patient.third_line,
        connect: 'lower',
        range: {
          'min': 0,
          'max': 120,
        },
        pips: {
          mode: 'values',
          values: [0, 120],
        }
      })
      this.slider2.on('change', (value) => {
        this.patient.third_line = parseInt(value);
      })
      this.slider3 = noUiSlider.create(document.getElementById('slider3'), {
        start: that.patient.patient_with_pod24,
        connect: 'lower',
        range: {
          'min': 0,
          'max': 100,
        },
        pips: {
          mode: 'values',
          values: [0, 100],
        }
      })
      this.slider3.on('change', (value) => {
        this.patient.patient_with_pod24 = parseInt(value);
      })
      this.slider4 = noUiSlider.create(document.getElementById('slider4'), {
        start: that.patient.patient_with_double_ref,
        connect: 'lower',
        range: {
          'min': 0,
          'max': 100,
        },
        pips: {
          mode: 'values',
          values: [0, 100],
        }
      })
      this.slider4.on('change', (value) => {
        this.patient.patient_with_double_ref = parseInt(value);
      })
    },
    methods: {
      submit() {
        this.loading = true;
        this.$store.dispatch('setPatient', this.patient).then(() => {
          setTimeout(() => {
            this.loading = false;
            this.disable_button = true;
            this.$toast.success('Форма успешно отправлена');
          }, 1000)
        })
      },
      plusAge() {
        if (this.patient.age < 90) {
          this.patient.age++;
          this.slider1.set(this.patient.age);
        }
      },
      minusAge() {
        if (this.patient.age > 18) {
          this.patient.age--;
          this.slider1.set(this.patient.age);
        }
      },
      plusLine() {
        if (this.patient.third_line < 120) {
          this.patient.third_line++;
          this.slider2.set(this.patient.third_line);
        }
      },
      minusLine() {
        if (this.patient.third_line > 0) {
          this.patient.third_line--;
          this.slider2.set(this.patient.third_line);
        }
      },
      plusPod() {
        if (this.patient.patient_with_pod24 < 100) {
          this.patient.patient_with_pod24++;
          this.slider3.set(this.patient.patient_with_pod24);
        }
      },
      minusPod() {
        if (this.patient.patient_with_pod24 > 0) {
          this.patient.patient_with_pod24--;
          this.slider3.set(this.patient.patient_with_pod24);
        }
      },
      plusRef() {
        if (this.patient.patient_with_double_ref < 100) {
          this.patient.patient_with_double_ref++;
          this.slider4.set(this.patient.patient_with_double_ref);
        }
      },
      minusRef() {
        if (this.patient.patient_with_double_ref > 0) {
          this.patient.patient_with_double_ref--;
          this.slider4.set(this.patient.patient_with_double_ref);
        }
      },
    }
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
@import "../sass/styles.sass"
</style>