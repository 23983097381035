<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">СВЦ является специфичным нежелательным <br>явлением для препаратов, воздействующих <br>на опухоль через активацию Т-лимфоцитов</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-45">
          <img src="@/assets/img/frame-45.png" alt="">
        </div>
        <div class="frame-note large">Информация,размещенная в настоящем материале, содержит сведения о незарегистрированных вРФ лекарственных средствах/показаниях, носит исключительно научный характер ине является рекламой.</div>
      </div>
    </div>
    <ul class="pagination">
      <li class="pagination-item"><a class="pagination-link pagination-link--prev" href="javascript:void(0)"></a></li>
      <li class="pagination-item"><a class="pagination-link pagination-link--6 active" href="javascript:void(0)">06</a></li>
      <li class="pagination-item"><a class="pagination-link pagination-link--7" href="javascript:void(0)">07</a></li>
      <li class="pagination-item"><a class="pagination-link pagination-link--8" href="javascript:void(0)">08</a></li>
      <li class="pagination-item"><a class="pagination-link pagination-link--9" href="javascript:void(0)">09</a></li>
      <li class="pagination-item"><a class="pagination-link pagination-link--10" href="javascript:void(0)">10</a></li>
      <li class="pagination-item"><a class="pagination-link pagination-link--next" href="javascript:void(0)"></a></li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "NewClassPage_6",
  }
</script>