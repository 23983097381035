<template>
  <div class="page">
    <div class="modal">
      <div class="modal-content">
        <img src="@/assets/img/relapse/modal-1-4.png" alt="">
      </div>
      <div class="modal-close" @click="$store.dispatch('setPageByUser', 3)"></div>
      <ul class="modal-pagination">
        <li class="modal-pagination-item" @click="$store.dispatch('setPageByUser', 6)"><a class="modal-pagination-link modal-pagination-link--prev" href="javascript:void(0)"></a></li>
        <li class="modal-pagination-item" @click="$store.dispatch('setPageByUser', 4)"><a class="modal-pagination-link" href="javascript:void(0)">01</a></li>
        <li class="modal-pagination-item" @click="$store.dispatch('setPageByUser', 5)"><a class="modal-pagination-link" href="javascript:void(0)">02</a></li>
        <li class="modal-pagination-item" @click="$store.dispatch('setPageByUser', 6)"><a class="modal-pagination-link" href="javascript:void(0)">03</a></li>
        <li class="modal-pagination-item" @click="$store.dispatch('setPageByUser', 7)"><a class="modal-pagination-link active" href="javascript:void(0)">04</a></li>
        <li class="modal-pagination-item" @click="$store.dispatch('setPageByUser', 7)"><a class="modal-pagination-link modal-pagination-link--next" href="javascript:void(0)"></a></li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Modal_1_4",
    methods: {
        handleKeydown(event) {
            if (event.key === 'ArrowLeft') {
              this.$store.dispatch('setPageByUser', 6);
            } else if (event.key === 'ArrowRight') {
              this.$store.dispatch('setPageByUser', 8);
            }
        }
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeydown);
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeydown);
    }
  }
</script>