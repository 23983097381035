<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
      fill="white"
      fill-opacity="0.7"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 23C12.4477 23 12 23.4477 12 24C12 24.5523 12.4477 25 13 25H17H20C20.5523 25 21 24.5523 21 24C21 23.4477 20.5523 23 20 23H18L18 15V14H17H14C13.4477 14 13 14.4477 13 15C13 15.5523 13.4477 16 14 16H16L16 23H13ZM18.5 9.5C18.5 10.6046 17.6046 11.5 16.5 11.5C15.3954 11.5 14.5 10.6046 14.5 9.5C14.5 8.39543 15.3954 7.5 16.5 7.5C17.6046 7.5 18.5 8.39543 18.5 9.5Z"
      fill="#1D191E"
    />
  </svg>
</template>
<script lang="ts" setup></script>
