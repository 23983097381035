<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Нежелательные явления, специфичные для<br> классов терапии, действующих за счет активации<br> Т-лимфоцитов</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-40">
          <img src="@/assets/img/frame-40.png" alt="">
        </div>
        <div class="frame-note large">1. Shimabukuro-Vornhagen A, et al. J Immunother Cancer 2018;6:56; 2. Garcia Borrega J, et al. Hemasphere 2019;3(2):e191.</div>
      </div>
    </div>
    <Pagination :total="17" :page="1" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_1",
    components: {
      Pagination,
    }
  }
</script>