<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">100% пациентов c ФЛ, достигшие полного ответа <br>на терапию мосунетузумабом, живы спустя 2 года</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-27">
          <img src="@/assets/img/frame-27.png" alt="">
        </div>
        <div class="frame-notes frame-notes--4">
          <div class="frame-note large">Медиана наблюдения – 28,3 месяца</div>
          <div class="frame-note large">Sehn LH, et al. EHA 2023; presentation no. 1078.</div>
        </div>
      </div>
    </div>
    <Pagination :total="15" :page="13" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapseTherapyPage_15",
    components: {
      Pagination,
    }
  }
</script>