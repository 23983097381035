<template>
  <ChapterView>
    <template v-slot:title><Chapter_3_Title /></template>
    <template v-slot:avatar><img src="@/assets/img/krilova.png" alt=""></template>
    <template v-slot:name>Крылова <br>Яна Викторовна</template>
    <template v-slot:regalia>к.м.н., НИИ ДОГиТ</template>
    <template v-slot:interactive-note>Данная презентация подготовлена при поддержке АО “Рош-Москва”. Информация предназначена только для медицинских работников. Мнение лектора может не совпадать с позицией АО “Рош-Москва”. 25 сентября 2024 года. <br/>M-RU-00015465 февраль 2024. Safety ID 211581, 231001</template>
  </ChapterView>
</template>

<script>
import ChapterView from '@/components/ChapterView'
import Chapter_3_Title from '@/components/Chapter_3_Title.vue'

export default {
  name: 'Chapter_3',
  components: {
    ChapterView,
    Chapter_3_Title,
  },
}
</script>
