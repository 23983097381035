<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Подходы к снижению риска развития СВЦ <br>при терапии мосунетузумабом</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-51">
          <img src="@/assets/img/frame-51.png" alt="">
        </div>
        <div class="frame-note large">1. Hutchings M, et al. ASCO 2020; #8009; 2. Yakoub-Agha I, et al. Haematologica 2020;105:297–316; 3. Bacac M et al. Clin Can Res 2018;24:4785–97; 4. Usmani SZ et al. ASCO 2021 #8005; <br>5. Hosseini I, et al. NPJ Syst Biol Appl 2020;6:28; 6. Bartlett NL, et al. ASCO 2019 #7518; 7. Trudel S, et al. ASH 2021 #157;</div>
      </div>
    </div>
    <Pagination :total="17" :page="10" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_12",
    components: {
      Pagination,
    }
  }
</script>