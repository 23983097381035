<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Монотерапия мосунетузумабом позволила <br>достигнуть полного ответа у 60% пациентов <br>на 3+ линии терапии ФЛ</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-20">
          <img src="@/assets/img/frame-20.png" alt="">
        </div>
        <div class="frame-note large">Budde LE, et al. (2022) The Lancet Oncology, 23(8): 1055-1065</div>
      </div>
    </div>
    <Pagination :total="15" :page="7" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapseTherapyPage_8",
    components: {
      Pagination,
    }
  }
</script>