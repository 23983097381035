import { createRouter, createWebHistory } from 'vue-router'

import MainPage from '../views/Index.vue'
import StartPage from '../views/StartPage.vue'
import WaitingPage from '../views/WaitingPage.vue'
import MoodVideo from '../views/MoodVideo.vue'
import RollCall from '../views/RollCall.vue'
import IceBreaker from '../views/IceBreaker.vue'
import IceBreakerResults from '../views/IceBreakerResults.vue'
import TagCloud from '../views/TagCloud.vue'
import Preloader from '../views/Preloader.vue'
import Graph_1 from "@/components/Graph_1.vue";
import Chapter_1 from "../views/Chapter_1.vue";
import Chapter_2 from "../views/Chapter_2.vue";
import Chapter_3 from "../views/Chapter_3.vue";
import Chapter_4 from "../views/Chapter_4.vue";
import PageRelapse_1 from "@/components/Relapse/Page_1.vue";
import PageRelapse_2 from "@/components/Relapse/Page_2.vue";
import PageRelapse_3 from "@/components/Relapse/Page_3.vue";
import PageRelapse_4 from "@/components/Relapse/Page_4.vue";
import PageRelapse_5 from "@/components/Relapse/Page_5.vue";
import PageRelapseTherapy_1 from "@/components/RelapseTherapy/Page_1.vue";
import PageRelapseTherapy_2 from "@/components/RelapseTherapy/Page_2.vue";
import PageRelapseTherapy_3 from "@/components/RelapseTherapy/Page_3.vue";
import PageRelapseTherapy_4 from "@/components/RelapseTherapy/Page_4.vue";
import PageRelapseTherapy_5 from "@/components/RelapseTherapy/Page_5.vue";
import PageRelapseTherapy_6 from "@/components/RelapseTherapy/Page_6.vue";
import PageRelapseTherapy_7 from "@/components/RelapseTherapy/Page_7.vue";
import PageRelapseTherapy_8 from "@/components/RelapseTherapy/Page_8.vue";
import PageRelapseTherapy_9 from "@/components/RelapseTherapy/Page_9.vue";
import PageRelapseTherapy_10 from "@/components/RelapseTherapy/Page_10.vue";
import PageRelapseTherapy_11 from "@/components/RelapseTherapy/Page_11.vue";
import PageRelapseTherapy_12 from "@/components/RelapseTherapy/Page_12.vue";
import PageRelapseTherapy_13 from "@/components/RelapseTherapy/Page_13.vue";
import PageRelapseTherapy_14 from "@/components/RelapseTherapy/Page_14.vue";
import PageRelapseTherapy_15 from "@/components/RelapseTherapy/Page_15.vue";
import PageRelapseTherapy_16 from "@/components/RelapseTherapy/Page_16.vue";
import PageRelapseTherapy_17 from "@/components/RelapseTherapy/Page_17.vue";
import PageRelapseTherapy_18 from "@/components/RelapseTherapy/Page_18.vue";
import PageRelapseTherapy_19 from "@/components/RelapseTherapy/Page_19.vue";
import PageMosunetuzumab_1 from "@/components/Mosunetuzumab/Page_1.vue";
import PageMosunetuzumab_2 from "@/components/Mosunetuzumab/Page_2.vue";
import PageMosunetuzumab_2_results from "@/components/Mosunetuzumab/Page_2_results.vue";
import PageMosunetuzumab_3 from "@/components/Mosunetuzumab/Page_3.vue";
import PageMosunetuzumab_4 from "@/components/Mosunetuzumab/Page_4.vue";
import PageMosunetuzumab_4_results from "@/components/Mosunetuzumab/Page_4_results.vue";
import PageMosunetuzumab_5 from "@/components/Mosunetuzumab/Page_5.vue";
import PageMosunetuzumab_6 from "@/components/Mosunetuzumab/Page_6.vue";
import PageMosunetuzumab_6_results from "@/components/Mosunetuzumab/Page_6_results.vue";
import PageMosunetuzumab_7 from "@/components/Mosunetuzumab/Page_7.vue";
import PageMosunetuzumab_8 from "@/components/Mosunetuzumab/Page_8.vue";
import PageMosunetuzumab_9 from "@/components/Mosunetuzumab/Page_9.vue";
import PageMosunetuzumab_10 from "@/components/Mosunetuzumab/Page_10.vue";
import PageMosunetuzumab_10_results from "@/components/Mosunetuzumab/Page_10_results.vue";
import PageMosunetuzumab_10_results_2 from "@/components/Mosunetuzumab/Page_10_results_2.vue";
import PageNewClass_1 from "@/components/NewClass/Page_1.vue";
import PageNewClass_2 from "@/components/NewClass/Page_2.vue";
import PageNewClass_3 from "@/components/NewClass/Page_3.vue";
import PageNewClass_4 from "@/components/NewClass/Page_4.vue";
import PageNewClass_5 from "@/components/NewClass/Page_5.vue";
import PageNewClass_6 from "@/components/NewClass/Page_6.vue";
import PageNewClass_7 from "@/components/NewClass/Page_7.vue";
import PageNewClass_8 from "@/components/NewClass/Page_8.vue";
import PageNewClass_9 from "@/components/NewClass/Page_9.vue";
import PageNewClass_10 from "@/components/NewClass/Page_10.vue";
import PageNewClass_11 from "@/components/NewClass/Page_11.vue";
import PageNewClass_12 from "@/components/NewClass/Page_12.vue";
import PageNewClass_13 from "@/components/NewClass/Page_13.vue";
import PageNewClass_14 from "@/components/NewClass/Page_14.vue";
import PageNewClass_15 from "@/components/NewClass/Page_15.vue";
import PageNewClass_16 from "@/components/NewClass/Page_15-1.vue";
import PageNewClass_17 from "@/components/NewClass/Page_15-2.vue";
import PageNewClass_18 from "@/components/NewClass/Page_15-3.vue";
import PageNewClass_19 from "@/components/NewClass/Page_16.vue";
import PageNewClass_20 from "@/components/NewClass/Page_16-1.vue";
import PageNewClass_21 from "@/components/NewClass/Page_17.vue";
import PageNewClass_22 from "@/components/NewClass/Page_18.vue";
import PageNewClass_23 from "@/components/NewClass/Page_19.vue";
import PageNewClass_24 from "@/components/NewClass/Page_19-1.vue";
import PageNewClass_25 from "@/components/NewClass/Page_20.vue";
import PageNewClass_26 from "@/components/NewClass/Page_21.vue";
import PageNewClass_27 from "@/components/NewClass/Page_22.vue";
import PageNewClass_28 from "@/components/NewClass/Page_23.vue";
import PageNewClass_29 from "@/components/NewClass/Page_24.vue";
import PageNewClass_30 from "@/components/NewClass/Page_24.vue";
import Page_1_1 from "@/components/Relapse/Page_1_1.vue";
import Page_2_1 from "@/components/Relapse/Page_2_1.vue";
import Page_3_1 from "@/components/Relapse/Page_3_1.vue";
import Page_4_1 from "@/components/Relapse/Page_4_1.vue";
import Page_5_1 from "@/components/Relapse/Page_5_1.vue";
import Page_6_1 from "@/components/Relapse/Page_6_1.vue";
import DViewer from '@/views/DViewer.vue'
import FinalScreen from '@/components/FinalScreen/FinalScreen.vue'

const routes = [
  {
    path: '/',
    component: MainPage,
    title: 'Определение характеристик кандидата на 3+ линию ФЛ',
  },
  {
    path: '/start',
    component: StartPage,
  },
  {
    path: '/waiting',
    component: WaitingPage,
  },
  {
    path: '/video',
    component: MoodVideo,
  },
  {
    path: '/names',
    component: RollCall,
  },
  {
    path: '/ice-breaker',
    component: IceBreaker,
  },
  {
    path: '/ice-breaker/results',
    component: IceBreakerResults,
  },
  {
    path: '/tag-cloud',
    component: TagCloud,
  },
  {
    path: '/preloader',
    component: Preloader,
  },
  {
    path: '/graph-1',
    component: Graph_1,
  },
  {
    path: '/chapter/1',
    component: Chapter_1,
  },
  {
    path: '/chapter/2',
    component: Chapter_2,
  },
  {
    path: '/chapter/3',
    component: Chapter_3,
  },
  {
    path: '/chapter/4',
    component: Chapter_4,
  },
  {
    path: '/relapse/1',
    component: PageRelapse_1,
  },
  {
    path: '/relapse/2',
    component: PageRelapse_2,
  },
  {
    path: '/relapse/3',
    component: PageRelapse_3,
  },
  {
    path: '/relapse/4',
    component: PageRelapse_4,
  },
  {
    path: '/relapse/5',
    component: PageRelapse_5,
  },
  {
    path: '/relapse/1/1',
    component: Page_1_1,
  },
  {
    path: '/relapse/2/1',
    component: Page_2_1,
  },
  {
    path: '/relapse/3/1',
    component: Page_3_1,
  },
  {
    path: '/relapse/4/1',
    component: Page_4_1,
  },
  {
    path: '/relapse/5/1',
    component: Page_5_1,
  },
  {
    path: '/relapse/6/1',
    component: Page_6_1,
  },
  {
    path: '/relapse-therapy/1',
    component: PageRelapseTherapy_1,
  },
  {
    path: '/relapse-therapy/2',
    component: PageRelapseTherapy_2,
  },
  {
    path: '/relapse-therapy/3',
    component: PageRelapseTherapy_3,
  },
  {
    path: '/relapse-therapy/4',
    component: PageRelapseTherapy_4,
  },
  {
    path: '/relapse-therapy/5',
    component: PageRelapseTherapy_5,
  },
  {
    path: '/relapse-therapy/6',
    component: PageRelapseTherapy_6,
  },
  {
    path: '/relapse-therapy/7',
    component: PageRelapseTherapy_7,
  },
  {
    path: '/relapse-therapy/8',
    component: PageRelapseTherapy_8,
  },
  {
    path: '/relapse-therapy/9',
    component: PageRelapseTherapy_9,
  },
  {
    path: '/relapse-therapy/10',
    component: PageRelapseTherapy_10,
  },
  {
    path: '/relapse-therapy/11',
    component: PageRelapseTherapy_11,
  },
  {
    path: '/relapse-therapy/12',
    component: PageRelapseTherapy_12,
  },
  {
    path: '/relapse-therapy/13',
    component: PageRelapseTherapy_13,
  },
  {
    path: '/relapse-therapy/14',
    component: PageRelapseTherapy_14,
  },
  {
    path: '/relapse-therapy/15',
    component: PageRelapseTherapy_15,
  },
  {
    path: '/relapse-therapy/16',
    component: PageRelapseTherapy_16,
  },
  {
    path: '/relapse-therapy/17',
    component: PageRelapseTherapy_17,
  },
  {
    path: '/relapse-therapy/18',
    component: PageRelapseTherapy_18,
  },
  {
    path: '/relapse-therapy/19',
    component: PageRelapseTherapy_19,
  },
  {
    path: '/mosunetuzumab/1',
    component: PageMosunetuzumab_1,
  },
  {
    path: '/mosunetuzumab/2',
    component: PageMosunetuzumab_2,
  },
  {
    path: '/mosunetuzumab/2-results',
    component: PageMosunetuzumab_2_results,
  },
  {
    path: '/mosunetuzumab/3',
    component: PageMosunetuzumab_3,
  },
  {
    path: '/mosunetuzumab/4',
    component: PageMosunetuzumab_4,
  },
  {
    path: '/mosunetuzumab/4-results',
    component: PageMosunetuzumab_4_results,
  },
  {
    path: '/mosunetuzumab/5',
    component: PageMosunetuzumab_5,
  },
  {
    path: '/mosunetuzumab/6',
    component: PageMosunetuzumab_6,
  },
  {
    path: '/mosunetuzumab/6-results',
    component: PageMosunetuzumab_6_results,
  },
  {
    path: '/mosunetuzumab/7',
    component: PageMosunetuzumab_7,
  },
  {
    path: '/mosunetuzumab/8',
    component: PageMosunetuzumab_8,
  },
  {
    path: '/mosunetuzumab/9',
    component: PageMosunetuzumab_9,
  },
  {
    path: '/mosunetuzumab/10',
    component: PageMosunetuzumab_10,
  },
  {
    path: '/mosunetuzumab/10-results',
    component: PageMosunetuzumab_10_results,
  },
  {
    path: '/mosunetuzumab/10-results-2',
    component: PageMosunetuzumab_10_results_2,
  },
  {
    path: '/new-class/1',
    component: PageNewClass_1,
  },
  {
    path: '/new-class/2',
    component: PageNewClass_2,
  },
  {
    path: '/new-class/3',
    component: PageNewClass_3,
  },
  {
    path: '/new-class/4',
    component: PageNewClass_4,
  },
  {
    path: '/new-class/5',
    component: PageNewClass_5,
  },
  {
    path: '/new-class/6',
    component: PageNewClass_6,
  },
  {
    path: '/new-class/7',
    component: PageNewClass_7,
  },
  {
    path: '/new-class/8',
    component: PageNewClass_8,
  },
  {
    path: '/new-class/9',
    component: PageNewClass_9,
  },
  {
    path: '/new-class/10',
    component: PageNewClass_10,
  },
  {
    path: '/new-class/11',
    component: PageNewClass_11,
  },
  {
    path: '/new-class/12',
    component: PageNewClass_12,
  },
  {
    path: '/new-class/13',
    component: PageNewClass_13,
  },
  {
    path: '/new-class/14',
    component: PageNewClass_14,
  },
  {
    path: '/new-class/15',
    component: PageNewClass_15,
  },
  {
    path: '/new-class/16',
    component: PageNewClass_16,
  },
  {
    path: '/new-class/17',
    component: PageNewClass_17,
  },
  {
    path: '/new-class/18',
    component: PageNewClass_18,
  },
  {
    path: '/new-class/19',
    component: PageNewClass_19,
  },
  {
    path: '/new-class/20',
    component: PageNewClass_20,
  },
  {
    path: '/new-class/21',
    component: PageNewClass_21,
  },
  {
    path: '/new-class/22',
    component: PageNewClass_22,
  },
  {
    path: '/new-class/23',
    component: PageNewClass_23,
  },
  {
    path: '/new-class/24',
    component: PageNewClass_24,
  },
  {
    path: '/new-class/25',
    component: PageNewClass_25,
  },
  {
    path: '/new-class/26',
    component: PageNewClass_26,
  },
  {
    path: '/new-class/27',
    component: PageNewClass_27,
  },
  {
    path: '/new-class/28',
    component: PageNewClass_28,
  },
  {
    path: '/new-class/29',
    component: PageNewClass_29,
  },
  {
    path: '/new-class/30',
    component: PageNewClass_30,
  },
  {
    path: '/3d',
    component: DViewer,
  },
  {
    path: '/final',
    component: FinalScreen,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
