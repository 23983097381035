<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Регистрация препаратов для лечения <br>рецидивов ФЛ в России</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-14">
          <img src="@/assets/img/frame-14.png" alt="">
        </div>
        <div class="frame-note large">1.https://grls.rosminzdrav.ru/Default.aspx (дата доступа 16.01.2024); 2. Инструкция по медицинскому применению препарата ритуксимаб П N013127/01; 3.ОХЛП обинутузумаб, ЛП-№(001150)-(РГ-RU); <br>4. Инструкция по медицинскому применению препарата Леналидомид, ЛП-003739;5. Инструкция по медицинскому применению препарата Дувелисиб, ЛП-006862; 6. ОХЛП мосунетузумаб, ЛП-<br>N=(003673)-(РГ-RU)</div>
      </div>
    </div>
    <Pagination :total="15" :page="2" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapseTherapyPage_2",
    components: {
      Pagination,
    }
  }
</script>