<template>
  <div class="interactive roll-call">
    <div class="interactive-title interactive-title-3 white roll-call__title">
      Приветствуем <br />
      участников конференции
    </div>
    <div
      class="scroll-parent cards-content"
      v-for="(r, idx) in userRows"
      :key="idx"
      :style="{ height: `${heightArr[idx]}px` }"
    >
      <div
        class="scroll-element cards-row"
        :class="[
          `cards-row-${(idx % 2) + 1}`,
          startAnimation
            ? idx % 2
              ? 'scroll-element--left'
              : 'scroll-element--right'
            : '',
        ]"
        ref="row"
      >
        <roll-call-user-row :users="r" />
      </div>
      <div
        class="scroll-element cards-row"
        :class="[
          `cards-row-${(idx % 2) + 1}`,
          startAnimation
            ? idx % 2
              ? 'scroll-element--left-second'
              : 'scroll-element--right-second'
            : '',
        ]"
      >
        <roll-call-user-row :users="r" />
      </div>
    </div>

    <div class="interactive-element interactive-element-1"></div>
    <div class="interactive-element interactive-element-3"></div>
    <div class="interactive-element interactive-element-4"></div>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { useStore } from "vuex";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { AcknowledgeUser } from "@/types/acknowledge-user";
import RollCallUserRow from "@/components/RollCallUserRow.vue";
const store = useStore();
const startAnimation = ref(false);

const users = ref<AcknowledgeUser[]>([]);

watch(
  () => store.getters.acknowledgedUsers,
  (val: string[]) => {
    users.value = users.value.map((el) => {
      return {
        ...el,
        acknowledged_at: val.some((user) => user === el.fullname),
      };
    });
  }
);

const userRows = computed<AcknowledgeUser[][]>(() => {
  const amount = Math.floor(users.value.length / 3);

  const first = users.value.slice(0, amount);
  const second = users.value.slice(amount, amount * 2);
  const third = users.value.slice(amount * 2);

  return [first, second, third];
});

const row = ref<HTMLElement[]>([]);
const heightArr = ref<number[]>([]);
const updateHeight = () => {
  if (!row.value) {
    return;
  }

  heightArr.value = [];

  row.value.forEach((el) => {
    heightArr.value.push(el.clientHeight);
  });
};
axios
  .get(`${process.env.VUE_APP_BASE_API_URL}/api/invites`, {
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
  })
  .then((res) => {
    let filteredUsers = res.data.filter((u) => u.role == 'participant');
    users.value = filteredUsers;
    nextTick(() => {
      updateHeight();
      startAnimation.value = true;
    });
  });

onMounted(async () => {
  updateHeight();
  window.addEventListener("resize", updateHeight);
});
</script>

<style scoped lang="sass">
@import "@/sass/functions"
.roll-call__title
  margin-left: vw(64px)
  margin-top: vw(56px)
  margin-bottom: vw(29px)
  text-transform: uppercase
  font-size: vw(56px)
  font-weight: 700
  line-height: vw(56px)
  letter-spacing: 0.02em
  text-align: left

.scroll-parent
  position: relative
  width: 100vw
  overflow-x: hidden
  margin: 0
  min-height: vw(200px)

@keyframes first-left
  from
    transform: translateX(0)
  to
    transform: translateX(-100%)

@keyframes second-left
  from
    transform: translateX(100%)
  to
    transform: translateX(0)

@keyframes first-right
  from
    transform: translateX(0%)
  to
    transform: translateX(100%)

@keyframes second-right
  from
    transform:  translateX(-100%)
  to
    transform:  translateX(0%)


.scroll-element
  position: absolute
  top: 0%

  &--left
    left: 0%

    animation: first-left 100s linear infinite

  &--left-second
    left: 0%


    animation: second-left 100s linear infinite

  &--right
    right: 0%

    animation: first-right 100s linear infinite

  &--right-second
    right: 0%

    animation: second-right 100s linear infinite

</style>
