<script setup lang="ts">
import CollapceIcon from "@/components/video-conference/icons/CollapceIcon.vue";
import PersonIcon from "@/components/video-conference/icons/PersonIcon.vue";
import TIleIcon from "@/components/video-conference/icons/TIleIcon.vue";
import { ref } from "vue";
import ExpandIcon from "@/components/video-conference/icons/ExpandIcon.vue";

const isOpen = ref(false);
const props = defineProps<{ isMini: boolean; isTiles: boolean }>();
const emit = defineEmits<{
  (e: "setMini"): void;
  (e: "setTiles"): void;
}>();

const buttons = [
  {
    icon: PersonIcon,
    text: "Спикер",
    onClick: () => {
      if (props.isTiles) {
        emit("setTiles");
      }

      isOpen.value = false;
    },
  },
  {
    icon: TIleIcon,
    text: "Плитка",
    onClick: () => {
      if (!props.isTiles) {
        emit("setTiles");
      }

      isOpen.value = false;
    },
  },
  {
    icon: CollapceIcon,
    text: "Свернуть",
    onClick: () => {
      emit("setMini");
      isOpen.value = false;
    },
  },
];

const onTriggerClick = () => {
  if (props.isMini) {
    emit("setMini");
    return;
  }

  isOpen.value = !isOpen.value;
};
</script>

<template>
  <div class="video-view-controls">
    <button
      class="video-button video-view-controls__trigger"
      :class="{ 'video-view-controls__trigger--mini': isMini }"
      @click="onTriggerClick"
    >
      <span class="video-button__msg" v-if="!isMini"> Изменить вид </span>
      <expand-icon
        v-if="isMini"
        class="video-button__icon video-view-controls__trigger-icon"
      >
      </expand-icon>
      <person-icon
        v-else
        class="video-button__icon video-view-controls__trigger-icon"
      />
    </button>
    <transition name="slide">
      <div class="video-view-controls__container" v-if="isOpen">
        <button
          class="video-button video-view-controls__button"
          :class="{
            'video-view-controls__button--active':
              (idx === 0 && !isTiles) || (idx === 1 && isTiles),
          }"
          :disabled="(idx === 0 && !isTiles) || (idx === 1 && isTiles)"
          v-for="(b, idx) in buttons"
          :key="idx"
          @click="b.onClick"
        >
          <component :is="b.icon" class="video-view-controls__icon"></component>

          <span class="video-button__msg">
            {{ b.text }}
          </span>
        </button>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="sass">
@import "@/sass/video-button"
.slide-enter-active
  transition: all 0.3s ease-out

.slide-leave-active
  transition: all 0.3s ease-in

.slide-enter-from, .slide-leave-to
  transform: translateY(-200px)
  opacity: 0

.video-view-controls
  display: flex
  flex-direction: column
  align-items: flex-end

  &__trigger
    padding: 8px 8px 8px 16px
    border-radius: 100px
    flex-direction: row
    &-icon
      margin-bottom: 0
      margin-left: 8px
    &--mini
      padding: 8px
      border-radius: 8px

      .video-view-controls__trigger-icon
        width: 24px
        height: 24px
        margin-left: 0

  &__container
    display: flex
    flex-direction: row
    margin-top: 8px
    padding: 8px
    background: rgba(29, 25, 30, 0.60)
    border-radius: 8px

  &__button
    flex-direction: row
    padding: 8px 16px
    background: rgba(255, 255, 255, 0)

    &--active
      background: #8B15C2

  &__icon
    width: 32px
    height: 32px
    margin-right: 8px
</style>
