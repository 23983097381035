<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Длительность полного ответа на мосунетузумаб <br>не зависит от скорости его достижения</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-24">
          <img src="@/assets/img/frame-24.png" alt="">
        </div>
        <div class="frame-note large">Sehn LH et al. EHA 2023. Poster 1078 <br>ПО - полный ответ; ЧО - частичный ответ; НД - не достигнуто</div>
      </div>
    </div>
    <Pagination :total="15" :page="11" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapseTherapyPage_12",
    components: {
      Pagination,
    }
  }
</script>