<template>
  <div id="app" class="wrapper">
    <div class="wrapper__content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    document.body.style.zoom = '100%';
    document.body.style.zoom = screen.logicalXDPI / screen.deviceXDPI;
    document.addEventListener('wheel', function(e) {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, { passive: false });

    document.addEventListener('gesturestart', function(e) {
      e.preventDefault();
    });
    document.addEventListener('gesturechange', function(e) {
      e.preventDefault();
    });
    document.addEventListener('gestureend', function(e) {
      e.preventDefault();
    });
    document.addEventListener('keydown', function(e) {
      if ((e.ctrlKey || e.metaKey) && (e.key === '+' || e.key === '-' || e.key === '=')) {
        e.preventDefault();
      }
    });
  }
}
</script>

<style lang="scss">
  @import "./sass/functions.sass";
  article,aside,details,figcaption,figure,footer,header,hgroup,hr,menu,nav,section{display:block}a,hr{padding:0}abbr,address,article,aside,audio,b,blockquote,body,canvas,caption,cite,code,dd,del,details,dfn,div,dl,dt,em,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,p,pre,q,samp,section,span,strong,sub,summary,table,tbody,td,tfoot,th,thead,time,tr,ul,var,video{margin:0;padding:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:0 0}ins,mark{background-color:#ff9;color:#000}body{line-height:1}nav ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}a{margin:0;font-size:100%;vertical-align:baseline;background:0 0}ins{text-decoration:none}mark{font-style:italic;font-weight:700}del{text-decoration:line-through}abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}table{border-collapse:collapse;border-spacing:0}hr{height:1px;border:0;border-top:1px solid #ccc;margin:1em 0}input,select{vertical-align:middle}
  html {
    touch-action: manipulation;
  }

  body {
    zoom: 100%;
    -ms-content-zooming: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  html, body {
    overscroll-behavior-y: none;
  }
  * {
    box-sizing: border-box;
  }

  body {
    overflow-x: hidden;
    font-family: 'Roboto Condensed';
    height: 100vh; 
    width: 100vw;
    --mini-camera-height: 244px
  }
  
  @font-face {
    font-family: 'DIN Condensed';
    src: url('./assets/fonts/DINCondensed-Regular.eot');
    src: local('DIN Condensed'), local('DINCondensed-Regular'),
      url('./assets/fonts/DINCondensed-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/DINCondensed-Regular.woff2') format('woff2'),
      url('./assets/fonts/DINCondensed-Regular.woff') format('woff'),
      url('./assets/fonts/DINCondensed-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto Condensed';
    src: url('./assets/fonts/RobotoCondensed-Bold.eot');
    src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
      url('./assets/fonts/RobotoCondensed-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/RobotoCondensed-Bold.woff2') format('woff2'),
      url('./assets/fonts/RobotoCondensed-Bold.woff') format('woff'),
      url('./assets/fonts/RobotoCondensed-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto Condensed';
    src: url('./assets/fonts/RobotoCondensed-Regular.eot');
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
      url('./assets/fonts/RobotoCondensed-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/RobotoCondensed-Regular.woff2') format('woff2'),
      url('./assets/fonts/RobotoCondensed-Regular.woff') format('woff'),
      url('./assets/fonts/RobotoCondensed-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto Condensed';
    src: url('./assets/fonts/RobotoCondensed-Light.eot');
    src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'),
      url('./assets/fonts/RobotoCondensed-Light.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/RobotoCondensed-Light.woff2') format('woff2'),
      url('./assets/fonts/RobotoCondensed-Light.woff') format('woff'),
      url('./assets/fonts/RobotoCondensed-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Saar SP Demo';
    src: url('./assets/fonts/SaarSPDemoRegular.woff2') format('woff2'),
      url('./assets/fonts/SaarSPDemoRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pilowlava';
    src: url('./assets/fonts/Pilowlava-Regular.woff2') format('woff2'),
      url('./assets/fonts/Pilowlava-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-Regular.woff2') format('woff2'),
      url('./assets/fonts/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
</style>
