<script setup lang="ts"></script>

<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.5 24V13.75C25.5 13.4185 25.3683 13.1005 25.1339 12.8661C24.8995 12.6317 24.5816 12.5 24.25 12.5C23.9185 12.5 23.6006 12.6317 23.3662 12.8661C23.1317 13.1005 23 13.4185 23 13.75V19.5C23 19.78 22.78 20 22.5 20C22.22 20 22 19.78 22 19.5V10.75C22 10.4185 21.8683 10.1005 21.6339 9.86612C21.3995 9.6317 21.0816 9.5 20.75 9.5C20.4185 9.5 20.1006 9.6317 19.8662 9.86612C19.6317 10.1005 19.5 10.4185 19.5 10.75V19.5C19.5 19.78 19.28 20 19 20C18.72 20 18.5 19.78 18.5 19.5V9.25C18.5 8.91848 18.3683 8.60054 18.1339 8.36612C17.8995 8.1317 17.5816 8 17.25 8C16.9185 8 16.6006 8.1317 16.3662 8.36612C16.1317 8.60054 16 8.91848 16 9.25V19.5C16 19.78 15.78 20 15.5 20C15.22 20 15 19.78 15 19.5V11.25C15 10.9185 14.8683 10.6005 14.6339 10.3661C14.3995 10.1317 14.0816 10 13.75 10C13.4185 10 13.1006 10.1317 12.8662 10.3661C12.6317 10.6005 12.5 10.9185 12.5 11.25V23H12.08C11.88 23 11.7 22.88 11.62 22.68L10.34 19.47C10.12 18.92 9.66004 18.49 9.10004 18.31L8.31004 18.05C8.12941 17.9909 7.93568 17.9846 7.75156 18.0316C7.56744 18.0787 7.40054 18.1773 7.27042 18.3158C7.1403 18.4543 7.05234 18.627 7.01686 18.8138C6.98137 19.0005 6.99982 19.1934 7.07004 19.37L8.91353 24H25.5Z"
      fill="white"
    />
  </svg>
</template>

<style scoped lang="sass"></style>
