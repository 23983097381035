<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title" v-html="data.title"></h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-18">
          <div class="quiz-grade">
            <div class="content-frame-field" v-for="item in data.options" :key="item.ident">
              <div class="radio radio--square">
                <label class="radio-container">
                  <input type="radio" name="question" :value="item.ident" v-model="answer">
                  <span class="radio-checkmark"></span>
                  <p>{{item.title}}</p>
                </label>
              </div>
            </div>
          </div>
          <div class="radio-row">
            <p>Совсем не важно</p>
            <p>Крайне важно</p>
          </div>
          <div class="content-frame-field">
            <button class="content-frame-button content-frame-button--2" @click="submit" v-if="!submited">Подтвердить</button>
            <button class="content-frame-button button--disabled content-frame-button--2" disabled v-else>Отправлено</button>
          </div>
        </div>
      </div>
    </div>
    <Pagination :total="15" :page="9" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapseTherapyPage_10",
    data() {
      return {
        answer: null,
        submited: false,
      }
    },
    components: {
      Pagination,
    },
    props: {
      data: {
        type: Object,
      }
    },
    methods: {
      async submit() {
        console.log('test');
        let answers = [];
        answers.push({'ident': this.answer})
        let payload = {
          answers: answers,
          ident: '11_presentation_therapy_relevant_poll2'
        }
        await this.$store.dispatch('sendPoll', payload)
        this.submited = true;
      }
    }
  }
</script>

<style scoped lang="sass">
@import "@/sass/functions"

.content
  padding: vw(56px)

.content-frame-row 
  flex-wrap: wrap
  width: calc(100% + 3vw)

</style>