<script setup lang="ts">

</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.75 4.5C12.75 4.08579 13.0858 3.75 13.5 3.75H19.5H20.25V4.5V10.5C20.25 10.9142 19.9142 11.25 19.5 11.25C19.0858 11.25 18.75 10.9142 18.75 10.5V6.31066L6.31066 18.75H10.5C10.9142 18.75 11.25 19.0858 11.25 19.5C11.25 19.9142 10.9142 20.25 10.5 20.25H4.5H3.75V19.5V13.5C3.75 13.0858 4.08579 12.75 4.5 12.75C4.91421 12.75 5.25 13.0858 5.25 13.5V17.6893L17.6893 5.25H13.5C13.0858 5.25 12.75 4.91421 12.75 4.5Z" fill="white"/>
  </svg>

</template>

<style scoped lang="sass">

</style>
