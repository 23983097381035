<template>
  <div>
    <Preloader v-if="!isLoadedAssets" @loaded="loadedImages" />
    <template v-else> 
      <start-page v-if="!isLoggedIn" @logged-in="isLoggedIn = true"> </start-page>
      <template v-else-if="!isDisconnected">
        <transition name="slide-fade">
          <Viewer3D v-if="active === '10_3d_viewer' "/>
        </transition>
        <transition name="slide-fade">
        <waiting-page v-if="active === '2_conference_started'" />
        </transition>
        <transition name="slide-fade">
        <mood-video v-if="active === '3_mood_video'" />
        </transition>
        <transition name="slide-fade">
        <ice-breaker v-if="active === '5_icebreaker_start'" :data="data" />
        </transition>
        <transition name="slide-fade">
        <ice-breaker-result v-if="active === '5_icebreaker_result'" :data="data" />
        </transition>
        <transition name="slide-fade">
        <presentation-1 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 0" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation1_1 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 1" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation1_2 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 2" :data="data" />
        </transition>
        <!-- <transition name="slide-fade">
        <Presentation1_3 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 3" :data="data" />
        </transition> -->
        <!-- <transition name="slide-fade">
        <Presentation1_4 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 4" :data="data" />
        </transition> -->
        <transition name="slide-fade">
        <Presentation1_5 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 3" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_1 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 4" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_2 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 5" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_3 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 6" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_4 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 7" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_5 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 8" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_6 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 9" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_7 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 10" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_8 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 11" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_9 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 12" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_10 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 13" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_11 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 14" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_12 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 15" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_13 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 16" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_14 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 17" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_15 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 18" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Modal_16 v-if="active === '6_presentation_therapy_problem_intro' && data && data.idx == 19" :data="data" />
        </transition>
        <transition name="slide-fade">
        <presentation-2 v-if="active === '8_presentation_therapy_problem' && data && data.idx == 0" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation2_1 v-if="active === '8_presentation_therapy_problem' && data && data.idx == 1" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation2_2 v-if="active === '8_presentation_therapy_problem' && data && data.idx == 2" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation2_3 v-if="active === '8_presentation_therapy_problem' && data && data.idx == 3" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation2_4 v-if="active === '8_presentation_therapy_problem' && data && data.idx == 4" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation2_5 v-if="active === '8_presentation_therapy_problem' && data && data.idx == 5" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation2_6 v-if="active === '8_presentation_therapy_problem' && data && data.idx == 6" :data="data" />
        </transition>
        <template v-if="isPresentation11 && active.includes('result') && (data.idx == 5 || data.idx == 9 || data.idx == 15)">
          <Transition name="slide-fade">
          <Presentation3_result_1 v-if="isPresentation11 && data && data.idx == 5 && active=== '11_presentation_therapy_relevant_result1'" :data="data" />
          </Transition>
          <Transition name="slide-fade">
          <Presentation3_result_2 v-if="isPresentation11 && data && data.idx == 9 && active=== '11_presentation_therapy_relevant_result2'" :data="data" />
          </Transition>
          <Transition name="slide-fade">
          <Presentation3_result_3 v-if="isPresentation11 && data && data.idx == 15 && active=== '11_presentation_therapy_relevant_result3'" :data="data" />
          </Transition>
        </template>
        <template v-else>
        <transition name="slide-fade">
        <presentation-3 v-if="isPresentation11 && data && data.idx == 0" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation3_1 v-if="isPresentation11 && data && data.idx == 1" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation3_2 v-if="isPresentation11 && data && data.idx == 2" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation3_3 v-if="isPresentation11 && data && data.idx == 3" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation3_4 v-if="isPresentation11 && data && data.idx == 4" :data="data" />
        </transition>
        <!-- <transition name="slide-fade">
        <Presentation3_5 v-if="isPresentation11 && data && data.idx == 5" :data="data" />
        </transition> -->
        <transition name="slide-fade">
        <Presentation3_6 v-if="isPresentation11 && data && data.idx == 5" :data="data" />
        </transition>
        <!-- <transition name="slide-fade">
        <Presentation3_6_1 v-if="isPresentation11 && data && data.idx == 6" :data="data" />
        </transition> -->
        <transition name="slide-fade">
        <Presentation3_7 v-if="isPresentation11 && data && data.idx == 6" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation3_8 v-if="isPresentation11 && data && data.idx == 7" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation3_9 v-if="isPresentation11 && data && data.idx == 8" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation3_10 v-if="isPresentation11 && data && data.idx == 9" :data="data" />
        </transition>
        <!-- <transition name="slide-fade">
        <Presentation3_10_1 v-if="isPresentation11 && data && data.idx == 10" :data="data" />
        </transition> -->
        <transition name="slide-fade">
        <Presentation3_11 v-if="isPresentation11 && data && data.idx == 10" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation3_12 v-if="isPresentation11 && data && data.idx == 11" :data="data" />
        </transition>
        <!-- <transition name="slide-fade">
        <Presentation3_13 v-if="isPresentation11 && data && data.idx == 12" :data="data" />
        </transition> -->
        <transition name="slide-fade">
        <Presentation3_14 v-if="isPresentation11 && data && data.idx == 12" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation3_15 v-if="isPresentation11 && data && data.idx == 13" :data="data" />
        </transition>
        <!-- <transition name="slide-fade">
        <Presentation3_16 v-if="isPresentation11 && data && data.idx == 14" :data="data" />
        </transition> -->
        <transition name="slide-fade">
        <Presentation3_17 v-if="isPresentation11 && data && data.idx == 14" :data="data" />
        </transition>
        <!-- <transition name="slide-fade">
        <Presentation3_18 v-if="isPresentation11 && data && data.idx == 18" :data="data" />
        </transition> -->
        <transition name="slide-fade">
        <Presentation3_19 v-if="isPresentation11 && data && data.idx == 15" :data="data" />
        </transition>
        </template>

        <!-- PRESENTATION 12 -->
        <template v-if="isPresentation12 && active.includes('result') && (data.idx == 2 || data.idx == 4 || data.idx == 6)">
          <transition name="slide-fade">
          <Presentation4_2 v-if="active === '12_presentation_clinical_case_result1' && data.idx == 2" :data="data" />
          </transition>
          <transition name="slide-fade">
          <Presentation4_4 v-if="active === '12_presentation_clinical_case_result2' && data.idx == 4" :data="data" />
          </transition>
          <transition name="slide-fade">
          <Presentation4_6 v-if="active === '12_presentation_clinical_case_result3' && data.idx == 6" :data="data" />
          </transition>
        </template>
        <template v-else>

        <!-- <transition name="slide-fade">
        <Presentation3_19_1 v-if="isPresentation11 && data && data.idx == 20" :data="data" />
        </transition> -->
        <transition name="slide-fade">
        <presentation-4 v-if="isPresentation12 && data && data.idx == 0" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation4_1 v-if="isPresentation12 && data && data.idx == 1" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation4_2 v-if="isPresentation12 && data && data.idx == 2" :data="data" />
        </transition>
        <!-- <transition name="slide-fade">
        <Presentation4_2_1 v-if="isPresentation12 && data && data.idx == 3" :data="data" />
        </transition> -->
        <transition name="slide-fade">
        <Presentation4_3 v-if="isPresentation12 && data && data.idx == 3" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation4_4 v-if="isPresentation12 && data && data.idx == 4" :data="data" />
        </transition>
        <!-- <transition name="slide-fade">
        <Presentation4_4_1 v-if="isPresentation12 && data && data.idx == 5" :data="data" />
        </transition> -->
        <transition name="slide-fade">
        <Presentation4_5 v-if="isPresentation12 && data && data.idx == 5" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation4_6 v-if="isPresentation12 && data && data.idx == 6" :data="data" />
        </transition>
        <!-- <transition name="slide-fade">
        <Presentation4_6_1 v-if="isPresentation12 && data && data.idx == 7" :data="data" />
        </transition> -->
        <transition name="slide-fade">
        <Presentation4_7 v-if="isPresentation12 && data && data.idx == 7" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation4_8 v-if="isPresentation12 && data && data.idx == 8" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation4_9 v-if="isPresentation12 && data && data.idx == 9" :data="data" />
        </transition>
        </template>

        <!-- PRESENTATION 13 -->
        <template v-if="isPresentation13 && active.includes('result') && (data.idx == 15)">
          <transition name="slide-fade">
          <Presentation5_16 v-if="active === '13_presentation_specific_nya_result1' && data.idx == 15" :data="data" />
          </transition>
          <transition name="slide-fade">
          <Presentation5_19 v-if="active === '13_presentation_specific_nya_result2' && data.idx == 18" :data="data" />
          </transition>
        </template>
        <template v-else>

        <transition name="slide-fade">
        <presentation-5 v-if="isPresentation13 && data && data.idx == 0" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_1 v-if="isPresentation13 && data && data.idx == 1" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_2 v-if="isPresentation13 && data && data.idx == 2" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_3 v-if="isPresentation13 && data && data.idx == 3" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_4 v-if="isPresentation13 && data && data.idx == 4" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_5 v-if="isPresentation13 && data && data.idx == 5" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_7 v-if="isPresentation13 && data && data.idx == 6" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_9 v-if="isPresentation13 && data && data.idx == 7" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_10 v-if="isPresentation13 && data && data.idx == 8" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_11 v-if="isPresentation13 && data && data.idx == 9" :data="data" />
        </transition>
        <!-- <transition name="slide-fade">
        <Presentation5_12 v-if="isPresentation13 && data && data.idx == 10" :data="data" />
        </transition> -->
        <transition name="slide-fade">
        <Presentation5_13 v-if="isPresentation13 && data && data.idx == 10" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_14 v-if="isPresentation13 && data && data.idx == 11" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_15 v-if="isPresentation13 && data && data.idx == 12" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_15_1 v-if="isPresentation13 && data && data.idx == 13" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_15_2 v-if="isPresentation13 && data && data.idx == 14" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_15_3 v-if="isPresentation13 && data && data.idx == 15" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_16 v-if="isPresentation13 && data && data.idx == 16" :data="data" />
        </transition>
        <!-- <transition name="slide-fade">
        <Presentation5_16_1 v-if="isPresentation13 && data && data.idx == 16" :data="data" />
        </transition> -->
        <transition name="slide-fade">
        <Presentation5_17 v-if="isPresentation13 && data && data.idx == 17" :data="data" />
        </transition>
        <!-- <transition name="slide-fade">
        <Presentation5_18 v-if="isPresentation13 && data && data.idx == 18" :data="data" />
        </transition> -->

        <!-- isPresentation14 -->

        <transition name="slide-fade">
        <presentation-6 v-if="isPresentation14 && data && data.idx == 0" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_19 v-if="isPresentation14 && data && data.idx == 1" :data="data" />
        </transition>
        <!-- <transition name="slide-fade"> -->
        <!-- <Presentation5_19_1 v-if="isPresentation13 && data && data.idx == 20" :data="data" />
        </transition> -->
        <transition name="slide-fade">
        <Presentation5_20 v-if="isPresentation14 && data && data.idx == 2" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_21 v-if="isPresentation14 && data && data.idx == 3" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_22 v-if="isPresentation14 && data && data.idx == 4" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_23 v-if="isPresentation14 && data && data.idx == 5" :data="data" />
        </transition>
        <transition name="slide-fade">
        <Presentation5_24 v-if="isPresentation14 && data && data.idx == 6" :data="data" />
        </transition>
        </template>

        <transition name="slide-fade">
        <roll-call v-if="active === '4_rollcall'" />
        </transition>
        <transition name="slide-fade">
        <tag-cloud v-if="active === '15_tags'" />
        </transition>
        <transition name="slide-fade">
        <PatientComponent v-if="active === '9_patient_start'" />
        </transition>
        <transition name="slide-fade">
        <PatientResult v-if="active === '9_patient_result'" :data="data" />
        </transition>
        <transition name="slide-fade">
        <FinalScreen
          v-if="active === '14_info_scrolls'"
        />
        </transition>
        <transition name="slide-fade">
        <Graph_1
          v-if="active === '7_graph_start' || active === '7_graph_result'"
          :current-state="active"
          @state="active = 'tags'"
        />
        </transition>
        <transition name="slide-fade">
        <video-section
          v-if="isLoggedIn"
          @room-disconnected="isDisconnected = true"
        />
        </transition>
      </template>
      <logout-page v-else />
    </template>
  </div>
</template>

<script>
import PatientComponent from "@/components/PatientComponent.vue";
import PatientResult from "@/components/PatientResult.vue";
import Graph_1 from "@/components/Graph_1.vue";
import { mapGetters } from "vuex";
import StartPage from "@/views/StartPage.vue";
import WaitingPage from "@/views/WaitingPage.vue";
import MoodVideo from "@/views/MoodVideo.vue";
import IceBreaker from "@/views/IceBreaker.vue";
import IceBreakerResult from "@/views/IceBreakerResults.vue";
import Preloader from "@/views/Preloader.vue";
import RollCall from "@/views/RollCall.vue";
import TagCloud from "@/views/TagCloud.vue";
import VideoSection from "@/components/video-conference/VideoSection.vue";
import LogoutPage from "@/components/LogoutPage.vue";
import Viewer3D from "@/components/Viewer3D.vue";
import Presentation1 from "@/views/Chapter_1.vue";
import Presentation1_1 from "@/components/Relapse/Page_1.vue";
import Presentation1_2 from "@/components/Relapse/Page_2.vue";
// import Presentation1_3 from "@/components/Relapse/Page_3.vue";
// import Presentation1_4 from "@/components/Relapse/Page_4.vue";
import Presentation1_5 from "@/components/Relapse/Page_5.vue";
import Presentation2 from "@/views/Chapter_1_part_2.vue";
import Presentation2_1 from "@/components/Relapse/Page_1_1.vue";
import Presentation2_2 from "@/components/Relapse/Page_2_1.vue";
import Presentation2_3 from "@/components/Relapse/Page_3_1.vue";
import Presentation2_4 from "@/components/Relapse/Page_4_1.vue";
import Presentation2_5 from "@/components/Relapse/Page_5_1.vue";
import Presentation2_6 from "@/components/Relapse/Page_6_1.vue";
import Presentation3 from "@/views/Chapter_2.vue";
import Presentation3_1 from "@/components/RelapseTherapy/Page_1.vue";
import Presentation3_2 from "@/components/RelapseTherapy/Page_2.vue";
import Presentation3_3 from "@/components/RelapseTherapy/Page_3.vue";
import Presentation3_4 from "@/components/RelapseTherapy/Page_4.vue";
// import Presentation3_5 from "@/components/RelapseTherapy/Page_5.vue";
import Presentation3_6 from "@/components/RelapseTherapy/Page_6.vue";

import Presentation3_result_1 from "@/components/RelapseTherapy/Result_1.vue";
import Presentation3_result_2 from "@/components/RelapseTherapy/Result_2.vue";
import Presentation3_result_3 from "@/components/RelapseTherapy/Result_3.vue";

// import Presentation3_6_1 from "@/components/RelapseTherapy/Page_6_1.vue";

import Presentation3_7 from "@/components/RelapseTherapy/Page_7.vue";
import Presentation3_8 from "@/components/RelapseTherapy/Page_8.vue";
import Presentation3_9 from "@/components/RelapseTherapy/Page_9.vue";
import Presentation3_10 from "@/components/RelapseTherapy/Page_10.vue";
// import Presentation3_10_1 from "@/components/RelapseTherapy/Page_10_1.vue";
import Presentation3_11 from "@/components/RelapseTherapy/Page_11.vue";
import Presentation3_12 from "@/components/RelapseTherapy/Page_12.vue";
// import Presentation3_13 from "@/components/RelapseTherapy/Page_13.vue";
import Presentation3_14 from "@/components/RelapseTherapy/Page_14.vue";
import Presentation3_15 from "@/components/RelapseTherapy/Page_15.vue";
// import Presentation3_16 from "@/components/RelapseTherapy/Page_16.vue";
import Presentation3_17 from "@/components/RelapseTherapy/Page_17.vue";
// import Presentation3_18 from "@/components/RelapseTherapy/Page_18.vue";
import Presentation3_19 from "@/components/RelapseTherapy/Page_19.vue";
// import Presentation3_19_1 from "@/components/RelapseTherapy/Page_19_1.vue";
import Presentation4 from "@/views/Chapter_3.vue";
import Presentation4_1 from "@/components/Mosunetuzumab/Page_1.vue";
import Presentation4_2 from "@/components/Mosunetuzumab/Page_2.vue";
// import Presentation4_2_1 from "@/components/Mosunetuzumab/Page_2_results.vue";
import Presentation4_3 from "@/components/Mosunetuzumab/Page_3.vue";
import Presentation4_4 from "@/components/Mosunetuzumab/Page_4.vue";
// import Presentation4_4_1 from "@/components/Mosunetuzumab/Page_4_results.vue";
import Presentation4_5 from "@/components/Mosunetuzumab/Page_5.vue";
import Presentation4_6 from "@/components/Mosunetuzumab/Page_6.vue";
// import Presentation4_6_1 from "@/components/Mosunetuzumab/Page_6_results.vue";
import Presentation4_7 from "@/components/Mosunetuzumab/Page_7.vue";
import Presentation4_8 from "@/components/Mosunetuzumab/Page_8.vue";
import Presentation4_9 from "@/components/Mosunetuzumab/Page_9.vue";

// import Presentation4_result_2 from "@/components/Mosunetuzumab/Result_2.vue";
// import Presentation4_result_3 from "@/components/Mosunetuzumab/Result_3.vue";

import Presentation5 from "@/views/Chapter_4.vue";
import Presentation5_1 from "@/components/NewClass/Page_1.vue";
import Presentation5_2 from "@/components/NewClass/Page_2.vue";
import Presentation5_3 from "@/components/NewClass/Page_3.vue";
import Presentation5_4 from "@/components/NewClass/Page_4.vue";
import Presentation5_5 from "@/components/NewClass/Page_5.vue";
// import Presentation5_6 from "@/components/NewClass/Page_6.vue";
import Presentation5_7 from "@/components/NewClass/Page_7.vue";
// import Presentation5_8 from "@/components/NewClass/Page_8.vue";
import Presentation5_9 from "@/components/NewClass/Page_9.vue";
import Presentation5_10 from "@/components/NewClass/Page_10.vue";
import Presentation5_11 from "@/components/NewClass/Page_11.vue";
// import Presentation5_12 from "@/components/NewClass/Page_12.vue";
import Presentation5_13 from "@/components/NewClass/Page_13.vue";
import Presentation5_14 from "@/components/NewClass/Page_14.vue";
import Presentation5_15 from "@/components/NewClass/Page_15.vue";
import Presentation5_15_1 from "@/components/NewClass/Page_15-1.vue";
import Presentation5_15_2 from "@/components/NewClass/Page_15-2.vue";
import Presentation5_15_3 from "@/components/NewClass/Page_15-3.vue";
import Presentation5_16 from "@/components/NewClass/Page_16.vue";
// import Presentation5_16_1 from "@/components/NewClass/Page_16-1.vue";
import Presentation5_17 from "@/components/NewClass/Page_17.vue";
// import Presentation5_18 from "@/components/NewClass/Page_18.vue";

import Presentation6 from "@/views/Chapter_4_Part_2.vue";
import Presentation5_19 from "@/components/NewClass/Page_19.vue";
// import Presentation5_19_1 from "@/components/NewClass/Page_19-1.vue";
import Presentation5_20 from "@/components/NewClass/Page_20.vue";
import Presentation5_21 from "@/components/NewClass/Page_21.vue";
import Presentation5_22 from "@/components/NewClass/Page_22.vue";
import Presentation5_23 from "@/components/NewClass/Page_23.vue";
import Presentation5_24 from "@/components/NewClass/Page_24.vue";
// import Presentation5_25 from "@/components/NewClass/Page_25.vue";
import Modal_1 from "@/components/Relapse/Modals/Modal_1_1.vue";
import Modal_2 from "@/components/Relapse/Modals/Modal_1_2.vue";
import Modal_3 from "@/components/Relapse/Modals/Modal_1_3.vue";
import Modal_4 from "@/components/Relapse/Modals/Modal_1_4.vue";
import Modal_5 from "@/components/Relapse/Modals/Modal_2_1.vue";
import Modal_6 from "@/components/Relapse/Modals/Modal_2_2.vue";
import Modal_7 from "@/components/Relapse/Modals/Modal_2_3.vue";
import Modal_8 from "@/components/Relapse/Modals/Modal_2_4.vue";
import Modal_9 from "@/components/Relapse/Modals/Modal_2_5.vue";
import Modal_10 from "@/components/Relapse/Modals/Modal_3_1.vue";
import Modal_11 from "@/components/Relapse/Modals/Modal_3_2.vue";
import Modal_12 from "@/components/Relapse/Modals/Modal_3_3.vue";
import Modal_13 from "@/components/Relapse/Modals/Modal_3_4.vue";
import Modal_14 from "@/components/Relapse/Modals/Modal_4_1.vue";
import Modal_15 from "@/components/Relapse/Modals/Modal_4_2.vue";
import Modal_16 from "@/components/Relapse/Modals/Modal_4_3.vue";
import FinalScreen from "@/components/FinalScreen/FinalScreen.vue";
export default {
  name: "MainPage",

  data() {
    return {
      isLoggedIn: false,
      isDisconnected: false,
      isLoadedAssets: false,
    };
  },
  computed: {
    ...mapGetters(["active", "data"]),
    user() {
      return this.$store.state.user;
    },
    isPresentation11() {
      return this.active === '11_presentation_therapy_relevant_poll1' || this.active === '11_presentation_therapy_relevant_result1' || this.active === '11_presentation_therapy_relevant_poll2' || this.active === '11_presentation_therapy_relevant_result2' || this.active === '11_presentation_therapy_relevant_poll3' || this.active === '11_presentation_therapy_relevant_result3' ? true : false;
    },
    isPresentation12() {
      return this.active === '12_presentation_clinical_case_poll1' || this.active === '12_presentation_clinical_case_result1' || this.active === '12_presentation_clinical_case_poll2' || this.active === '12_presentation_clinical_case_result2' || this.active === '12_presentation_clinical_case_poll3' || this.active === '12_presentation_clinical_case_result3' ? true : false;
    },
    isPresentation13() {
      return this.active === '13_presentation_specific_nya_poll1' || this.active === '13_presentation_specific_nya_result1' ? true : false;
    },
    isPresentation14() {
      return this.active === '13_presentation_specific_nya_poll2' || this.active === '13_presentation_specific_nya_result2' ? true : false;
    },
  },
  components: {
    LogoutPage,
    VideoSection,
    RollCall,
    StartPage,
    PatientComponent,
    PatientResult,
    Graph_1,
    Preloader,
    WaitingPage,
    MoodVideo,
    IceBreaker,
    IceBreakerResult,
    TagCloud,
    Viewer3D,
    Presentation1,
    Presentation1_1,
    Presentation1_2,
    // Presentation1_3,
    // Presentation1_4,
    Presentation1_5,
    Presentation2,
    Presentation2_1,
    Presentation2_2,
    Presentation2_3,
    Presentation2_4,
    Presentation2_5,
    Presentation2_6,
    Presentation3,
    Presentation3_result_1,
    Presentation3_result_2,
    Presentation3_result_3,
    Presentation3_1,
    Presentation3_2,
    Presentation3_3,
    Presentation3_4,
    // Presentation3_5,
    Presentation3_6,
    // Presentation3_6_1,
    Presentation3_7,
    Presentation3_8,
    Presentation3_9,
    Presentation3_10,
    // Presentation3_10_1,
    Presentation3_11,
    Presentation3_12,
    // Presentation3_13,
    Presentation3_14,
    Presentation3_15,
    // Presentation3_16,
    Presentation3_17,
    // Presentation3_18,
    Presentation3_19,
    // Presentation3_19_1,
    Presentation4,
    // Presentation4_result_2,
    // Presentation4_result_3,
    Presentation4_1,
    Presentation4_2,
    // Presentation4_2_1,
    Presentation4_3,
    Presentation4_4,
    // Presentation4_4_1,
    Presentation4_5,
    Presentation4_6,
    // Presentation4_6_1,
    Presentation4_7,
    Presentation4_8,
    Presentation4_9,
    Presentation5,
    Presentation5_1,
    Presentation5_2,   
    Presentation5_3,   
    Presentation5_4,
    Presentation5_5,
    // Presentation5_6,
    Presentation5_7,
    // Presentation5_8,
    Presentation5_9,
    Presentation5_10,
    Presentation5_11,
    // Presentation5_12,
    Presentation5_13,
    Presentation5_14,
    Presentation5_15,
    Presentation5_15_1,
    Presentation5_15_2,
    Presentation5_15_3,
    Presentation5_16,
    // Presentation5_16_1,
    Presentation5_17,
    // Presentation5_18,
    Presentation6,
    Presentation5_19,
    // Presentation5_19_1,
    Presentation5_20,
    Presentation5_21,
    Presentation5_22,
    Presentation5_23,
    Presentation5_24,
    // Presentation5_25,
    Modal_1,
    Modal_2,
    Modal_3,
    Modal_4,
    Modal_5,
    Modal_6,
    Modal_7,
    Modal_8,
    Modal_9,
    Modal_10,
    Modal_11,
    Modal_12,
    Modal_13,
    Modal_14,
    Modal_15,
    Modal_16,
    FinalScreen,
},
  methods: {
    loadedImages() {
      //если все ассеты закешированы - прелоадер крутится 3 секунды
      setTimeout(() => {
        this.isLoadedAssets = true;
      }, 3000)
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(5px);
  opacity: 0;
}
</style>