<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Монотерапия биспецифическими антителами редко <br>приводит к развитию ICANS-подобных <br>нежелательных явлений при рецидивах ФЛ</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-61">
          <img src="@/assets/img/frame-61.png" alt="">
        </div>
        <div class="frame-note">1. Budder LE et al (2022) LancetOncology, 23(8), 1055-1065;  <br>
          2. Linton K. et al. ASH 2023. Abstract 1655.Blood 142(suppl 1):1655; <br>
          3. Villasboas JC etal. ASH 2023. Blood 142 (suppl 1): 3041</div>
      </div>
    </div>
    <Pagination :total="6" :page="4" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_22",
    components: {
      Pagination,
    }
  }
</script>