<template>
  <ChapterView>
    <template v-slot:title><Chapter_1_Title_Part_2 /></template>
    <template v-slot:avatar><img src="@/assets/img/avatar-8.png" alt=""></template>
    <template v-slot:name>Батухтина <br>Юлия Валерьевна</template>
    <template v-slot:regalia>к.м.н., КГБУЗ КККОД им. <br>А.И. Крыжановского</template>
  </ChapterView>
</template>

<script>
import ChapterView from '@/components/ChapterView'
import Chapter_1_Title_Part_2 from '@/components/Chapter_1_Title_Part_2.vue'

export default {
  name: 'Chapter_1_part_2',
  components: {
    ChapterView,
    Chapter_1_Title_Part_2,
  },
}
</script>