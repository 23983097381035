<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">СВЦ: классификация по степени тяжести</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-50">
          <img src="@/assets/img/frame-50.png" alt="">
        </div>
        <div class="frame-note large">Lee DW, et al. Biol Blood Marrow Transplant 2019;25:625–38.</div>
      </div>
    </div>
    <Pagination :total="17" :page="9" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_11",
    components: {
      Pagination,
    }
  }
</script>