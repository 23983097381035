<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Основные проблемы в 3+ <br>линии терапии ФЛ</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-11">
          <img src="@/assets/img/frame-11.png" alt="">
        </div>
        <div class="frame-note">Собственные данные автора: данные реальной практики в России 2023 года и данные серии региональных встреч по ФЛ в РФ <br>ПО - полный ответ; POD24 - прогрессирование заболевания в течение 24 месяцев с начала первой линии иммунохимиотерапии; ХТ - химиотерапии; 3Л+ - третья и последующие линии</div>
      </div>
    </div>
    <Pagination :total="6" :page="5" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapsePage_1",
    components: {
      Pagination,
    }
  }
</script>