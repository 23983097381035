<script setup lang="ts"></script>

<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5 4C14.2909 4 12.5 5.79086 12.5 8V15C12.5 17.2091 14.2909 19 16.5 19C18.7091 19 20.5 17.2091 20.5 15V8C20.5 5.79086 18.7091 4 16.5 4ZM11.5 15C11.5 14.4477 11.0523 14 10.5 14C9.94772 14 9.5 14.4477 9.5 15C9.5 18.5265 12.1077 21.4439 15.5 21.9291V25.0076C15.4598 25.0126 15.4199 25.02 15.3806 25.0299L11.9462 25.8884C11.663 25.9593 11.4249 26.1503 11.2943 26.4114L11.2236 26.5528C10.8912 27.2177 11.3747 28 12.118 28H20.882C21.6253 28 22.1088 27.2177 21.7764 26.5528L21.7057 26.4114C21.5751 26.1503 21.337 25.9593 21.0538 25.8884L17.6194 25.0299C17.5801 25.02 17.5402 25.0126 17.5 25.0076V21.9291C20.8923 21.4439 23.5 18.5265 23.5 15C23.5 14.4477 23.0523 14 22.5 14C21.9477 14 21.5 14.4477 21.5 15C21.5 17.7614 19.2614 20 16.5 20C13.7386 20 11.5 17.7614 11.5 15Z"
      fill="white"
    />
  </svg>
</template>

<style scoped lang="sass"></style>
