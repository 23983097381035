<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Терапия мосунетузумабом позволяла достигать <br>более продолжительной ремиссии по сравнению <br>с предшествующей терапией</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-25">
          <img src="@/assets/img/frame-25.png" alt="">
        </div>
        <div class="frame-notes frame-notes--3">
          <div class="frame-note large">Медиана наблюдения – 28,3 месяца</div>
          <div class="frame-note large">Schuster SJ et al. ASH 2023.Abstract 603 &presentation</div>
        </div>
      </div>
    </div>
    <Pagination :total="15" :page="12" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapseTherapyPage_13",
    components: {
      Pagination,
    }
  }
</script>