<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Клиническое наблюдение. <br>Женщина, 53 года</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-37">
          <img src="@/assets/img/frame-37.png" alt="">
        </div>
        <div class="frame-note">Собственные данные автора <br>R, ритуксимаб; DHAP,  дексаметазон (D), высокодозный цитарабин (HA), цисплатин (P); ОГК, органы грудной клетки; ОБП, органы брюшной полости; ЗП, забрюшинное пространство; ОМТ, органы малого таза </div>
      </div>
    </div>
    <Pagination :total="9" :page="7" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "MosunetuzumabPage_7",
    components: {
      Pagination,
    }
  }
</script>