<script setup lang="ts">
import HeartIcon from "@/components/video-conference/icons/HeartIcon.vue";
import { ref } from "vue";

const reactions = ["👍", "💜", "👏", "😀", "😢"];
const isOpen = ref(false);

const emit = defineEmits<{
  (e: "setReaction", data: string): void;
}>();

const onClick = (idx: number) => {
  emit("setReaction", reactions[idx]);
  isOpen.value = false;
};
</script>

<template>
  <div class="video-reaction">
    <button class="video-reaction__trigger" @click="isOpen = !isOpen">
      <heart-icon />
    </button>

    <div>
      <template v-for="(r, idx) in reactions" :key="r">
        <transition :name="`reaction-${idx}`">
          <button
            v-if="isOpen"
            class="video-reaction__item"
            :class="`video-reaction__item--${idx}`"
            @click="onClick(idx)"
          >
            {{ r }}
          </button>
        </transition>
      </template>
    </div>
  </div>
</template>

<style lang="sass">
@import "@/sass/reaction-item"

@mixin generate-keyframe($name, $x, $y)
  animation: 0.6s $name

  @keyframes #{$name}
    0%
      opacity: 0
      transform: translate(-$x, -$y) scale(0)
    50%
      opacity: 1
      transform: translate(-($x * 0.15), -($y * 0.15)) scale(1)
    75%
      transform: translate($x * 0.15, $y * 0.15)
    100%
      transform: translate(0, 0)

.video-reaction
  position: relative

  &__trigger, &__item
    all: unset
    @include video-reaction-item
    cursor: pointer


  &__trigger
    width: 40px
    height: 40px

  &__item
    width: 32px
    height: 32px

  &__item
    position: absolute

    &--0
      top: 10px
      left: -44px

    &--1
      top: -17px
      left: -69px

    &--2
      top: -25px
      left: -34px

    &--3
      top: -44px
      left: -3px

    &--4
      top: -61px
      left: -45px

.reaction-0-leave-to
  transform: translate(44px, -10px) scale(0)
  opacity: 0
  z-index: 0

.reaction-1-leave-to
  transform: translate(69px, 17px) scale(0)
  opacity: 0
  z-index: 0

.reaction-2-leave-to
  transform: translate(34px, 25px) scale(0)
  opacity: 0
  z-index: 0
.reaction-3-leave-to
  transform: translate(3px, 44px) scale(0)
  opacity: 0
  z-index: 0
.reaction-4-leave-to
  transform: translate(45px, 61px) scale(0)
  opacity: 0
  z-index: 0

.reaction-0-leave-active,
.reaction-1-leave-active,
.reaction-2-leave-active,
.reaction-3-leave-active,
.reaction-4-leave-active
  transition: all 0.6s



.reaction-0-enter-active
  @include generate-keyframe(bounce-0, -44px, 10px)
.reaction-1-enter-active
  @include generate-keyframe(bounce-1, -69px, -17px)
.reaction-2-enter-active
  @include generate-keyframe(bounce-2, -34px, -25px)
.reaction-3-enter-active
  @include generate-keyframe(bounce-3, -3px, -44px)
.reaction-4-enter-active
  @include generate-keyframe(bounce-4, -45px, -61px)
</style>
