<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Опрос—интерактив</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-55">
          <img src="@/assets/img/frame-55-1.png" alt="">
          <div class="frame-content">
            результаты
          </div>
        </div>
        <div class="frame-note large">Собственные данные автора. </div>
      </div>
    </div>
    <Pagination :total="17" :page="16" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_16",
    components: {
      Pagination,
    },
  }
</script>