<template>
  <div class="three-d-viver" :class="{ zoom: showAnimation }">
    <preloader v-if="showAnimation" :need-load="false" />

    <h1 class="three-d-viver__title" v-if="!showAnimation">
      мосунетузумаб 360&#176;
    </h1>

    <div class="three-d-viver__hint" v-if="!showAnimation">
      <!-- <person-icon
        class="three-d-viver-hint"
        v-if="!showHint"
        @click.stop="showHint = !showHint"
      />

      <transition name="viewer-hint-transition">
        <div class="three-d-viver__hint-card" v-if="showHint">
          <img
            src="@/assets/img/3d-hint.png"
            class="three-d-viver__hint-icon"
          />

          <div class="three-d-viver__hint-content">
            <p class="three-d-viver__hint-title">
              Тумян <br />
              Гаяне Сергеевна
            </p>
            <p class="three-d-viver__hint-subtitle">
              профессор, д.м.н., НМИЦ онкологии <br />
              имени Н.Н. Блохина МЗ РФ, Москва
            </p>
          </div>
        </div>
      </transition> -->
    </div>
    <div
      id="3d-viewer"
      class="hover3d-curtains"
      :class="{ 'd-viewer--animation': showAnimation }"
    >
      <div
        id="hovers"
        class="hidden"
        style="position: absolute; top: 0px; left: 0px"
      >
        <div
          data-hover3d="TCELL_"
          class="hover3d-item left hover3d-item-1"
          style="left: 743.851px; top: 95.9428px"
        >
          <div class="hover3d-info">
            <p class="hover3d-title">Опухолевая В-клетка</p>
          </div>
          <div class="hover3d-pin"></div>
        </div>

        <div
          data-hover3d="CD20_"
          class="hover3d-item left hover3d-item-2"
          style="left: 743.851px; top: 95.9428px"
        >
          <div class="hover3d-info">
            <p class="hover3d-title">
              Анти-CD20 Fab-фрагмент<sup><small> 1,2</small></sup>
            </p>
            <p class="hover3d-text hover-3d-text-2">
              Связывает CD20 на поверхности
              <span class="nowrap">В-лимфоцитов</span>
            </p>
          </div>
          <div class="hover3d-pin"></div>
        </div>

        <div
          data-hover3d="CENTER_"
          class="hover3d-item left hover3d-item-3"
          style="left: 743.851px; top: 95.9428px"
        >
          <div class="hover3d-info">
            <p class="hover3d-title">Мосунетузумаб</p>
            <p class="hover3d-text hover-3d-text-3">
              Является полноразмерным гуманизированным биспецифическим антителом
              класса IgG1
            </p>
          </div>
          <div class="hover3d-pin"></div>
        </div>

        <div
          data-hover3d="TAIL_"
          class="hover3d-item left hover3d-item-4"
          style="left: 743.851px; top: 95.9428px"
        >
          <div class="hover3d-info">
            <p class="hover3d-title">
              Инактивированный Fс-фрагмент<sup><small>1-4</small></sup>
            </p>
            <p class="hover3d-text hover-3d-text-4">
              Обеспечивает большую стабильность антитела; не активирует
              <span class="nowrap">антитело-зависимую</span> клеточную
              цитотоксичность
            </p>
          </div>
          <div class="hover3d-pin"></div>
        </div>

        <div
          data-hover3d="CD3_"
          class="hover3d-item left hover3d-item-5"
          style="left: 743.851px; top: 95.9428px"
        >
          <div class="hover3d-info">
            <p class="hover3d-title">
              Анти-CD3 Fab-фрагмент<sup><small>1,2</small></sup>
            </p>
            <p class="hover3d-text hover-3d-text-5">
              CD3 — компонент комплекса TCR на поверхности
              <span class="nowrap">T-лимфоцитов</span>
            </p>
          </div>
          <div class="hover3d-pin"></div>
        </div>

        <div
          data-hover3d="BCELL_"
          class="hover3d-item right hover3d-item-6"
          style="left: 331.258px; top: 670.868px"
        >
          <div class="hover3d-info">
            <p class="hover3d-title">T-лимфоцит</p>
          </div>
          <div class="hover3d-pin"></div>
        </div>
      </div>
    </div>

    <div class="three-d-viver__footer">
      <info-icon class="three-d-viver-icon" @click="showFooter = true" />
      <transition name="viewer-transition">
        <div class="three-d-viver__footer-info" v-if="showFooter">
          1. Общая характеристика лекарственного препарата мосунетузумаб. ЛП-N(003673)-(РГ-RU); <br>
          2. Sun LL, et al. Sci Transl Med 2015; 7: 287ra70; <br>
          3. Lo M, et al. J Biol Chem 2017; 292: 3900–8;<br>
          4. Kellner C, et al. Transfus Med Hemother 2017; 44: 327–36<br>
          Данная презентация подготовлена при поддержке АО "Рош-Москва”. Информация предназначена<br>
          только для медицинских работников. Мнение лектора может не совпадать с позицией АО<br>
          "Рош-Москва”». 21 февраля 2024 года. M-RU-00015465 февраль 2024
          <close-icon
            class="three-d-viver__footer-info-icon"
            @click="showFooter = false"
          ></close-icon>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import InfoIcon from "./icons/InfoIconLight.vue";
import CloseIcon from "./icons/CloseIcon.vue";

import { init3DView } from "../lib/3d-viewer/3d-viewer.js";
import Preloader from "@/views/Preloader.vue";
import { ymEvent } from "@/utils/ymEvent";
// import PersonIcon from "./icons/PersonIcon.vue";
let view = null;
const showFooter = ref(false);
const showHint = ref(false);

const showAnimation = ref(true);
function removeCards(e: Event) {
  if (!e.target) {
    return;
  }
  showHint.value = false;

  if (
    ![...(e.target as HTMLElement).classList].some((el) =>
      el.includes("hover3d")
    )
  ) {
    const allTexts = document.querySelectorAll(".hover3d-item");
    allTexts.forEach((t) => t.classList.remove("hover3d-item-visible"));
  }
}

const cardGoals = {
  CD3_: "anticd3fab",
  CENTER_: "mosun3dmolecule",
  CD20_: "anticd20fab",
};

function onCardClick(el: Element) {
  return () => {
    const goalName = cardGoals[el.getAttribute("data-hover3d")];
    if (goalName) {
      console.log(goalName);
      ymEvent(goalName);
    }
    showHint.value = false;
    const allTexts = document.querySelectorAll(".hover3d-item");
    allTexts.forEach((t) => t.classList.remove("hover3d-item-visible"));
    el.classList.add("hover3d-item-visible");
  };
}

onMounted(async () => {
  const viewContainer = document.getElementById("3d-viewer");
  view = init3DView(viewContainer, {
    modelUrl: "/3d-viewer/roche_mosunetuzumab_web_v3_noPOI(fix+compressed).glb",
    configUrl: "/3d-viewer/roche_scene_closeup_035(CD20-swap-CD3).json",
    hdriUrl: "/3d-viewer/winter_evening_1k.hdr",
  });
  await view.loading;
  showAnimation.value = false;
  window.addEventListener("click", removeCards);

  document.querySelectorAll(".hover3d-item").forEach((el) => {
    el.addEventListener("click", onCardClick(el));
  });
});

onBeforeUnmount(() => {
  view.dispose();
});
</script>

<style lang="sass">
@import "../sass/functions.sass"

.hover3d-pin
  width: vw(44px) !important
  height: vw(44px) !important
  background-size: contain

.hover3d-info
  border-radius: vw(12px) !important

.hover3d-item
  &-1
    .hover3d-info
      width: vw(215px)
  &-2
    .hover3d-info
      width: vw(280px)
  &-3
    .hover3d-info
      width: vw(267px)
  &-4
    .hover3d-info
      width: vw(350px)
  &-5
    .hover3d-info
      width: vw(270px)
  &-6
    .hover3d-info
      width: vw(130px)

@keyframes unhide-custom-2
    0%
        opacity: 0
        height: 0px


    100%
        opacity: 1
        height: vw(64px)



@keyframes unhide-custom-3
    0%
        opacity: 0
        height: 0p


    100%
        opacity: 1
        height: vw(119px)



@keyframes unhide-custom-4
    0%
        opacity: 0
        height: 0px


    100%
        opacity: 1
        height: vw(96px)



@keyframes unhide-custom-5
    0%
        opacity: 0
        height: 0px

    100%
        opacity: 1
        height: vw(70px)

@keyframes moveLeft
    0%
        translate: -70% 0%
        opacity: 0


    30%

    80%
        translate: -100% 0%
        opacity: 1


    100%
        translate: calc(-100% - vw(8px)) 0%
        opacity: 1




@keyframes moveRight
    0%
        translate: -50% 0%
        opacity: 0


    100%
        translate: vw(52px) 0%
        opacity: 1

.hover-3d-text-2 
    animation: .45s normal forwards unhide-custom-2 !important


.hover-3d-text-3 
    animation: .45s normal forwards unhide-custom-3 !important


.hover-3d-text-4 
    animation: .45s normal forwards unhide-custom-4 !important


.hover-3d-text-5 
    animation: .45s normal forwards unhide-custom-5 !important

</style>
<style lang="sass" scoped>
@import "../sass/functions.sass"
@import "../sass/styles.sass"

.hover3d-title
  font-size: vw(22px) !important
  padding: vw(9px) !important

.hover3d-text
  padding-top: vw(9px)
  margin: 0 vw(9px) vw(9px)
  font-size: vw(16px)
  line-height: vw(28px)

.d-viewer--animation .hover3d-item
  opacity: 0

.three-d-viver
  position: relative
  overflow: hidden

  &__hint
    position: absolute
    left: vw(32px)
    top: vw(123px)
    width: vw(42px)
    height: vw(42px)
    z-index: 1

    &-card
      position: absolute
      top: 0
      background-color: rgba(255, 255, 255, 0.4)
      border-radius: 16px
      padding: 25px 37px 25px 25px
      display: flex
      gap: 20px
      align-items: center
      width: 424px
      height: 172px
      overflow: hidden
    &-icon
      border-radius: 50%
      width: 97px
      height: 97px

    &-content
      display: flex
      flex-direction: column
      gap: 16px

    &-title
      font-size: 28px
      font-weight: 500
      line-height: 36px
      letter-spacing: 0.02em
      text-align: left
      color: rgba(49, 8, 68, 1)
      white-space: nowrap


    &-subtitle
      color: rgba(49, 8, 68, 1)
      font-size: 15px
      font-weight: 400
      line-height: 24px
      letter-spacing: 0.02em
      text-align: left
      white-space: nowrap



  &__title
    position: absolute
    left: vw(32px)
    top: vw(60px)
    font-family: DIN Condensed
    font-size: vw(56px)
    font-weight: 700
    line-height: vw(56px)
    text-align: left
    text-transform: uppercase
    color: #fff
    z-index: 1

  &__footer
    position: absolute
    left: vw(46px)
    bottom: vw(28px)

    &-info
      box-sizing: border-box
      bottom: 0
      z-index: 1
      position: absolute
      box-shadow: 0px 44px 72px 0px rgba(0, 0, 0, 0.32)
      padding: vw(24px)
      font-size: vw(12px)
      line-height: vw(17px)
      letter-spacing: 0.02em
      color: rgba(45, 40, 48, 1)
      border-radius: vw(16px)
      width: vw(644px)
      height: vw(159px)
      overflow: hidden
      background: #fff

      &-icon
        position: absolute
        top: vw(24px)
        right: vw(24px)
        height: vw(32px)
        width: vw(32px)
        cursor: pointer

  &-icon
    cursor: pointer
    width: vw(32px)
    height: vw(32px)

.viewer-transition-enter-active,
.viewer-transition-leave-active
  transition: all 0.3s ease

.viewer-transition-enter-to,
.viewer-transition-leave-from
  width: vw(644px)
  height: vw(159px)
  opacity: 1

.viewer-transition-enter-from,
.viewer-transition-leave-to
  width: 0
  height: 0
  padding: 0
  opacity: 0

.viewer-hint-transition-enter-active,
.viewer-hint-transition-leave-active
  transition: all 0.3s ease-out

.viewer-hint-transition-enter-to,
.viewer-hint-transition-leave-from
  width: 424px
  height: 172px
  opacity: 1

.viewer-hint-transition-enter-from,
.viewer-hint-transition-leave-to
  width: 0
  height: 0
  padding: 0
  opacity: 0
</style>
