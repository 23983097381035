<script setup lang="ts">
import { VideoActionProps } from "@/components/video-conference/types/video-action-props";
import { computed, ref } from "vue";

import CameraIcon from "./icons/CameraIcon.vue";
import CameraIconActive from "./icons/CameraIconActive.vue";

import HandIcon from "./icons/HandIcon.vue";
import HandIconActive from "./icons/HandIconActive.vue";

import MicrophoneIcon from "./icons/MicrophoneIcon.vue";
import MicrophoneIconActive from "./icons/MicrophoneIconActive.vue";
import VideoLoader from "@/components/video-conference/VideoLoader.vue";

const componentMap = {
  CameraIcon,
  CameraIconActive,
  HandIcon,
  HandIconActive,
  MicrophoneIcon,
  MicrophoneIconActive,
};

const isLoading = ref(false);

const props = defineProps<VideoActionProps>();

const iconComponent = computed(() => {
  return componentMap[props.isActive ? props.icon : props.activeIcon];
});

const msg = computed(() => {
  return props.isActive ? props.activeText : props.text;
});

const onClick = async () => {
  if (isLoading.value || props.disabled) return;

  isLoading.value = true;
  try {
    await props.cb();
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <button
    class="video-button video-action"
    :disabled="disabled || isLoading"
    :class="{ 'video-action--small': isSmall }"
    @click="onClick"
  >
    <component :is="iconComponent" class="video-action__icon" />

    <span class="video-button__msg" v-if="!isSmall">
      {{ msg }}
    </span>

    <video-loader class="video-action__loader" v-if="isLoading" />
  </button>
</template>

<style scoped lang="sass">
@import "@/sass/video-button.sass"

.video-action
  padding: 10px 8px
  min-width: 107px

  position: relative

  &__loader
    position: absolute
    transform: translate(-50%, -50%)

  &__icon
    margin-bottom: 8px

  &--small
    padding: 8px
    min-width: unset

    .video-action__icon
      width: 24px
      height: 24px
      margin-bottom: 0
</style>
