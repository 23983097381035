<script setup lang="ts">

</script>

<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1111 8C9.67172 8 8.44444 9.21964 8.44444 10.8C8.44444 11.6418 8.79661 12.389 9.34672 12.8992C9.75164 13.2748 9.77541 13.9075 9.39983 14.3125C9.02424 14.7174 8.39152 14.7411 7.98661 14.3656C7.03644 13.4842 6.44444 12.2091 6.44444 10.8C6.44444 8.183 8.50042 6 11.1111 6C12.3166 6 13.4126 6.47105 14.2356 7.23444C14.6405 7.61002 14.6643 8.24274 14.2887 8.64766C13.9131 9.05257 13.2804 9.07634 12.8755 8.70076C12.4011 8.26072 11.7839 8 11.1111 8ZM17.7644 7.23444C18.5874 6.47105 19.6834 6 20.8889 6C23.4996 6 25.5556 8.183 25.5556 10.8C25.5556 12.2091 24.9636 13.4842 24.0134 14.3656C23.6085 14.7411 22.9758 14.7174 22.6002 14.3125C22.2246 13.9075 22.2484 13.2748 22.6533 12.8992C23.2034 12.389 23.5556 11.6418 23.5556 10.8C23.5556 9.21964 22.3283 8 20.8889 8C20.2161 8 19.5989 8.26072 19.1245 8.70076C18.7196 9.07634 18.0869 9.05257 17.7113 8.64766C17.3357 8.24274 17.3595 7.61002 17.7644 7.23444ZM9.63169 18.4545C9.77648 18.9875 9.46181 19.5369 8.92885 19.6817C7.96949 19.9423 7.20863 20.357 6.70575 20.8257C6.20644 21.291 6 21.7664 6 22.2005C6 22.7528 5.55228 23.2005 5 23.2005C4.44772 23.2005 4 22.7528 4 22.2005C4 21.0763 4.54773 20.103 5.34217 19.3626C6.13304 18.6255 7.20551 18.0774 8.40449 17.7516C8.93745 17.6068 9.48689 17.9215 9.63169 18.4545ZM22.3683 18.4545C22.5131 17.9215 23.0625 17.6068 23.5955 17.7516C24.7945 18.0774 25.867 18.6255 26.6578 19.3626C27.4523 20.103 28 21.0763 28 22.2005C28 22.7528 27.5523 23.2005 27 23.2005C26.4477 23.2005 26 22.7528 26 22.2005C26 21.7664 25.7936 21.291 25.2943 20.8257C24.7914 20.357 24.0305 19.9423 23.0712 19.6817C22.5382 19.5369 22.2235 18.9875 22.3683 18.4545Z" fill="white"/>
    <path d="M16 21C19.6128 21 22.5863 22.682 22.9603 24.8412C23.0104 25.1305 22.8698 25.4104 22.6183 25.5618C21.8279 26.0376 19.764 27 16 27C12.236 27 10.1721 26.0376 9.38173 25.5618C9.13023 25.4104 8.98955 25.1305 9.03966 24.8412C9.41367 22.682 12.3872 21 16 21Z" fill="white"/>
    <path d="M11 15C11 17.7614 13.2386 20 16 20C18.7614 20 21 17.7614 21 15C21 12.2386 18.7614 10 16 10C13.2386 10 11 12.2386 11 15Z" fill="white"/>
  </svg>
</template>

<style scoped lang="sass">

</style>
