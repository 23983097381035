<template>
  <div class="preloader">
    <div class="preloader-item"></div>
  </div>
</template>

<script>
export default {
  name: 'StartPreloader',
  props: {
	needLoad: {
		type: Boolean,
        default: true
	}
  },
  data() {
    return {
      loaded: false,
    }
  },
  async mounted() {
	if (!this.needLoad) return;

	let video = require('@/assets/video/bg.mp4');
	await this.preloadVideo(video);
    this.loadAllImages();
	
  },
  methods: {
	async preloadVideo(src) {
		const res = await fetch(src);
		const blob = await res.blob();
		return URL.createObjectURL(blob);
	},
	loadImage(file) {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = file;
			
			img.onload = () => {
					resolve();
			};

			img.onerror = reject;
		});
	},
	loadAllImages() {
		const imagePromises = [];

		imagePromises.push(this.loadImage(require('@/assets/img/02.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/03.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/04.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/08.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/background-1.jpg')));
		imagePromises.push(this.loadImage(require('@/assets/img/background-2.jpg')));
		imagePromises.push(this.loadImage(require('@/assets/img/background-3.jpg')));
		imagePromises.push(this.loadImage(require('@/assets/img/ball.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/close-button.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/info.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/input-arrow.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/login-form-element-1.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/login-form-element-2.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/man.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/man-dark.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/people.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/play-button.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/revert-button.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/roll-call-connected.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/sphere-1.png')));
		imagePromises.push(this.loadImage(require('@/assets/img/sphere-2.png')));
		imagePromises.push(this.loadImage(require('@/assets/img/sphere-3.png')));
		imagePromises.push(this.loadImage(require('@/assets/img/sphere-4.png')));
		imagePromises.push(this.loadImage(require('@/assets/img/sphere-5.png')));
		imagePromises.push(this.loadImage(require('@/assets/img/sphere-line-1.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/sphere-line-2.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/start-background.jpg')));
		imagePromises.push(this.loadImage(require('@/assets/img/start-element-1.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/start-element-2.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/start-element-3.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/video-attachment.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/video-play.png')));
		imagePromises.push(this.loadImage(require('@/assets/img/woman.svg')));
		imagePromises.push(this.loadImage(require('@/assets/img/woman-dark.svg')));

		Promise.all(imagePromises).then(() => {
			this.loaded = true;
			this.$emit('loaded');
		}).catch(error => {
			console.error("Error loading images:", error);
		});
	}
  }
}
</script>

<style lang="sass">
@import "../sass/functions.sass"
.preloader
  width: 100vw
  min-width: 100vw
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  &-item 
    width: vw(429px)
    height: vw(415px)
    background-size: contain
    background-repeat: no-repeat
    background-image: url(@/assets/img/preloader-element.svg)
    animation: flashing 2s linear infinite

@keyframes flashing
  0%
    opacity: 1
  50%
    opacity: 0
  100%
    opacity: 1
</style>