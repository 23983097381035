<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">СВЦ является диагнозом исключения</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-44">
          <img src="@/assets/img/frame-44.png" alt="">
        </div>
        <div class="frame-note large">Gödel P et al. Intensive Care Med 2018; 44:371–3 <br>СЛО, синдром лизиса опухоли</div>
      </div>
    </div>
    <Pagination :total="17" :page="5" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_5",
    components: {
      Pagination,
    }
  }
</script>