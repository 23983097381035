<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Особенности режима <br>дозирования мосунетузумаба</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-17">
          <img src="@/assets/img/frame-17.png" alt="">
        </div>
        <div class="frame-note large">Budde LE, et al. (2022) The Lancet Oncology, 23(8): 1055-1065</div>
      </div>
    </div>
    <Pagination :total="15" :page="5" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapseTherapyPage_5",
    components: {
      Pagination,
    }
  }
</script>