<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full" v-html="data.title"></h1>
      </div>

      <div class="frame-wrapper">
        <div class="content-frame content-frame-58">
          <img src="@/assets/img/frame-58.png" alt="" />
          <div
            class="frame-content local-frame-content"
            v-if="active === '13_presentation_specific_nya_poll2'"
          >
            <div
              class="frame-content-title custom-title"
              v-html="data.title"
            ></div>
            <div class="container" style="align-items: start;">
              <div
                class="content-frame-field"
                style="margin: 0"
                v-for="item in data.options"
                :key="item.ident"
              >
                <div class="radio radio--circle">
                  <label class="radio-container">
                    <p style="line-height: 1.2;">{{ item.title }}</p>
                    <input
                      type="radio"
                      name="question"
                      :value="item.ident"
                      v-model="answer"
                    />
                    <span class="radio-checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="content-frame-row content-frame-row--button">
              <div class="content-frame-field">
                <button
                  class="content-frame-button content-frame-button--2"
                  @click="submit"
                  v-if="!submited"
                >
                  Ответить
                </button>
                <button
                  class="content-frame-button button--disabled content-frame-button--2"
                  disabled
                  v-else
                >
                  Отправлено
                </button>
              </div>
            </div>
          </div>

          <div class="frame-content local-frame-content" v-else-if="active === '13_presentation_specific_nya_result2'">
            <div
              class="frame-content-title custom-title"
              v-html="data.title"
            ></div>
            <div class="container">
              <div
                class="frame-results-item column progress"
                v-for="(item, idx) in answerItems"
                :key="idx"
              >
                <div class="frame-results-item-title" style="margin-right: 0">
                  {{ item.title }}
                </div>
                <div style="display: flex; gap: 10px" >
                  <div class="frame-results-item-progress">
                    <span :style="{ width: item.value + '%' }"></span>
                  </div>
                  <div class="frame-results-item-number">{{ item.value }}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="frame-note large">
          Данный клинический случай разработан с целью обучения и не включает
          информацию о НЯ/ОСу реального пациента. Предоставлен проф.
          ГеоргомХессом, Университет Иоганна Гутенберга, Майнце,Германия,,.
        </div>
      </div>
    </div>
    <Pagination :total="6" :page="1" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
export default {
  name: "NewClassPage_19",
  data() {
    return {
      answer: null,
      submited: false,
    };
  },
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    Pagination,
  },
  computed: {
    active() {
      return this.$store.getters["active"];
    },
    answerItems() {
      const { answers, options } = this.data;
      let totalAnswes = 0;

      Object.values(answers).forEach((el) => {
        totalAnswes += el;
      });

      return options.map((el) => {
        const val = answers[el.ident];

        return {
          title: el.title,
          value: val ? Math.round((val / totalAnswes) * 100) : 0,
        };
      });
    },
  },
  methods: {
    async submit() {
      console.log("test");
      let answers = [];
      answers.push({ ident: this.answer });
      let payload = {
        answers: answers,
        ident: "13_presentation_specific_nya_poll2",
      };
      await this.$store.dispatch("sendPoll", payload);
      this.submited = true;
    },
  },
};
</script>
<style scoped lang="sass">
@import "@/sass/functions"

.local-frame-content
  bottom: 0
  overflow: hidden
  top: unset
  height: vw(270px)
  right: vw(16px)
.progress
  padding: vw(14px) 0
  justify-content: flex-end
  .frame-results-item-title
    font-size: vw(22px)
    line-height: vw(30px)
    white-space: nowrap
    letter-spacing: 0.2px
  .frame-results-item-number
    font-size: vw(22px)

  &:last-child
    background: #35B520
    color: #fff
    border-radius: vw(8px)
    padding: vw(10px) vw(16px) vw(10px) vw(16px)
    justify-content: flex-start

    .frame-results-item-title
      color: #fff
    .frame-results-item-progress
      background: rgba(255,255,255, 0.3)
      span
        background: #fff
    .frame-results-item-number
      color: #fff
.custom-title
  font-size: vw(28px)
  font-weight: 700
  line-height: vw(36px)
  letter-spacing: 0.02em
  text-align: left
  margin-bottom: vw(16px)
  margin-top: vw(21px)

.container
  display: grid
  grid-template-columns: 1fr 1fr
  gap:  0 vw(147px)
  padding: 0
  padding-right: vw(90px)
  align-items: flex-start
  .frame-results-item-progress
    width: vw(422px)
  .frame-results-item.column
    margin-bottom: 0
.content-frame-row
  flex-wrap: wrap

.radio-container p
  max-width: 18vw

.content-frame-58 .content-frame-field
  margin-right: 0 !important

.content-frame-row
  padding-right: vw(120px)

.content-frame-58 .content-frame-button
  right: vw(40px)
  bottom: vw(16px)
</style>
