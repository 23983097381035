<script setup lang="ts"></script>

<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.5 20C25.5 24.42 21.92 28 17.5 28C14.24 28 11.31 26.01 10.1 22.98L7.07004 15.37C6.99982 15.1934 6.98137 15.0005 7.01686 14.8138C7.05234 14.627 7.1403 14.4543 7.27042 14.3158C7.40054 14.1773 7.56744 14.0787 7.75156 14.0316C7.93568 13.9846 8.12941 13.9909 8.31004 14.05L9.10004 14.31C9.66004 14.49 10.12 14.92 10.34 15.47L11.62 18.68C11.7 18.88 11.88 19 12.08 19H12.5V7.25C12.5 6.91848 12.6317 6.60054 12.8662 6.36612C13.1006 6.1317 13.4185 6 13.75 6C14.0816 6 14.3995 6.1317 14.6339 6.36612C14.8683 6.60054 15 6.91848 15 7.25V15.5C15 15.78 15.22 16 15.5 16C15.78 16 16 15.78 16 15.5V5.25C16 4.91848 16.1317 4.60054 16.3662 4.36612C16.6006 4.1317 16.9185 4 17.25 4C17.5816 4 17.8995 4.1317 18.1339 4.36612C18.3683 4.60054 18.5 4.91848 18.5 5.25V15.5C18.5 15.78 18.72 16 19 16C19.28 16 19.5 15.78 19.5 15.5V6.75C19.5 6.41848 19.6317 6.10054 19.8662 5.86612C20.1006 5.6317 20.4185 5.5 20.75 5.5C21.0816 5.5 21.3995 5.6317 21.6339 5.86612C21.8683 6.10054 22 6.41848 22 6.75V15.5C22 15.78 22.22 16 22.5 16C22.78 16 23 15.78 23 15.5V9.75C23 9.41848 23.1317 9.10054 23.3662 8.86612C23.6006 8.6317 23.9185 8.5 24.25 8.5C24.5816 8.5 24.8995 8.6317 25.1339 8.86612C25.3683 9.10054 25.5 9.41848 25.5 9.75V20Z"
      fill="white"
    />
  </svg>
</template>

<style scoped lang="sass"></style>
