<template>
  <div class="final-screen-circle">
    <div class="final-screen-circle__content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style scoped lang="sass">
@import "@/sass/functions"
.final-screen-circle
  position: absolute
  background-image: url("@/assets/img/final-screen/final-screen-ball.svg")
  background-position: center
  background-size: contain
  transition: all 0.4s
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  &--content
    transition: all 0.4s

  &:hover
    transform: scale(1.13)
</style>
