<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Высокая частота полных ответов была отмечена <br>у пациентов с ФЛ как высокого, так и низкого риска</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-21">
          <img src="@/assets/img/frame-21.png" alt="">
        </div>
        <div class="frame-note large">Budde LE, et al. (2022) The Lancet Oncology, 23(8): 1055-1065 <br>ЧОО - частота объективного ответа; ДИ - доверительный интервал; POD24 - прогрессирование заболевания в течение 24 месяцев с момента начала первой линии терапии
</div>
      </div>
    </div>
    <Pagination :total="15" :page="8" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapseTherapyPage_9",
    components: {
      Pagination,
    }
  }
</script>