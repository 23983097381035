<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">В 3+ линии терапии ФЛ эффективность <br>существующих режимов крайне низкая</h1>
        <p class="text">Систематический обзор литературы и мета-анализ 20 клинических исследований*</p>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-7">
          <img src="@/assets/img/frame-7.png" alt="">
        </div>
        <div class="frame-note">Kanters, S. et al. BMC Cancer 23, 74 (2023) <br>ЧО - частичный ответ; ПО - полный ответ; ОВ - общая выживаемость; ВБП - выживаемость без прогрессирования; ДИ - доверительный интервал <br>*Все пациенты получили ≥2 линий предшествующей терапии, включавшей анти-CD20 и алкилирующий агент (в комбинации или последовательно)</div>
      </div>
    </div>
    <Pagination :total="6" :page="1" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapsePage_1",
    components: {
      Pagination,
    }
  }
</script>