export enum ControlIconNames {
  CameraIcon = "CameraIcon",
  HandIcon = "HandIcon",
  MicrophoneIcon = "MicrophoneIcon",
}

export enum ActiveControlIconNames {
  CameraIconActive = "CameraIconActive",
  HandIconActive = "HandIconActive",
  MicrophoneIconActive = "MicrophoneIconActive",
}
export interface VideoActionProps {
  icon: ControlIconNames;
  text: string;
  activeIcon: ActiveControlIconNames;
  activeText: string;
  disabled?: boolean;
  isSmall?: boolean;
  isActive: boolean;
  cb: () => Promise<void>;
}
