<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Клиническое наблюдение. <br>Женщина, 53 года</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-35">
          <img src="@/assets/img/frame-35.png" alt="">
        </div>
        <div class="frame-note">Собственные данные автора <br>CHOP, циклофосфамид (С), доксорубицин (H), винкристин (O), преднизолон (P); R, ритуксимаб; B,  бендамустин; G, обинутузумаб </div>
      </div>
    </div>
    <Pagination :total="9" :page="5" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "MosunetuzumabPage_5",
    components: {
      Pagination,
    }
  }
</script>