<script setup lang="ts">

</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 7.69419C10 2.99976 3 3.49976 3 9.49979C3 15.4998 12 20.5 12 20.5C12 20.5 21 15.4998 21 9.49979C21 3.49976 14 2.99976 12 7.69419Z" fill="#CE2121"/>
  </svg>
</template>

<style scoped lang="sass">

</style>
