<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Синдром нейротоксичности, ассоциированной <br>с иммунными эффекторными клетками (ICANS)</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-59">
          <img src="@/assets/img/frame-59.png" alt="">
        </div>
        <div class="frame-note large">1.LeeDW, et al. Biol Blood Marrow Transplant 2019;25:625–38;2. Garcia Borrega J,et al. Hemasphere2019;3(2):e191.</div>
      </div>
    </div>
    <Pagination :total="6" :page="2" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "NewClassPage_20",
    components: {
      Pagination,
    }
  }
</script>