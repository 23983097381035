<script setup lang="ts">
import { VideoChatMessageProps } from "@/components/video-conference/types/video-chat-message-props";
import { computed } from "vue";

const props = defineProps<VideoChatMessageProps>();

const title = computed(() => {
  const authorName = props.author.split(" ");
  const person = props.isLocalParticipant
    ? "Вы"
    : `${authorName[0]} ${authorName[1] ? authorName[1][0] : ""}`;

  const time = new Intl.DateTimeFormat("ru-RU", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(props.time);

  return `${person} в ${time}`;
});
</script>

<template>
  <div
    class="video-chat-message"
    :class="{
      'video-chat-message--local': isLocalMessage,
      'video-chat-message--image': !text && image_url,
    }"
  >
    <p class="video-chat-message__title" v-if="!isLocalMessage">
      {{ title }}
    </p>

    <span
      class="video-chat-message__text"
      :class="{ 'video-chat-message__text--current': isLocalParticipant }"
    >
      <img
        v-if="image_url"
        :src="image_url"
        class="video-chat-message__image"
        loading="lazy"
      />

      <template v-if="text">{{ text }} </template>
    </span>
  </div>
</template>

<style scoped lang="sass">
.video-chat-message
  &__image
    width: 100%
  &__title
    margin-bottom: 4px
    color: #9F97A0
    font-family: "Roboto Condensed"
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: 20px

  &__text
    padding: 8px
    font-family: "Roboto Condensed"
    font-size: 16px
    font-style: normal
    font-weight: 400
    line-height: 24px
    letter-spacing: 0.32px
    color: #fff
    border-radius: 8px
    background: #585059
    display: inline-block

    &--current
      background: #7E5093

  &--local
    display: flex
    align-items: center
    justify-content: center

    .video-chat-message__text
      background: none
      color: #9F97A0
      font-family: "Roboto Condensed"
      font-size: 14px
      font-style: normal
      font-weight: 400
      line-height: 16px
      padding: 0

  &--image
    .video-chat-message__text
      padding: 0
      display: flex
      overflow: hidden
      background: none
</style>
