<template>
  <div class="interactive">
    <div class="interactive-title interactive-title-4">ПРАВДА ИЛИ ВЫМЫСЕЛ?</div>
    <div class="task small">
      <div class="task-icon"></div>
      <div class="task-text">
        <p>
          Кликните мышкой на карточки, утверждения на которых Вы считаете ПРАВДИВЫМИ. После выбора карточек нажмите кнопку "ответить".
        </p>
      </div>
    </div>
    <div class="text-cards">
      <div data-aos="zoom-in">
        <div class="text-card number-1" :class="{active: active.includes(data.options[0]?.ident)}" @click="toggleActive(data.options[0]?.ident)">
          <div class="text-card-number">{{data.options[0]?.ident}}</div>
          <div class="text-card-text" v-html="data.options[0]?.title"></div>
        </div>
      </div>
      <div class="text-cards-row number-1">
        <div data-aos="zoom-in" data-aos-delay="300" data-aos-offset="-3000">
          <div class="text-card number-2" :class="{active: active.includes(data.options[1]?.ident)}" @click="toggleActive(data.options[1]?.ident)">
            <div class="text-card-number">{{data.options[1]?.ident}}</div>
            <div class="text-card-text" v-html="data.options[1]?.title"></div>
          </div>
        </div>
        <div data-aos="zoom-in" data-aos-delay="600" data-aos-offset="-3000">
          <div class="text-card number-3" :class="{active: active.includes(data.options[2]?.ident)}" @click="toggleActive(data.options[2]?.ident)">
            <div class="text-card-number">{{data.options[2]?.ident}}</div>
            <div class="text-card-text" v-html="data.options[2]?.title"></div>
          </div>
        </div>
      </div>
      <div class="text-cards-row text-cards-row--2">
        <div data-aos="zoom-in" data-aos-delay="900" data-aos-offset="-3000">
          <div class="text-card number-4" :class="{active: active.includes(data.options[3]?.ident)}" @click="toggleActive(data.options[3]?.ident)">
            <div class="text-card-number">{{data.options[3]?.ident}}</div>
            <div class="text-card-text" v-html="data.options[3]?.title"></div>
          </div>
        </div>
        <div data-aos="zoom-in" data-aos-delay="1200" data-aos-offset="-3000">
          <div class="text-card" :class="{active: active.includes(data.options[4]?.ident)}" @click="toggleActive(data.options[4]?.ident)">
            <div class="text-card-number">{{data.options[4]?.ident}}</div>
            <div class="text-card-text" v-html="data.options[4]?.title"></div>
          </div>
        </div>
      </div>
      <div data-aos="zoom-in" data-aos-delay="1500" data-aos-offset="-3000">
        <div class="text-card number-6" :class="{active: active.includes(data.options[5]?.ident)}" @click="toggleActive(data.options[5]?.ident)">
            <div class="text-card-number">{{data.options[5]?.ident}}</div>
            <div class="text-card-text" v-html="data.options[5]?.title"></div>
        </div>
      </div>
    </div>
    <button type="button" class="interactive-button" @click="submit" v-if="!submited">Ответить</button>
    <button type="button" class="interactive-button button--disabled" v-else>Отправлено</button>

    <div class="interactive-element interactive-element-1"></div>
    <div class="interactive-element interactive-element-4"></div>
    <div class="interactive-element interactive-element-6" data-aos="zoom-in"></div>
    <div class="interactive-element interactive-element-7"></div>
    <div class="information">
      <div class="info-button" @click="openInfo"></div>
      <transition name="slide-fade">
        <div class="info-content" v-if="infoBlock">
          <div class="info-content-close" @click="closeInfo"></div>
          <div class="info-content-row">
            <p>1.</p>
            <p>1. Odutola, M.K. et al (2021). Environmental Research, 197, p.110887; <br>2. Linet MS et al (2014). J. Natl. Cancer Inst. 48: 26-40; <br>3. Mannetje A et al (2016). Environ. Health Perspect., 124: 396-405; <br>4. Casulo C et al (2015). J Clin Oncol. 33(23): 2516-22; <br>5. Dennie TW & Kolesar JM (2009). Clin Ther. 31 Pt 2: 2290-311; <br>6. Besien K & Schouten H (2007). Leukemia & Lymphoma 48(2): 232-243; <br>7. Crouchet al (2022). Blood Advances 6(21): 5716-5731; <br>8. Собственные данные</p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import AOS from 'aos';
import { ymEvent } from "@/utils/ymEvent";
export default {
  name: 'IceBreaker',
  props: {
    data: {
      type: Object,
    }
  },
  data() {
    return {
      active: [],
      submited: false,
      infoBlock: false,
    }
  },
  mounted() {
    AOS.init();
  },
  methods: {
    toggleActive(item) {
      if (!this.submited) {
        if (!this.active.includes(item)) {
          this.active.push(item);
        } else  {
          let index = this.active.indexOf(item);
          if (index !== -1) {
            this.active.splice(index, 1);
          }
        }
      }
    },
    async submit() {
      let answers = [];
      this.active.forEach((item) => {
        answers.push({'ident': item})
      });
      let payload = {
        answers: answers,
        ident: '5_icebreaker'
      }
      await this.$store.dispatch('sendPoll', payload)
      this.submited = true;
    },
    openInfo() {
      this.infoBlock = true;
      this.$emit("openInfo");
      ymEvent('infoandsourcesbutton')
    },
    closeInfo() {
      this.infoBlock = false;
      this.$emit("closeInfo");
    },
  }
}
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px) translateX(-20px);
  opacity: 0;
}
</style>
