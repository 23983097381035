<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title" v-html="data.title"></h1>
      </div>

      <div class="frame-wrapper">
        <div class="content-frame content-frame-18 container">
          <div
            class="frame-results-item column"
            v-for="(item, idx) in answerItems"
            :key="idx"
          >
            <div class="frame-results-item-title">{{ item.title }}</div>
            <div style="display: flex; gap: 10px;">
              <div class="frame-results-item-progress">
                <span :style="{ width: item.value + '%' }"></span>
              </div>
              <div class="frame-results-item-number">{{ item.value }}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Pagination :total="19" :page="5" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
export default {
  name: "RelapseTherapyPagee_6",
  data() {
    return {
      answer: null,
      submited: false,
    };
  },
  components: {
    Pagination,
  },
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    answerItems() {
      const { answers, options } = this.data;
      let totalAnswes = 0;

      Object.values(answers).forEach((el) => {
        totalAnswes += el;
      });

      return options.map((el) => {
        const val = answers[el.ident];

        return {
          title: el.title,
          value: val ? Math.round((val / totalAnswes) * 100) : 0,
        };
      });
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/sass/functions"
.container
  padding: vw(56px)
  flex-direction: column 
</style>