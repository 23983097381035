<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Динамика изменения числа В-лимфоцитов <br>во время монотерапии мосунетузумабом</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-30">
          <img src="@/assets/img/frame-30.png" alt="">
        </div>
        <div class="frame-note large">Schuster SJ et al. ASH 2023. Abstract 603 & presentation</div>
      </div>
    </div>
    <Pagination :total="15" :page="18" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapseTherapyPage_18",
    components: {
      Pagination,
    }
  }
</script>