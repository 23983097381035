<template>
  <div class="login-form">
    <div class="login-form-close" @click="emit('close')"></div>
    <div class="login-form-content">
      <h2 class="login-form-title">Как к Вам обращаться?</h2>
      <div class="login-form-field">
        <input
          type="text"
          placeholder="ФИО"
          class="login-form-control"
          disabled
          :value="modelValue"
          @input="emit('update:modelValue', $event.target?.value)"
        />
      </div>
      <div class="login-form-field">
        <div class="radio radio--light">
          <base-checkbox
            v-model="isDoctor"
            label="Чтобы авторизоваться, необходимо подтвердить, что Вы являетесь специалистом здравоохранения."
          >
          </base-checkbox>
        </div>
      </div>
      <div class="login-form-field">
        <div class="radio radio--light">
          <base-checkbox
            label="Я даю свое согласие на обработку персональных данных<br /><a href='https://www.roche.ru/deklaraciya-o-zashite-dannyh' target='_blank'>(соглашение)</a>"
            v-model="isAgreement"
          >
          </base-checkbox>
        </div>
      </div>
      <button
        type="button"
        class="login-form-button"
        @click="onLogin"
        :disabled="isDisabled"
      >
        Войти
        <video-loader class="loader" v-if="isLoading" />
      </button>
      <div class="login-form-note">
        Нажимая на кнопку «войти», Вы принимаете <a href="https://www.roche.ru/konfidencialnost" target="_blank">условия пользования веб-сайтом</a>. 
      </div>
      <div class="login-form-bottom login-form-note">
        Информация на данном веб-сайте предназначена исключительно для медицинских работников. Сайт предназначен только для посетителей из Российской Федерации. M-RU-00015465 февраль 2024
      </div>
      <div class="login-form-element login-form-element-1"></div>
      <div class="login-form-element login-form-element-2"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import BaseCheckbox from "./BaseCheckbox.vue";
import VideoLoader from "./video-conference/VideoLoader.vue";
import { useLogin } from "@/composables/use-login";
import { ymEvent } from "@/utils/ymEvent";
const props = defineProps<{ modelValue: string }>();
const emit = defineEmits(["update:modelValue", "continue", "close"]);
const isAgreement = ref(false);
const isDoctor = ref(false);
const { getUserData } = useLogin();
const isLoading = ref(false);

const isDisabled = computed(() => {
  return (
    !isAgreement.value ||
    !isDoctor.value ||
    !props.modelValue ||
    isLoading.value
  );
});
const onLogin = async () => {
  if (isDisabled.value) {
    return;
  }
  isLoading.value = true;
  await getUserData();
  isLoading.value = false;

  ymEvent('enterbuttonfirstscreen')
  emit("continue");
};
</script>

<style lang="sass" scoped>
@import "../sass/functions.sass"

.login-form-button
  position: relative

.loader
  position: absolute
  top: 16%
  left: 43%
</style>
