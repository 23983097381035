<template>
  <div class="interactive">
    <div class="interactive-title interactive-title-2 pinky ">
      <span class="stroke-1"><span class="space">за</span> предел<span class="accent-letter">а</span>ми</span>
      <br><span class="stroke-2">во<span class="accent-letter">о</span>бражения</span>
    </div>
    <div class="video-frame">
      <video id="video" class="video-js">
        <source :src="require('@/assets/video/bg_3.mp4')">
      </video>
      <div class="video-frame-button" v-if="isShowPlayButton">
        <div class="video-frame-button-icon"></div>
        <div class="video-frame-button-text">Смотреть видео</div>
      </div>
    </div>
    <div class="interactive-element interactive-element-1"></div>
    <div class="interactive-element interactive-element-2"></div>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css';
export default {
  name: 'MoodVideo',
  data() {
    return {
      player: null,
      isShowPlayButton: true,
    }
  },
  mounted() {
    this.player = videojs('video', {});
  },
  methods: {
    playVideo() {
      this.isShowPlayButton = false;
      this.player.play();
    }
  }
}
</script>