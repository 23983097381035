<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Основные цели терапии рецидивов ФЛ</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-13">
          <img src="@/assets/img/frame-13.png" alt="">
        </div>
        <div class="frame-notes frame-notes--2">
          <div class="frame-note large">*указаны данные 2 наиболее часто выбираемых ответов для каждой линии терапии <br> <br>Собственные данные автора. Результаты опроса специалистов здравоохранения РФ (n=130). Март 2023 год</div>
        </div>
      </div>
    </div>
    <Pagination :total="17" :page="1" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapseTherapyPage_1",
    components: {
      Pagination,
    }
  }
</script>