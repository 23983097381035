<template>
  <a
    class="final-screen-link"
    href="https://250924-fl-event-bispecific-feedback.testograf.ru/"
    target="_blank"
    @click="openLink"
  >
    <div class="final-screen-link__body">
      Поделитесь обратной <br />
      связью
    </div>
  </a>
</template>

<script lang="ts">
  import { ymEvent } from "@/utils/ymEvent";
  export default {
    name: 'FinalScreenLink',
    methods: {
      openLink() {
        ymEvent('feedbackbutton')
      }
    }
  }
</script>

<style lang="sass" scoped>
@import "@/sass/functions"
.final-screen-link
    position: absolute
    left: vw(48px)
    bottom: vw(48px)
    background: rgba(62, 209, 38, 1)
    box-shadow: 0px vw(16px) vw(36px) 0px rgba(62, 209, 38, 1)
    padding: vw(15px) vw(18px)
    display: block
    border-radius: vw(60px)
    text-decoration: none
    z-index: 10

    &__body
        background: rgba(53, 188, 31, 1)
        color: #fff
        font-size: vw(32px)
        text-decoration: none
        font-weight: 400
        line-height: vw(38px)
        letter-spacing: 0.01em
        text-align: center
        padding: vw(36px) vw(18px)
        border-radius: vw(45px)
        width: vw(340px)
        height: vw(144px)
</style>
