<script lang="ts" setup>
withDefaults(
  defineProps<{
    modelValue: boolean;
    label: string;
    isLight?: boolean;
  }>(),
  {
    isLight: true,
  }
);
const emit = defineEmits(["update:modelValue"]);
const onChange = (event: any) =>
  emit("update:modelValue", event.target.checked);
</script>

<template>
  <div
    class="radio"
    :class="{
      'radio--light': isLight,
      'radio--dark': !isLight,
    }"
  >
    <label class="radio-container">
      <p v-html="label"></p>
      <input
        type="checkbox"
        name="confirm"
        :checked="modelValue"
        @change="onChange"
      />
      <span class="radio-checkmark"></span>
    </label>
  </div>
</template>
