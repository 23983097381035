<template>
  <div class="page background-2">
    <div class="page-wrapper">
      <div class="page-head mt-20">
        <h1 class="title full mr-15">Как выглядят пациенты с ФЛ, <br>которые нуждаются в 3 линии терапии?</h1>
      </div>
      <div class="page-content">
        <div class="cards">
          <div class="cards__names">
            <div class="cards__names-item">
              <div class="cards__names-item-icon woman"></div>
              <div class="cards__names-item-text">Женский пол</div>
            </div>
            <div class="cards__names-item">
              <div class="cards__names-item-icon age"></div>
              <div class="cards__names-item-text">Возраст</div>
            </div>
            <div class="cards__names-item">
              <div class="cards__names-item-icon time"></div>
              <div class="cards__names-item-text">Время от диагноза до третьей <br>линии терапии, месяцы</div>
            </div>
            <div class="cards__names-item">
              <div class="cards__names-item-icon runner"></div>
              <div class="cards__names-item-text">Доля пациентов <br>с POD24 <br>в анамнезе</div>
            </div>
            <div class="cards__names-item">
              <div class="cards__names-item-icon graph"></div>
              <div class="cards__names-item-text">Доля пациентов <br>c двойной <br>рефрактерностью</div>
            </div>
          </div>
          <div class="card active">
            <h2 class="card-title">Мнение аудитории</h2>
            <div class="card-content">
              <div class="card-item">
                <!-- <p class="card-label">Пол</p> -->
                <div class="card-row">
                  <div class="card-value">
                    <!-- <div class="card-icon woman"></div> -->
                    <p class="card-text"><strong>{{Math.round(data.gender_female*100)}}</strong> %</p>
                  </div>
                </div>
              </div>
              <div class="card-item" v-if="data.age">
                <!-- <p class="card-label">Возраст</p> -->
                <div class="card-value">
                  <p class="card-text"><strong class="large">{{Number(data.age.avg.toFixed(1))}}</strong> лет</p>
                </div>
              </div>
              <div class="card-item" v-if="data.ecog">
                <!-- <p class="card-label">ECOG</p> -->
                <div class="card-row">
                  <div class="card-value">
                    <p class="card-text"><strong>II {{Math.round(data.ecog[0]*100)}}</strong> %</p>
                  </div>
                  <div class="card-value">
                    <p class="card-text"><strong>I {{Math.round(data.ecog[1]*100)}}</strong> %</p>
                  </div>
                  <div class="card-value">
                    <p class="card-text"><strong>III {{Math.round(data.ecog[2]*100)}}</strong> %</p>
                  </div>
                  <div class="card-value">
                    <p class="card-text"><strong>VI {{Math.round(data.ecog[3]*100)}}</strong> %</p>
                  </div>
                </div>
              </div>
              <div class="card-item">
                <!-- <p class="card-label">Время до третьей <br>линии терапии, медиана</p> -->
                <div class="card-value">
                  <p class="card-text"><strong class="large">{{ Number(data.third_line.avg.toFixed(1)) }}</strong> мес</p>
                </div>
              </div>
              <div class="card-item">
                <!-- <p class="card-label">Доля пациентов с POD24 <br>в анамнезе</p> -->
                <div class="card-value">
                  <p class="card-text"><strong class="large">{{ Number(data.pod_24.avg.toFixed(1)) }}</strong> %</p>
                </div>
              </div>
              <div class="card-item">
                <!-- <p class="card-label">Доля пациентов c двойной <br>рефрактерностью</p> -->
                <div class="card-value">
                  <p class="card-text"><strong class="large">{{ Number(data.double_ref.avg.toFixed(1)) }}</strong> %</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <h2 class="card-title">Реальная практика в РФ<small>1</small></h2>
            <div class="card-content">
              <div class="card-item">
                <!-- <p class="card-label">Пол</p> -->
                <div class="card-row">
                  <div class="card-value">
                    <!-- <div class="card-icon woman"></div> -->
                    <p class="card-text"><strong>63</strong> %</p>
                  </div>
                </div>
              </div>
              <div class="card-item">
                <!-- <p class="card-label">Возраст</p> -->
                <div class="card-value">
                  <p class="card-text"><strong class="large">60</strong> лет<small>†,ⱡ</small></p>
                </div>
              </div>
              <div class="card-item">
                <!-- <p class="card-label">Время до третьей <br>линии терапии, медиана</p> -->
                <div class="card-value">
                  <p class="card-text"><strong class="large">27</strong> мес.<small>†</small></p>
                </div>
              </div>
              <div class="card-item">
                <!-- <p class="card-label">Доля пациентов с POD24 <br>в анамнезе</p> -->
                <div class="card-value">
                  <p class="card-text"><strong class="large">76</strong> %</p>
                </div>
              </div>
              <div class="card-item">
                <!-- <p class="card-label">Доля пациентов c двойной <br>рефрактерностью</p> -->
                <div class="card-value">
                  <p class="card-text"><strong class="large">33</strong> %<small>ⱡ</small></p>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <h2 class="card-title">Реальная практика в мире<small>1-5*</small></h2>
            <div class="card-content">
              <div class="card-item">
                <!-- <p class="card-label">Пол<small>1,4</small></p> -->
                <div class="card-row">
                  <div class="card-value">
                    <!-- <div class="card-icon woman"></div> -->
                    <p class="card-text"><strong>41-52</strong> %<small>1-4</small></p>
                  </div>
                </div>
              </div>
              <div class="card-item">
                <!-- <p class="card-label">Возраст</p> -->
                <div class="card-value">
                  <p class="card-text"><strong class="large">58 — 70</strong> лет<small>1-4,†,ⱡ</small></p>
                </div>
              </div>
              <div class="card-item">
                <!-- <p class="card-label">Время до третьей <br>линии терапии, медиана</p> -->
                <div class="card-value">
                  <p class="card-text"><strong class="large">26 — 52</strong> мес.<small>1-3,5,†</small></p>
                </div>
              </div>
              <div class="card-item">
                <!-- <p class="card-label">Доля пациентов с POD24 <br>в анамнезе</p> -->
                <div class="card-value">
                  <p class="card-text"><strong class="large">44 — 76</strong> %<small>1-4</small></p>
                </div>
              </div>
              <div class="card-item">
                <!-- <p class="card-label">Доля пациентов c двойной <br>рефрактерностью</p> -->
                <div class="card-value">
                  <p class="card-text"><strong class="large">56 — 64</strong> %<small>1,3,ⱡ</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cards-notes">
          <p class="card-note">POD24 – прогрессирование заболевание в течение 2 лет от начала <br>первой линии иммунохимиотерапии ФЛ; <br>двойная рефрактерность – рефрактерность к анти-CD20 и <br>алкилирующему агенту</p>
          <p class="card-note">1. Собственные данные автора. Дневниковое исследование <br>пациентов с ФЛ, получивших ≥ 3 линии терапии (n=224) <br>†медиана; ⱡна начало 3 линии терапии;</p>
          <p class="card-note small">1.Casulo eta l. Lancet Haematol. 2022 April ; 9(4): e289–e300; 2.Jamie <br>T.Ta et al (2021). Blood 138. Suppl1, p 4080; 3. Salles et al. Hemasphere. <br>2022 Jun 21;6(7):e745; 4. S.Fuji, Y. Tada, K. Nozaki et al (2020).Annals of <br>Hematology, vol. 99, p. 2133–2139; 5. Huntington SF et al. HealthEcon <br>Outcomes Res. 2022 Oct 24;9(2):115-122 <br><br>*Данные анализов реальной практики 3+ линии терапии ФЛ в США, <br>Европе и Японии. †медиана; ⱡна начало 3+ линии терапии; </p>
        </div>
      </div>
    </div>
    <!-- <div class="live-frame"></div> -->
  </div>
</template>

<script>
import 'nouislider/dist/nouislider.css';
  export default {
    name: "PatientResult",
    props: {
      data: {
        type: Object,
      }
    }
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
@import "../sass/styles.sass"
</style>