<script setup lang="ts">

</script>

<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28 12L20 12V4M12 4V12L4 12M4 20L12 20L12 28M20 28V20L28 20" stroke="white" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<style scoped lang="sass">

</style>
