<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.5 8C3.39543 8 2.5 8.89543 2.5 10V22C2.5 23.1046 3.39543 24 4.5 24H19.5C20.6046 24 21.5 23.1046 21.5 22V10C21.5 8.89543 20.6046 8 19.5 8H4.5ZM27.9188 9.12946L22.5 13V19L27.9188 22.8705C28.5806 23.3433 29.5 22.8702 29.5 22.0568V9.94319C29.5 9.12982 28.5806 8.65669 27.9188 9.12946Z"
      fill="white"
    />
  </svg>
</template>
