<template>
  <FinalScreenCircle
    class="third-circle"
    @click="emit('next')"
    :class="{ 'third-circle--blured': blured }"
  >
    <div class="third-circle__body">
      <svg
        class="third-circle__number"
        width="127"
        height="61"
        viewBox="0 0 127 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.8652 37.3035L42.3859 30.94C49.991 27.8359 56.5873 31.5608 56.5873 38.6227C56.5873 52.7466 39.6697 60.7397 19.8033 60.7397C5.91229 60.7397 1.17849 57.558 1.17849 52.669C1.17849 50.1856 2.49774 47.8575 8.31799 48.4008L42.4635 51.4273C46.9645 51.8153 48.9822 49.72 48.9822 47.5471C48.9822 45.2966 47.1973 43.7446 42.4635 43.1237L14.6815 39.7092C8.78362 39.0108 6.76593 35.441 6.76593 31.716C6.76593 28.8447 7.85237 25.1974 14.6815 23.0245L44.9468 13.4017C48.5941 12.2376 49.215 10.6855 49.215 8.74545C49.1374 6.26214 46.8093 5.87412 44.8692 6.41735L17.7856 14.4105C12.2758 16.0402 1.87692 19.6099 1.25609 9.13347C0.868073 2.9252 5.91229 0.519493 12.3534 0.519493H41.1442C49.3702 0.519493 53.4831 4.32206 53.4831 9.44388C53.4831 12.3152 51.4655 15.1865 47.9733 17.6698L24.8475 33.7337C21.7434 35.9066 23.2954 38.7779 26.8652 37.3035ZM96.6567 37.3035L112.177 30.94C119.782 27.8359 126.379 31.5608 126.379 38.6227C126.379 52.7466 109.461 60.7397 89.5948 60.7397C75.7038 60.7397 70.97 57.558 70.97 52.669C70.97 50.1856 72.2892 47.8575 78.1095 48.4008L112.255 51.4273C116.756 51.8153 118.774 49.72 118.774 47.5471C118.774 45.2966 116.989 43.7446 112.255 43.1237L84.473 39.7092C78.5751 39.0108 76.5574 35.441 76.5574 31.716C76.5574 28.8447 77.6439 25.1974 84.473 23.0245L114.738 13.4017C118.386 12.2376 119.006 10.6855 119.006 8.74545C118.929 6.26214 116.601 5.87412 114.661 6.41735L87.5771 14.4105C82.0673 16.0402 71.6684 19.6099 71.0476 9.13347C70.6596 2.9252 75.7038 0.519493 82.1449 0.519493H110.936C119.162 0.519493 123.275 4.32206 123.275 9.44388C123.275 12.3152 121.257 15.1865 117.765 17.6698L94.639 33.7337C91.5349 35.9066 93.0869 38.7779 96.6567 37.3035Z"
          fill="white"
        />
      </svg>

      <svg
        class="third-circle__plus"
        width="68"
        height="68"
        viewBox="0 0 68 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        v-if="!blured"
      >
        <path
          d="M2 33.377L66.0046 34.6239"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
        />
        <path
          d="M33.3711 66.0039L34.618 1.99935"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
        />
      </svg>
    </div>
  </FinalScreenCircle>
</template>

<script setup lang="ts">
import FinalScreenCircle from "./FinalScreenCircle.vue";

const emit = defineEmits(["next"]);
defineProps<{ blured: boolean }>();
</script>

<style scoped lang="sass">
@import "@/sass/functions"

.third-circle
  width: vw(326px)
  height: vw(326px)

  &__body
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
  &__number
    margin-bottom: vw(10px)
    width: vw(127px)
    height: vw(61px)

  &--blured
    pointer-events: none

  &__plus
    width: vw(68px)
    height: vw(68px)
</style>
