<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import VideoChatMessage from "@/components/video-conference/VideoChatMessage.vue";
import ChevronIcon from "@/components/video-conference/icons/ChevronIcon.vue";
import useDataStorage from "@/components/video-conference/composables/use-data-storage";
import VideoSubmitIcon from "@/components/video-conference/icons/VideoSubmitIcon.vue";
import axios from "axios";
import { useStore } from "vuex";
import VideoLoader from "@/components/video-conference/VideoLoader.vue";
import VideoFadeTransition from "@/components/video-conference/VideoFadeTransition.vue";
import ClearIcon from "@/components/video-conference/icons/ClearIcon.vue";
import { ymEvent } from "@/utils/ymEvent";

const props = defineProps<{
  isMini: boolean;
  isChatOpen: boolean;
  isSpectator: boolean;
}>();

const { fullChatMessages } = useDataStorage();
const store = useStore();

const emit = defineEmits(["setChatOpen", "sendMessage", "sendLocalMessage"]);
const messagesContainer = ref<HTMLElement | null>(null);

const currText = ref("");
const imageUrl = ref("");

const isImageUpload = ref(false);
const clear = () => {
  imageUrl.value = "";
  currText.value = "";
};
const enterText = () => {
  if (!currText.value && !imageUrl.value) return;

  const text = currText.value;
  let result: string | { msg: string; image_url: string } = text;

  if (imageUrl.value) {
    result = {
      msg: text,
      image_url: imageUrl.value,
    };
  }

  emit("sendMessage", result);
  clear();
  ymEvent('buttonsentinthechat');
};

const handleChatToggle = () => {
  if (props.isChatOpen) {
    ymEvent('hidechatbutton');
  }
  emit('setChatOpen', !props.isChatOpen);
};

watch(
  () => props.isMini,
  () => {
    emit("setChatOpen", true);
  }
);

watch(fullChatMessages, async () => {
  await nextTick();

  setTimeout(() => {
    if (!messagesContainer.value) return;

    messagesContainer.value.scroll({
      top: messagesContainer.value.scrollHeight,
      behavior: "smooth",
    });
  }, 500);
});

watch(
  () => props.isChatOpen,
  async () => {
    if (!props.isChatOpen) {
      return;
    }

    await nextTick();

    if (!messagesContainer.value) return;

    messagesContainer.value.scroll({
      top: messagesContainer.value.scrollHeight,
    });
  }
);

const onLoad = async (e: Event) => {
  const files = (e.target as HTMLInputElement).files;

  if (!files) {
    return;
  }

  const body = new FormData();
  body.append("image", files[0]);
  isImageUpload.value = true;

  try {
    let res = await axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/api/upload`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );

    imageUrl.value = `${process.env.VUE_APP_BASE_API_URL}/api/data/${res.data}`;
    emit("sendLocalMessage", "Изображение прикреплено.");
  } catch (err) {
    emit("sendLocalMessage", "Произошла ошибка при загрузке изображения.");
    console.error(err);
  } finally {
    isImageUpload.value = false;
  }
};
const miniChatText = computed(() => {
  return props.isChatOpen ? "Скрыть чат" : "Показать чат";
});
</script>

<template>
  <div
    class="video-chat"
    :class="{ 'video-chat--mini': isMini, 'video-chat--open': isChatOpen }"
  >
    <button
      v-if="isMini"
      class="video-chat__toggle"
      @click="handleChatToggle"
    >
      <span>
        {{ miniChatText }}
      </span>

      <chevron-icon class="video-chat__toggle-icon" />
    </button>

    <div class="video-chat__container" v-if="isChatOpen">
      <div
        class="video-chat__messages"
        ref="messagesContainer"
        :class="{ 'video-chat__messages--spectator': isSpectator }"
      >
        <video-chat-message
          class="video-chat__message"
          v-for="(m, idx) in fullChatMessages"
          :key="idx"
          v-bind="m"
        />
      </div>

      <form
        @submit.prevent="enterText"
        class="video-chat__form"
        v-if="!isSpectator"
      >
        <input
          v-model="currText"
          class="video-chat__input"
          placeholder="Введите сообщение..."
          @keyup.enter="enterText"
        />

        <input
          type="file"
          name="file"
          id="file"
          accept="image/*"
          class="video-chat__form-attachment"
          @change.stop="onLoad"
        />
        <label for="file"></label>
        <video-fade-transition>
          <button
            v-if="imageUrl || currText"
            type="button"
            class="video-chat__clear"
            @click.prevent="clear"
          >
            <clear-icon />
          </button>
        </video-fade-transition>
        <button
          type="submit"
          class="video-chat__submit"
          :disabled="isImageUpload"
        >
          <video-submit-icon />
          <video-loader v-if="isImageUpload" class="video-chat__loader" />
        </button>
      </form>
    </div>
  </div>
</template>

<style scoped lang="sass">
.video-chat
  background: #1D191E
  position: relative
  flex-shrink: 0

  &__container
    height: 100%

  &__form
    display: flex
    gap: 8px
    position: absolute
    left: 16px
    right: 16px
    bottom: 16px

    &-attachment
      display: none

      & + label
        all: unset
        position: absolute
        top: 8px
        left: 8px
        width: 40px
        height: 40px
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer
        background-size: 24px
        background: url('@/assets/img/video-attachment.svg') no-repeat center


  &__message
    margin-bottom: 30px

    &:first-child
      margin-top: 16px

    &:last-child
      margin-bottom: 90px

  &__submit
    all: unset
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center
    padding: 12px
    background: #8B15C2
    border-radius: 8px
    position: relative

  &__loader
    position: absolute
  &__clear
    cursor: pointer
    position: absolute
    right: 72px
    top: 50%
    transform: translateY(-50%)
    padding: 8px
    border: none
    background: none
    display: flex
    justify-content: center
    align-items: center
  &__messages
    height: 100%
    overflow: auto
    padding: 0 16px
    &::-webkit-scrollbar
      width: 5px
      height: 5px

    &::-webkit-scrollbar-track
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
      -webkit-border-radius: 10px
      border-radius: 10px


    &::-webkit-scrollbar-thumb
      -webkit-border-radius: 10px
      border-radius: 10px
      background: rgba(255, 255, 255, 0.4)
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4)

    &::-webkit-scrollbar-thumb:window-inactive
      background: rgba(255, 255, 255, 0.4)

    &--spectator
      height: 100%

      .video-chat__message
        margin-bottom: 16px


  &__input
    width: 100%
    padding: 16px 48px
    outline: none
    border-radius: 8px
    color: #1D191E
    font-family: "Roboto Condensed"
    font-size: 15px
    font-style: normal
    font-weight: 400
    line-height: 22px
    letter-spacing: 0.3px
    border: none

    &::placeholder
      font-family: "Roboto Condensed"
      font-size: 15px
      font-style: normal
      font-weight: 400
      line-height: 22px
      letter-spacing: 0.3px
      color: #9F97A0

  &__toggle
    all: unset
    cursor: pointer
    width: 100%
    padding: 8px 0px
    background: #110F11
    font-family: "Roboto Condensed"
    font-size: 15px
    font-style: normal
    font-weight: 400
    line-height: 22px
    letter-spacing: 0.3px
    color: #fff
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center

    &-icon
      margin-left: 8px
      transition: transform 0.2s linear

    &:hover
      background: #2B272C

    &:active
      background: #0A080A

  &--mini
    padding-top: 0

  &--open
    height: 100%

    .video-chat__toggle-icon
      transform: rotate(180deg)

.video-chat--mini.video-chat--open
  .video-chat__messages
    height: calc(100% - 40px)

  .video-chat__container
    height: 100%
</style>
