<script setup lang="ts">
import ChevronIconLeft from "@/components/video-conference/icons/ChevronIconLeft.vue";
import { computed } from "vue";

const props = defineProps<{ currentPage: number; amountOfPages: number }>();
const emit = defineEmits(["update:currentPage"]);

const isLeftButtonDisabled = computed(() => props.currentPage === 0);
const isRightButtonDisabled = computed(
  () => props.currentPage === props.amountOfPages - 1
);

const onLeftClick = () => {
  if (isLeftButtonDisabled.value) return;

  emit("update:currentPage", props.currentPage - 1);
};

const onRightClick = () => {
  if (isRightButtonDisabled.value) return;

  emit("update:currentPage", props.currentPage + 1);
};
</script>

<template>
  <button
    class="video-button video-pagination__left"
    :disabled="isLeftButtonDisabled"
    @click="onLeftClick"
  >
    <chevron-icon-left />
  </button>

  <div class="video-pagination__dots">
    <button
      v-for="i in amountOfPages"
      :key="i"
      @click="emit('update:currentPage', i - 1)"
      class="video-pagination__dots-item"
      :class="{ 'video-pagination__dots-item__active': currentPage === i - 1 }"
    ></button>
  </div>
  <button
    class="video-button video-pagination__right"
    :disabled="isRightButtonDisabled"
    @click="onRightClick"
  >
    <chevron-icon-left class="video-pagination__chevron" />
  </button>
</template>

<style scoped lang="sass">
@import "@/sass/video-button"

.video-pagination
  &__left, &__right
    position: absolute
    top: 50%
    z-index: 1
    padding: 16px
    border-radius: 50%
    transform: translateY(-50%)

  &__left
    left: 24px

  &__right
    right: 24px

  &__chevron
    transform: rotate(180deg)

  &__dots
    position: absolute
    bottom: 24px
    padding: 6px 8px
    background: rgba(29, 25, 30, 0.60)
    border-radius: 100px
    display: flex
    align-items: center
    left: 50%
    transform: translateX(-50%)

    &-item
      all: unset
      cursor: pointer
      width: 8px
      height: 8px
      margin-right: 8px
      border-radius: 50%
      background: rgba(255, 255, 255, 0.50)

      &:last-child
        margin-right: 0

      &__active
        background: rgba(255, 255, 255, 1)
        width: 12px
        height: 12px
</style>
