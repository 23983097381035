<script setup lang="ts">
import { AcknowledgeUser } from "@/types/acknowledge-user";
import VideoFadeTransition from "@/components/video-conference/VideoFadeTransition.vue";

defineProps<{ users: AcknowledgeUser[] }>();

const getFullName = (name: string) => {
  const [a, b, c] = name.split(" ");

  if (b) {
    if (c) {
      return `${a}<br>${b} ${c}`;
    }

    return `${a}<br>${b}`;
  }

  return a;
};

const symbolToShow = (identity: string) => {
  const [first, second] = identity.split(" ");

  return (first[0] + (second ? second[0] : "")).toUpperCase();
};
</script>

<template>
  <div
    v-for="user in users"
    :key="user.fullname"
    class="name-card"
    :class="user.acknowledged_at ? 'white' : 'light-white'"
  >
    <div class="name-card-avatar">
      <img v-if="user.avatar_url" :src="user.avatar_url" alt="" />
      <template v-else>
        {{ symbolToShow(user.fullname) }}
      </template>
    </div>
    <div class="name-card-content">
      <div class="name-card-title" v-html="getFullName(user.fullname)"></div>
      <div class="name-card-text">{{ user.details }}</div>
    </div>

    <video-fade-transition>
      <img
        src="@/assets/img/roll-call-connected.png"
        class="name-card-icon"
        alt=""
        v-if="user.acknowledged_at"
      />
    </video-fade-transition>
  </div>
</template>

<style scoped lang="sass"></style>
