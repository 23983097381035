import { Chart, ChartEvent } from "chart.js";

export const renderCrosshair = (event: ChartEvent, chart: Chart, isFinal: boolean) => {

  if (event.type === "click") {
    return;
  }

  const { x, y } = event;
  const canvas = chart.canvas;
  const context = canvas.getContext("2d");

  const { left, top, right, bottom } = chart.chartArea;

  if (!context) {
    return;
  }

  if (x === null || y === null) {
    return;
  }
  
  const scale = window.devicePixelRatio
  context.clearRect(0, 0, canvas.width / scale, canvas.height / scale);

  if (isFinal) return

  const padding = 10;

  context.beginPath();
  context.moveTo(left, y);
  context.lineTo(x - padding, y);
  context.moveTo(x, top);
  context.lineTo(x, y - padding);
  context?.moveTo(right, y);
  context.lineTo(x + padding, y);
  context.moveTo(x, bottom);
  context.lineTo(x, y + padding);
  context.strokeStyle = "#FFD15B";
  context.lineWidth = 2;
  context.stroke();
  context.closePath();
};
