<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Популяция пациентов, включенная в исследование, <br>отражает профиль кандидата на 3 линию терапии ФЛ <br>в реальной практике</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-19">
          <img src="@/assets/img/frame-19.png" alt="">
        </div>
        <div class="frame-note large">Budde LE, et al. (2022) The Lancet Oncology, 23(8): 1055-1065 <br>POD24 - прогрессирование заболевания в течение 24 месяцев с начала первой линии иммунохимиотерапии; ауто-ТГСК - аутологичная трансплантация гемопоэтических стволовых клеток</div>
      </div>
    </div>
    <Pagination :total="15" :page="6" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapseTherapyPage_7",
    components: {
      Pagination,
    }
  }
</script>