import { createStore } from "vuex";
import axios from "axios";
import { Room, RoomEvent, DataPacket_Kind } from "livekit-client";

const store = createStore({
  state() {
    return {
      token: "",
      patient: null,
      user: null,
      room: null,
      metadata: null,
    };
  },
  getters: {
    active: (state) => state.metadata?.presentation_block,
    data: (state) => state.metadata?.presentation_data,
    room: (state) => state.room,
    acknowledgedUsers: (state) => {
      return state.metadata?.acknowledged_users || [];
    },
  },
  actions: {
    async initRoom({ state, commit }) {
      const room = new Room();
      commit("SET_ROOM", room);

      const livekitUrl = process.env.VUE_APP_LIVEKIT_URL || "";

      try {
        await room.prepareConnection(livekitUrl, state.user.token);
        await room.connect(livekitUrl, state.user.token);
      } catch (error) {
        console.log(error);
      }

      if (room.metadata) {
        commit("SET_META", room.metadata);
      }

      room.on(RoomEvent.RoomMetadataChanged, (metadata) => {
        console.log(metadata);
        commit("SET_META", metadata);
      });
    },
    async getUnknownUser({ commit }) {
      let user = await axios.get(
        `${process.env.VUE_APP_BASE_API_URL}/api/acquire-spectator-token`
      );

      commit("SET_USER", { token: user.data });
    },
    setPageByUser({ state }, id) {
      console.log(id)
      const encoder = new TextEncoder();
      let msg = encoder.encode(id)
      state.room.localParticipant.publishData(msg, DataPacket_Kind.RELIABLE, {
        topic: 'presenter',
      });
    },
    async getUser({ commit, state }) {
      let user = await axios.get(
        `${process.env.VUE_APP_BASE_API_URL}/api/invite`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      commit("SET_USER", user.data);
      return user.data;
    },

    async setPatient({ commit, state }, payload) {
      let patient = await axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/patient`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      commit("SET_PATIENT", patient.data);
    },

    async sendPoll({ state }, payload) {
      await axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/polls/${payload.ident}/answer`,
        payload.answers,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
    },
  },
  mutations: {
    SET_PATIENT(state, patient) {
      state.patient = patient;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_ROOM(state, room) {
      state.room = room;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_META(state, meta) {
      try {
        state.metadata = JSON.parse(meta);
      } catch (e) {
        console.error(e);
      }
    },
  },
});

export default store;
