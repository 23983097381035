import { Point } from "chart.js";

export const getResearchDataset = () => {
  return {
    id: "ResearchLine",
    data: [] as Point[],
    borderColor: "#fff",
    borderWidth: 3,
    borderRadius: 0,
    pointRadius: 0,
    pointHitRadius: 3,
  };
};

export const getCollectiveLindeDataSet = () => {
  return {
    id: "CollectiveLine",
    data: [] as Point[],
    borderColor: "#C1C1C1",
    borderRadius: 0,
    pointRadius: 0,
    borderWidth: 1,
    pointHitRadius: 3,
  };
};

export const getMyLineDataSet = () => {
  return {
    id: "MyLine",
    data: [] as Point[],
    borderColor: "#FFEF5F",
    borderRadius: 0,
    pointRadius: 0,
    borderWidth: 2,
    pointHitRadius: 3,
  };
};

export const getMediumDataset = (p: Point) => {
  return {
    id: "dashedLine",
    data: [{ x: -5, y: 0.5 }, p, { x: p.x, y: -1 }],
    borderColor: "rgb(232, 184, 255)",
    animation: {
      numbers: { duration: 0 },
      color: { duration: 0 },
    },
    pointRadius: [0, 5, 0],
    pointBorderColor: "#fff",
    pointBackgroundColor: "#fff",
    borderDash: [10, 10],
  };
};
