<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Насколько важно стремиться к достижению<br>полного ответа в 3+ линии терапии ФЛ?</h1>
        <div class="subtitle subtitle--cards">Отметьте от 1 до 10, где 1 – совсем не важно, 10 – это крайне важно</div>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-65">
          <div class="content-frame__wrapper content-frame__wrapper--results">
            <div class="content-frame__columns">
              <div class="content-frame__column">
                <div class="subtitle__label">12%</div>
                <div class="content-frame__column-body">
                  <div class="content-frame__column-grade" style="height: 20%;"></div>
                </div>
                1
              </div>
              <div class="content-frame__column">
                <div class="subtitle__label">12%</div>
                <div class="content-frame__column-body">
                  <div class="content-frame__column-grade" style="height: 40%;"></div>
                </div>
                2
              </div>
              <div class="content-frame__column">
                <div class="subtitle__label">12%</div>
                <div class="content-frame__column-body">
                  <div class="content-frame__column-grade" style="height: 60%;"></div>
                </div>
                3
              </div>
              <div class="content-frame__column">
                <div class="subtitle__label">12%</div>
                <div class="content-frame__column-body">
                  <div class="content-frame__column-grade" style="height: 10%;"></div>
                </div>
                4
              </div>
              <div class="content-frame__column">
                <div class="subtitle__label">12%</div>
                <div class="content-frame__column-body">
                  <div class="content-frame__column-grade" style="height: 50%;"></div>
                </div>
                5
              </div>
              <div class="content-frame__column">
                <div class="subtitle__label">12%</div>
                <div class="content-frame__column-body">
                  <div class="content-frame__column-grade" style="height: 8%;"></div>
                </div>
                6
              </div>
              <div class="content-frame__column">
                <div class="subtitle__label">12%</div>
                <div class="content-frame__column-body">
                  <div class="content-frame__column-grade" style="height: 11%;"></div>
                </div>
                7
              </div>
              <div class="content-frame__column">
                <div class="subtitle__label">12%</div>
                <div class="content-frame__column-body">
                  <div class="content-frame__column-grade" style="height: 20%;"></div>
                </div>
                8
              </div>
              <div class="content-frame__column">
                <div class="subtitle__label">12%</div>
                <div class="content-frame__column-body">
                  <div class="content-frame__column-grade" style="height: 20%;"></div>
                </div>
                9
              </div>
              <div class="content-frame__column">
                <div class="subtitle__label">12%</div>
                <div class="content-frame__column-body">
                  <div class="content-frame__column-grade" style="height: 20%;"></div>
                </div>
                10
              </div>

          </div>
          <div class="content-frame__text">
            <div class="subtitle__label">Совсем не важно</div>
            <div class="subtitle__label">Крайне важно</div>
          </div>
          </div>
        </div>

      </div>
    </div>
    <Pagination :total="12" :page="10" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "MosunetuzumabPage_9",
    components: {
      Pagination,
    }
  }
</script>