import { Point } from "@/types/Point";

interface GraphToRender {
  color: string;
  points: {
    x: number;
    y: number;
  }[];
}

export const interpolateGraphToRender = (points: Point[], amontOfPoints: number): Point[] => {
  const result = [];
  // eslint-disable-next-line no-debugger
  // debugger
  for (let i = 1; i < points.length; i++) {
    const deltaX = (points[i].x - points[i - 1].x) / amontOfPoints;
    const delataY = (points[i].y - points[i - 1].y) / amontOfPoints;

    for (let j = 0; j < amontOfPoints; j++) {
      result.push({
        x: points[i - 1].x + j * deltaX,
        y: points[i - 1].y + j * delataY,
      });
    }
  }

  return result;
}
export const graphToRender: GraphToRender[] = [
  {
    color: "#B95FFF",
    points: [
      {
        x: 1.8535967654761,
        y: 0.9663444876354,
      },
      {
        x: 4.2869676520788,
        y: 0.8408091271122,
      },
      {
        x: 6.0606355997603,
        y: 0.6944212627977,
      },
      {
        x: 9.3966846790979,
        y: 0.5644056581452,
      },
      {
        x: 19.1707232097889,
        y: 0.3178243389766,
      },
      {
        x: 29.0618160941408,
        y: 0.2617831300747,
      },
      {
        x: 39.0114361553231,
        y: 0.1698755474755,
      },
      {
        x: 50.7753986982506,
        y: 0.1586673056951,
      },
      {
        x: 60.3153285216195,
        y: 0.1340091737782,
      },
      {
        x: 68.4506061010569,
        y: 0.0802096132324,
      },
      {
        x: 74.3618509609359,
        y: 0.0555514813155,
      },
    ],
  },
  {
    color: "#FFEF5F",
    points: [
      {
        x: 3.485439819219,
        y: 0.9813522523756,
      },
      {
        x: 6.1776899534213,
        y: 0.8894446697765,
      },
      {
        x: 9.3381575022674,
        y: 0.7885704937529,
      },
      {
        x: 13.7276957645538,
        y: 0.6966629111538,
      },
      {
        x: 15.8346741304512,
        y: 0.5419891745844,
      },
      {
        x: 22.2726635818045,
        y: 0.414215218288,
      },
      {
        x: 34.2707348320539,
        y: 0.2954078554158,
      },
      {
        x: 46.4443876127947,
        y: 0.2191918113092,
      },
      {
        x: 63.4172688936352,
        y: 0.1967753277484,
      },
      {
        x: 78.2831718085783,
        y: 0.24160829487,
      },
      {
        x: 94.3781454369616,
        y: 0.2102252178849,
      },
    ],
  },
  {
    color: "#FF5F5F",
    points: [
      {
        x: 1.436988630152,
        y: 0.9791106040196,
      },
      {
        x: 6.8214888985566,
        y: 0.8401284059427,
      },
      {
        x: 10.6842825693686,
        y: 0.8378867575867,
      },
      {
        x: 14.6641305938416,
        y: 0.7840871970408,
      },
      {
        x: 21.3362287525168,
        y: 0.6271718121153,
      },
      {
        x: 26.3695659599385,
        y: 0.4590481854095,
      },
      {
        x: 37.899419795544,
        y: 0.4590481854095,
      },
      {
        x: 50.4827628140982,
        y: 0.3940403830833,
      },
      {
        x: 65.2316113753803,
        y: 0.3738655478786,
      },
      {
        x: 82.6141828940342,
        y: 0.3447241192495,
      },
      {
        x: 102.0452056017551,
        y: 0.24160829487,
      },
    ],
  },
  {
    color: "#073E71",
    points: [
      {
        x: 0,
        y: 1,
      },
      {
        x: 3.8173034837297,
        y: 0.7838922799505,
      },
      {
        x: 6.8653454678359,
        y: 0.623911129503,
      },
      {
        x: 11.493853665923,
        y: 0.5417586468407,
      },
      {
        x: 14.654786093885,
        y: 0.4033965707779,
      },
      {
        x: 26.9598444741656,
        y: 0.394748941024,
      },
      {
        x: 36.8942035334746,
        y: 0.3731298666392,
      },
      {
        x: 50.6668376838804,
        y: 0.3039488286078,
      },
      {
        x: 64.1008005027188,
        y: 0.3039488286078,
      },
      {
        x: 79.9054626425287,
        y: 0.2174725310686,
      },
      {
        x: 100.7901947558489,
        y: 0.2045010864377,
      },
    ],
  },
  {
    color: "#6CFF5F",
    points: [
      {
        x: 0.9074674987202,
        y: 0.9315188468099,
      },
      {
        x: 2.6735091392232,
        y: 0.7472550805696,
      },
      {
        x: 4.0132648665013,
        y: 0.6119728218109,
      },
      {
        x: 11.0165334409095,
        y: 0.5419992396943,
      },
      {
        x: 19.7858436558207,
        y: 0.4696932048406,
      },
      {
        x: 29.4077256971815,
        y: 0.3320784933447,
      },
      {
        x: 39.8212815773885,
        y: 0.3134188714469,
      },
      {
        x: 54.6803905526547,
        y: 0.2597724584909,
      },
      {
        x: 66.007416246915,
        y: 0.2597724584909,
      },
      {
        x: 88.9659575734532,
        y: 0.1991286873232,
      },
      {
        x: 110.1584572594885,
        y: 0.1618094435277,
      },
    ],
  },
  {
    color: "#256A1F",
    points: [
      {
        x: 2.4970498234273,
        y: 0.8959208292718,
      },
      {
        x: 6.2718813066872,
        y: 0.7551380520762,
      },
      {
        x: 12,
        y: 0.7,
      },
      {
        x: 17.6052300784048,
        y: 0.5530670777937,
      },
      {
        x: 24.3491867408639,
        y: 0.3645148419695,
      },
      {
        x: 32.413493412754,
        y: 0.2538745317496,
      },
      {
        x: 43.6072325244822,
        y: 0.2492645188238,
      },
      {
        x: 56.6064134284245,
        y: 0.2308244671205,
      },
      {
        x: 70.809222193843,
        y: 0.1708942990847,
      },
      {
        x: 96,
        y: 0.2,
      },
      {
        x: 113.8990996346889,
        y: 0.1201841569006,
      },
    ],
  },
  {
    color: "#fff",
    points: [
      {
        x: 1.8130341166058,
        y: 0.9724955430453,
      },
      {
        x: 5.8872634692693,
        y: 0.7313320195652,
      },
      {
        x: 11.4430307683559,
        y: 0.6391224370581,
      },
      {
        x: 19.0359127437743,
        y: 0.5823780785922,
      },
      {
        x: 25.8880257459812,
        y: 0.4405171824275,
      },
      {
        x: 31.8141775316736,
        y: 0.2702841070297,
      },
      {
        x: 40.3330207236064,
        y: 0.1922606141391,
      },
      {
        x: 55.3335924311403,
        y: 0.2348188829886,
      },
      {
        x: 68.6674339489482,
        y: 0.1497023452897,
      },
      {
        x: 93.4831945515351,
        y: 0.1426093004815,
      },
      {
        x: 112.0024188818238,
        y: 0.100051031632,
      },
    ],
  },
  {
    color: "#FFAC5F",
    points: [
      {
        x: 2.1864152479533,
        y: 0.9843518895154,
      },
      {
        x: 8.0832456127208,
        y: 0.9031854664403,
      },
      {
        x: 13.7036620541398,
        y: 0.7232077457086,
      },
      {
        x: 24.1152531669324,
        y: 0.6596861972151,
      },
      {
        x: 37.2909835132098,
        y: 0.5326431002281,
      },
      {
        x: 47.8868505749014,
        y: 0.4479477022367,
      },
      {
        x: 56.5510312884089,
        y: 0.3956123625712,
      },
      {
        x: 67.3279631837443,
        y: 0.3956123625712,
      },
      {
        x: 82.5307289679105,
        y: 0.3667812791616,
      },
      {
        x: 101.9718415767535,
        y: 0.3067887055844,
      },
      {
        x: 117.8195731820661,
        y: 0.2926728059192,
      },
    ],
  },
  {
    color: "#5FB2FF",
    points: [
      {
        x: 2.1864152479533,
        y: 0.9843518895154,
      },
      {
        x: 6.5884904253681,
        y: 0.7375361830352,
      },
      {
        x: 12.1485951358426,
        y: 0.5245787116718,
      },
      {
        x: 21.4996803307316,
        y: 0.3890603208042,
      },
      {
        x: 30.8507655256205,
        y: 0.3406608954944,
      },
      {
        x: 43.4684633502371,
        y: 0.3621203153154,
      },
      {
        x: 59.2238467806654,
        y: 0.3551841468119,
      },
      {
        x: 75.5225192949016,
        y: 0.3482479783084,
      },
      {
        x: 88.9661784336269,
        y: 0.2257142153703,
      },
      {
        x: 105.6803728649756,
        y: 0.1895755827899,
      },
      {
        x: 119.2943538130903,
        y: 0.1844129209927,
      },
    ],
  },
  {
    color: "#DF0FF1",
    points: [
      {
        x: 3.9894565935316,
        y: 0.9586308066173,
      },
      {
        x: 9.4223474316104,
        y: 0.792162762533,
      },
      {
        x: 11.052214683034,
        y: 0.5771415389242,
      },
      {
        x: 17.7527800499978,
        y: 0.4730990113716,
      },
      {
        x: 25.7210199458466,
        y: 0.4592266743646,
      },
      {
        x: 39.1221506797741,
        y: 0.3482479783084,
      },
      {
        x: 56.1452086390875,
        y: 0.3482479783084,
      },
      {
        x: 72.8060738758623,
        y: 0.2580777877628,
      },
      {
        x: 92.1833845316764,
        y: 0.2650139562663,
      },
      {
        x: 105.0412261817961,
        y: 0.2303331137487,
      },
      {
        x: 117.5368751093772,
        y: 0.1470990917066,
      },
    ],
  },
  {
    color: "#0FF1F1",
    points: [
      {
        x: 2.7217820646466,
        y: 0.9100776270927,
      },
      {
        x: 8.1546729027253,
        y: 0.8615244475681,
      },
      {
        x: 14.3119491858812,
        y: 0.7158649089944,
      },
      {
        x: 17.3905873274592,
        y: 0.5355245279032,
      },
      {
        x: 25.358827223308,
        y: 0.3621203153154,
      },
      {
        x: 37.8544761508891,
        y: 0.2927586302803,
      },
      {
        x: 55.0586304714717,
        y: 0.424545831847,
      },
      {
        x: 64.4756412574749,
        y: 0.2788862932733,
      },
      {
        x: 78.6011574364796,
        y: 0.2372692822522,
      },
      {
        x: 87.1126864161362,
        y: 0.1748437657206,
      },
      {
        x: 109.0253461297205,
        y: 0.084673575175,
      },
    ],
  },
];
