<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Регистрационное исследование мосунетузумаба</h1>
        <p class="text large">Международное, открытое, многоцентровое исследование <br>II фазы GO29781</p>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-15">
          <img src="@/assets/img/frame-15.png" alt="">
        </div>
        <div class="frame-note">Budde LE, et al. (2022) The Lancet Oncology, 23(8): 1055-1065</div>
      </div>
    </div>
    <Pagination :total="15" :page="3" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "RelapseTherapyPage_3",
    components: {
      Pagination,
    }
  }
</script>