<template>
    <ul class="pagination">
        <li class="pagination-item pagination-item--prev" v-if="page !== 1" @click="prev">
            <a class="pagination-link pagination-link--prev" href="javascript:void(0)"></a>
        </li>
        <li class="pagination-item" v-for="item in pages" :key="item">
            <a class="pagination-link" :class="{active: item == page}" href="javascript:void(0)" @click="$store.dispatch('setPageByUser', item)">
                {{leadingZero(item)}}
            </a>
        </li>
        <li class="pagination-item" v-if="page !== total" @click="next">
            <a class="pagination-link pagination-link--next" href="javascript:void(0)"></a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'PaginationPresentation',
    props: {
        page: {
            type: Number,
            required: true,
        },
        total: {
            type: Number,
            required: true,
        }
    },
    computed: {
        pages() {
            return Array.from({ length: this.total }, (_, i) => i + 1).slice(this.page - 1, this.page + 5);
        }
    },
    methods: {
        leadingZero(num) {
            return String(num).padStart(2, '0');
        },
        prev() {
            let p = this.page - 1;
            this.$store.dispatch('setPageByUser', p);
        },
        next() {
            let p = this.page + 1;
            this.$store.dispatch('setPageByUser', p);
        },
        handleKeydown(event) {
            if (event.key === 'ArrowLeft' && this.page >= 1) {
                this.prev();
            } else if (event.key === 'ArrowRight' && this.page <= this.total) {
                this.next();
            }
        }
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeydown);
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeydown);
    }
};
</script>

<style>
.pagination-item--prev {
    position: relative;
    z-index: 100;
}
</style>
