<script setup lang="ts"></script>

<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.79289 5.70711C5.40237 5.31658 5.40237 4.68342 5.79289 4.29289C6.18342 3.90237 6.81658 3.90237 7.20711 4.29289L12.5 9.58579V8C12.5 5.79086 14.2909 4 16.5 4C18.7091 4 20.5 5.79086 20.5 8V15C20.5 15.7418 20.2981 16.4365 19.9462 17.032L20.6716 17.7574C21.1951 16.967 21.5 16.0191 21.5 15C21.5 14.4477 21.9477 14 22.5 14C23.0523 14 23.5 14.4477 23.5 15C23.5 16.5723 22.9816 18.0236 22.1064 19.1922L29.2071 26.2929C29.5976 26.6834 29.5976 27.3166 29.2071 27.7071C28.8166 28.0976 28.1834 28.0976 27.7929 27.7071L5.79289 5.70711ZM12.5 15V14.4142L17.0485 18.9627C16.8692 18.9873 16.6861 19 16.5 19C14.2909 19 12.5 17.2091 12.5 15ZM16.5 20C16.9823 20 17.4487 19.9317 17.89 19.8042L19.4402 21.3544C18.8353 21.6348 18.1836 21.8313 17.5 21.9291V25.0076C17.5402 25.0126 17.5801 25.02 17.6194 25.0299L21.0538 25.8884C21.337 25.9593 21.5751 26.1503 21.7057 26.4114L21.7764 26.5528C22.1088 27.2177 21.6254 28 20.882 28H12.118C11.3747 28 10.8912 27.2177 11.2236 26.5528L11.2943 26.4114C11.4249 26.1503 11.663 25.9593 11.9462 25.8884L15.3806 25.0299C15.4199 25.02 15.4598 25.0126 15.5 25.0076V21.9291C12.1077 21.4439 9.5 18.5265 9.5 15C9.5 14.4477 9.94772 14 10.5 14C11.0523 14 11.5 14.4477 11.5 15C11.5 17.7614 13.7386 20 16.5 20Z"
      fill="white"
    />
  </svg>
</template>

<style scoped lang="sass"></style>
