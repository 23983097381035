<template>
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1173 6.53627H3.85196L8.85222 1.56212L7.41383 0.14209L0 7.51717L7.41388 14.8922L8.85227 13.4722L3.90256 8.54837H17.1173C20.8957 8.54837 23.9697 11.5947 23.9697 15.3392C23.9697 19.0836 20.8957 22.13 17.1173 22.13H5.13828V24.1421H17.1173C22.0152 24.1421 26 20.1931 26 15.3392C26 10.4853 22.0153 6.53627 17.1173 6.53627Z"
      fill="#F8DDFF"
    />
  </svg>
</template>

<script lang="ts" setup>
</script>
