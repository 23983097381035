<template>
  <div class="page background-1">
    <div class="page-wrapper">
      <div class="page-topic">
        <h1 class="title full">Какие клинические данные по эффективности<br>мосунетузумаба в 3+ линии терапии ФЛ впечатлили<br>Вас больше всего?</h1>
      </div>
      <div class="frame-wrapper">
        <div class="content-frame content-frame-66">
          <div class="content-frame__wrapper content-frame__wrapper--results-2">
            <div class="content-frame__lines">
              <div class="content-frame__line">
                <div class="subtitle__label">ПО — 60%, ЧОО — 80%</div>
                <div class="content-frame__line-wrapper">
                  <div class="content-frame__line-body">
                    <div class="content-frame__line-grade" style="width: 20%;"></div>
                  </div>
                  78%
                </div>
              </div>
              <div class="content-frame__line">
                <div class="subtitle__label">ПО — 60%, ЧОО — 80%</div>
                <div class="content-frame__line-wrapper">
                  <div class="content-frame__line-body">
                    <div class="content-frame__line-grade" style="width: 20%;"></div>
                  </div>
                  78%
                </div>
              </div>
              <div class="content-frame__line">
                <div class="subtitle__label">ПО — 60%, ЧОО — 80%</div>
                <div class="content-frame__line-wrapper">
                  <div class="content-frame__line-body">
                    <div class="content-frame__line-grade" style="width: 20%;"></div>
                  </div>
                  78%
                </div>
              </div>
              <div class="content-frame__line">
                <div class="subtitle__label">ПО — 60%, ЧОО — 80%</div>
                <div class="content-frame__line-wrapper">
                  <div class="content-frame__line-body">
                    <div class="content-frame__line-grade" style="width: 20%;"></div>
                  </div>
                  78%
                </div>
              </div>
              <div class="content-frame__line">
                <div class="subtitle__label">ПО — 60%, ЧОО — 80%</div>
                <div class="content-frame__line-wrapper">
                  <div class="content-frame__line-body">
                    <div class="content-frame__line-grade" style="width: 20%;"></div>
                  </div>
                  78%
                </div>
              </div>
              <div class="content-frame__line">
                <div class="subtitle__label">ПО — 60%, ЧОО — 80%</div>
                <div class="content-frame__line-wrapper">
                  <div class="content-frame__line-body">
                    <div class="content-frame__line-grade" style="width: 20%;"></div>
                  </div>
                  78%
                </div>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <Pagination :total="12" :page="10" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue' 
  export default {
    name: "MosunetuzumabPage_9",
    components: {
      Pagination,
    }
  }
</script>